// window.requestIdleCallback shim
// https://gist.github.com/paullewis/55efe5d6f05434a96c36
// FIXME This doesn't accept a timeout option
const idleTimeout = (callback) => {
    return setTimeout(() => {
        const start = window.performance.now();
        callback({
            didTimeout: false,
            timeRemaining() {
                return Math.max(0, 50 - (window.performance.now() - start));
            },
        });
    }, 1);
};

const cancelIdleTimeout = (id) => clearTimeout(id);

(window as any).requestIdleCallback = (window as any).requestIdleCallback || idleTimeout;
(window as any).cancelIdleCallback = (window as any).cancelIdleCallback || cancelIdleTimeout;
(window as any).dominosCouponDragging = false;
(window as any).confirm = (window as any).confirm || false;
