import { Map as ImmMap } from 'immutable';
import isEqual from 'lodash.isequal';
import { ListType, ResourceType } from '../AppTypes';
import { ActivityTypes } from '../types/IActivity';
import { CustomFieldType } from '../types/ICustomField';

export default class Query {
    private fields: ImmMap<string, string | boolean | number | any[]>;

    constructor(queryType: ResourceType = 'activity') {
        this.fields = ImmMap({
            type: queryType,
        });
    }

    public status(): string;
    public status(value: string | null): this;
    public status(value?: string | null): this | string {
        // null values are allowed for status queries
        if (value || value === null) {
            this.fields = this.fields.set('status', value as any);
            return this;
        }
        return this.fields.get('status') as string;
    }

    public isArchived(): boolean;
    public isArchived(value: boolean): this;
    public isArchived(value?: boolean): this | boolean {
        if (arguments.length) {
            this.fields = this.fields.set('isArchived', `=${value}`);
            return this;
        }
        return this.fields.get('isArchived') as boolean;
    }

    public id(): string;
    public id(value: number | string): this;
    public id(value?: number | string): this | string {
        if (value) {
            this.fields = this.fields.set('id', value + '');
            return this;
        }
        return this.fields.get('id') as string;
    }

    public ref(): string;
    public ref(value: string): this;
    public ref(value?: string): this | string {
        if (value) {
            this.fields = this.fields.set('ref', value);
            return this;
        }
        return this.fields.get('ref') as string;
    }

    /**
     * The date to Query a ResourceType with
     *
     * This is an ISO8601 string prefixed with operators >, <, =, etc...
     */
    public date(): string;
    public date(value: string): this;
    public date(value?: string): this | string {
        if (value) {
            this.fields = this.fields.set('date', value);
            return this;
        }
        return this.fields.get('date') as string;
    }

    /**
     * The date the list (stats, data) was last updated
     *
     * Only applies to lists. Throws exception otherwise.
     */
    public lastUpdated(): string;
    public lastUpdated(value: string): this;
    public lastUpdated(value?: string): this | string {
        if (this.fields.get('type') !== 'list') {
            throw new Error('lastUpdated can only be used in list queries');
        }

        if (value) {
            this.fields = this.fields.set('lastUpdated', value);
            return this;
        }
        return this.fields.get('lastUpdated') as string;
    }

    public startDate(): string;
    public startDate(value: string): this;
    public startDate(value?: string): this | string {
        if (this.fields.get('type') !== 'campaign') {
            throw new Error('startDate can only be used in campaign queries');
        }

        if (value) {
            this.fields = this.fields.set('startDate', value);
            return this;
        }
        return this.fields.get('startDate') as string;
    }

    public endDate(): string;
    public endDate(value: string): this;
    public endDate(value?: string): this | string {
        if (this.fields.get('type') !== 'campaign') {
            throw new Error('endDate can only be used in campaign queries');
        }
        if (value) {
            this.fields = this.fields.set('endDate', value);
            return this;
        }
        return this.fields.get('endDate') as string;
    }

    public campaignId(): string;
    public campaignId(value: number | string): this;
    public campaignId(value?: number | string): this | string {
        if (value) {
            this.fields = this.fields.set('campaignId', value + '');
            return this;
        }
        return this.fields.get('campaignId') as string;
    }

    public activityId(): string;
    public activityId(value: number | string): this;
    public activityId(value?: number | string): this | string {
        if (value) {
            this.fields = this.fields.set('activityId', value + '');
            return this;
        }
        return this.fields.get('activityId') as string;
    }

    public activityType(): ActivityTypes;
    public activityType(value: ActivityTypes): this;
    public activityType(value?: ActivityTypes): this | ActivityTypes {
        if (!['activity', 'activityLayout'].includes((this.fields.get('type') as string) ?? '')) {
            throw new Error('activityType can only be used in activity queries');
        }

        if (typeof value !== 'undefined') {
            this.fields = this.fields.set('activityType', value as string);
            return this;
        }
        return this.fields.get('activityType') as ActivityTypes;
    }

    public templateUrl(): string;
    public templateUrl(value: string): this;
    public templateUrl(value?: string): this | string {
        if (this.fields.get('type') !== 'templateContent') {
            throw new Error('templateUrl can only be used in templateContent queries');
        }
        if (value) {
            this.fields = this.fields.set('templateUrl', value + '');
            return this;
        }
        return this.fields.get('templateUrl') as string;
    }

    public templateId(): number;
    public templateId(value: number): this;
    public templateId(value?: number): this | number {
        if (this.fields.get('type') !== 'contentBlock') {
            throw new Error('templateId can only be used in contentBlock queries');
        }
        if (value) {
            this.fields = this.fields.set('templateId', value);
            return this;
        }
        return this.fields.get('templateId') as number;
    }

    public name(): string;
    public name(value: string): this;
    public name(value?: string): this | string {
        if (value) {
            this.fields = this.fields.set('name', value);
            return this;
        }
        return this.fields.get('name') as string;
    }

    public target(): string;
    public target(value: string): this;
    public target(value?: string): this | string {
        if (value) {
            this.fields = this.fields.set('target', value);
            return this;
        }
        return this.fields.get('target') as string;
    }

    public expression(): string;
    public expression(value: string): this;
    public expression(expr?: string): this | string {
        if (this.fields.get('type') !== 'subscriber') {
            throw new Error('Expression can only be used in subscriber queries');
        }
        if (expr) {
            this.fields = this.fields.set('expression', expr);
            return this;
        }
        return this.fields.get('expression') as string;
    }

    public listType(): ListType;
    public listType(value: ListType): this;
    public listType(value?: ListType): this | ListType {
        if (this.fields.get('type') !== 'list') {
            throw new Error('listType can only be used in list queries');
        }

        if (typeof value !== 'undefined') {
            this.fields = this.fields.set('listType', value as string);
            return this;
        }
        return this.fields.get('listType') as ListType;
    }

    public isEmpty(): boolean;
    public isEmpty(value: boolean): this;
    public isEmpty(value?: boolean): this | boolean {
        if (this.fields.get('type') !== 'list') {
            throw new Error('isEmpty can only be used in list queries');
        }

        if (arguments.length) {
            this.fields = this.fields.set('isEmpty', value as boolean);
            return this;
        }
        return this.fields.get('isEmpty') as boolean;
    }

    public isActive(): boolean;
    public isActive(value: boolean): this;
    public isActive(value?: boolean): this | boolean {
        if (this.fields.get('type') !== 'list') {
            throw new Error('isActive can only be used in list queries');
        }

        if (arguments.length) {
            this.fields = this.fields.set('isActive', value as boolean);
            return this;
        }
        return this.fields.get('isActive') as boolean;
    }

    public any(): string;
    public any(value: string): this;
    public any(value?: string): this | string {
        if (value) {
            this.fields = this.fields.set('any', value);
            return this;
        }
        return this.fields.get('any') as string;
    }

    public includeContent(): string;
    public includeContent(value: string | number): this;
    public includeContent(value?: string | number): this | string {
        if (this.fields.get('type') !== 'activityRevision') {
            throw new Error('includeContent can only be used in activityRevision queries');
        }

        if (typeof value !== 'undefined') {
            this.fields = this.fields.set('includeContent', value);
            return this;
        }
        return this.fields.get('includeContent') as string;
    }

    public isPublished(): boolean;
    public isPublished(value: boolean): this;
    public isPublished(value?: boolean): this | boolean {
        if (this.fields.get('type') !== 'contentBlock') {
            throw new Error('isPublished can only be used in contentBlock queries');
        }

        if (arguments.length) {
            this.fields = this.fields.set('isPublished', value as boolean);
            return this;
        }
        return this.fields.get('isPublished') as boolean;
    }

    public tag(): string;
    public tag(value: string | string[]): this;
    public tag(value?: string | string[]): this | string | string[] {
        if (this.fields.get('type') !== 'contentBlock') {
            throw new Error('tag can only be used in contentBlock queries');
        }

        if (value) {
            this.fields = this.fields.set('tag', value);
            return this;
        }
        return this.fields.get('tag') as string;
    }

    public credentialType(): string;
    public credentialType(value: string | string[]): this;
    public credentialType(value?: string | string[]): this | string | string[] {
        if (this.fields.get('type') !== 'credential') {
            throw new Error('tag can only be used in credential queries');
        }

        if (value) {
            this.fields = this.fields.set('credentialType', value);
            return this;
        }
        return this.fields.get('credentialType') as string;
    }

    public customFieldGroupName(): string;
    public customFieldGroupName(value: string | string[]): this;
    public customFieldGroupName(value?: string | string[]): this | string | string[] {
        if (this.fields.get('type') !== 'customField') {
            throw new Error('customFieldGroupName can only be used in customField queries');
        }

        if (value) {
            this.fields = this.fields.set('customFieldGroupName', value);
            return this;
        }
        return this.fields.get('customFieldGroupName') as string;
    }

    public customFieldType(): CustomFieldType;
    public customFieldType(value: CustomFieldType | CustomFieldType[]): this;
    public customFieldType(value?: CustomFieldType | CustomFieldType[]): this | CustomFieldType | CustomFieldType[] {
        if (this.fields.get('type') !== 'customField') {
            throw new Error('customFieldType can only be used in customField queries');
        }

        if (value) {
            this.fields = this.fields.set('customFieldType', value);
            return this;
        }
        return this.fields.get('customFieldType') as CustomFieldType;
    }

    public customFieldName(): string;
    public customFieldName(value: string | string[]): this;
    public customFieldName(value?: string | string[]): this | string | string[] {
        if (this.fields.get('type') !== 'customField') {
            throw new Error('customFieldName can only be used in customField queries');
        }

        if (value) {
            this.fields = this.fields.set('customFieldName', value);
            return this;
        }
        return this.fields.get('customFieldName') as string;
    }

    public integrationType(): string;
    public integrationType(value: string | string[]): this;
    public integrationType(value?: string | string[]): this | string | string[] {
        if (this.fields.get('type') !== 'integration') {
            throw new Error('tag can only be used in integration queries');
        }

        if (value) {
            this.fields = this.fields.set('integrationType', value);
            return this;
        }
        return this.fields.get('integrationType') as string;
    }

    public partitionIds(): number[];
    public partitionIds(value: number[]): this;
    public partitionIds(value?: number[]): this | number[] {
        if (value) {
            this.fields = this.fields.set('partitionIds', value);
            return this;
        }
        return this.fields.get('partitionIds') as number[];
    }

    public type(): ResourceType {
        return this.fields.get('type') as ResourceType;
    }

    public toJSON(): Record<string, any> {
        return this.fields.toJS();
    }

    public queryMatches(result: { [key: string]: any }): boolean {
        return isEqual(result, this.fields.toJS());
    }
}
