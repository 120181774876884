<template>
    <div :class="[`-${props.status}`, { hidden: appErrors.length < 1 }]" class="alert">
        <ul class="alert__global--list">
            <li v-for="err of appErrors" :key="err.error.name">
                {{ err.error.message }}
                <a v-if="err.action" class="btn-link" @click.prevent="err.action.method">{{
                    err.action && err.action.text
                }}</a>
                <a href="#" class="btn-link" @click.prevent="removeError(err)">
                    <SvgIcon icon="circle-cancel" color="#fff" size="s"></SvgIcon>
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { useStoreRoot } from './stores/root';
import { storeToRefs } from 'pinia';
import SvgIcon from './SvgIcon.vue';

const rootStore = useStoreRoot();
const { appErrors } = storeToRefs(rootStore);

const props = defineProps({
    status: {
        type: String,
        default: 'negative',
    },
});

const removeError = (err: any) => {
    appErrors.value.splice(appErrors.value.indexOf(err), 1);
};
</script>
