import { ResourceID } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';
import Rx from 'rx';

interface IOrganizationTrackingDomainCommand extends ICommand {
    domain: string;
}

interface IOrganizationSendingDomainCommand extends ICommand {
    organizationId: ResourceID;
    domain: string;
    envfrom: string;
    selector: string;
}

export interface IOrganizationConfigCommandResponse extends ICommandResponse {
    organizationId: ResourceID;
    result: {
        type: string;
        data: any;
    };
}

class OrganizationTrackingDomainCommand extends AbstractCommand<IOrganizationTrackingDomainCommand> {
    public readonly _domain: string;

    constructor(op: 'add' | 'remove', domain: string, organizationId: number) {
        super(organizationId, op + 'OrganizationTrackingDomain');
        if (typeof domain === 'undefined') {
            throw new Error('Tracking domain is required');
        }
        this._domain = domain;
    }

    get domain(): string {
        return this._domain;
    }

    public toJSON(): IOrganizationTrackingDomainCommand {
        return Object.assign(
            {},
            {
                domain: this._domain,
                organizationId: this.organizationId,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === this.name &&
            response.result.data
        );
    }
}

export class OrganizationAddTrackingDomainCommand extends OrganizationTrackingDomainCommand {
    constructor(domain: string, organizationId: number) {
        super('add', domain, organizationId);
    }
}

export class OrganizationRemoveTrackingDomainCommand extends OrganizationTrackingDomainCommand {
    constructor(domain: string, organizationId: number) {
        super('remove', domain, organizationId);
    }
}

class OrganizationSendingDomainCommand extends AbstractCommand<IOrganizationSendingDomainCommand> {
    public readonly _domain: string;
    public readonly _selector: string;
    public readonly _envfrom: string;

    constructor(op: 'add' | 'remove', domain: string, envfrom: string, selector: string, organizationId: number) {
        super(organizationId, op + 'OrganizationSendingDomain');
        if (typeof domain === 'undefined') {
            throw new Error('Sending domain is required');
        }
        this._domain = domain;
        this._selector = selector;
        this._envfrom = envfrom;
    }

    get domain(): string {
        return this._domain;
    }

    public toJSON(): IOrganizationSendingDomainCommand {
        return Object.assign(
            {},
            {
                domain: this._domain,
                selector: this._selector,
                envfrom: this._envfrom,
                organizationId: this.organizationId,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === this.name &&
            response.result.data
        );
    }
}

export class OrganizationAddSendingDomainCommand extends OrganizationSendingDomainCommand {
    constructor(domain: string, envfrom: string, selector: string, organizationId: number) {
        super('add', domain, envfrom, selector, organizationId);
    }
}

export class OrganizationRemoveSendingDomainCommand extends OrganizationSendingDomainCommand {
    constructor(domain: string, organizationId: number) {
        // envfrom and selector are ignored in remove commands
        super('remove', domain, '', '', organizationId);
    }
}
