import AppConfig from '../AppConfig';
import { default as AppErrors, Types } from '../AppErrors';
import { ResourceType, SortOrder } from '../AppTypes';
import Rx from 'rx';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';
import Query from './Query';

interface IQueryCommand extends ICommand {
    query: Query | undefined;
    sortKey: string;
    sortOrder: SortOrder;
    offset: number;
    limit: number;
}

export interface IQueryCommandResponse<T> extends ICommandResponse {
    result: {
        data: T[];
        matchesQuery: boolean;
        query: Query;
        type: ResourceType;
    };
}

export default class QueryCommand extends AbstractCommand<IQueryCommand> {
    private _query: Query | undefined;
    private _sortKey: string = AppConfig.Defaults.sortKey;
    private _sortOrder: SortOrder = AppConfig.Defaults.sortOrder;
    private _offset: number = AppConfig.Defaults.offset;
    private _limit: number = AppConfig.Defaults.limit;

    constructor(organizationId: number) {
        super(organizationId, 'query');
    }

    public query(): Query;
    public query(value: Query): this;
    public query(value?: Query): this | Query | undefined {
        if (value) {
            this._query = value;
            return this;
        }
        return this._query;
    }

    public sortKey(): string;
    public sortKey(value: string): this;
    public sortKey(value?: string): this | string {
        if (value) {
            this._sortKey = value;
            return this;
        }
        return this._sortKey;
    }

    public sortOrder(): SortOrder;
    public sortOrder(value: SortOrder): this;
    public sortOrder(value?: SortOrder): this | SortOrder {
        if (value) {
            this._sortOrder = value;
            return this;
        }
        return this._sortOrder;
    }

    public offset(): number;
    public offset(value: number): this;
    public offset(value?: number): this | number {
        if (typeof value !== 'undefined') {
            if (value < 0 || isNaN(value)) {
                throw new Error('Query offset value needs to be greater than or equal to zero');
            }
            this._offset = value;
            return this;
        }
        return this._offset;
    }

    public limit(): number;
    public limit(value: number): this;
    public limit(value?: number): this | number {
        if (typeof value !== 'undefined') {
            if (value < 0 || isNaN(value)) {
                throw new Error('Query limit value needs to be greater than or equal to zero');
            }
            this._limit = value;
            return this;
        }
        return this._limit;
    }

    public toJSON(): IQueryCommand {
        return Object.assign(
            {},
            {
                limit: this._limit,
                offset: this._offset,
                organizationId: this.organizationId,
                query: this._query,
                sortKey: this._sortKey,
                sortOrder: this._sortOrder,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error && response.error === Types.UnauthenticatedCommandError) {
            return Rx.Observable.throw(new AppErrors(response.error));
        }
        if (response.result && !Object.prototype.hasOwnProperty.call(response.result, 'query')) {
            return Rx.Observable.empty();
        }
        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        // If matchesQuery is false, it means it's a subscription update. Allow those too.
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            (this.query().queryMatches(response.result.query) ||
                (!response.result.matchesQuery && response.result.query.type === this.query().type()))
        );
    }
}
