export interface IThemePolicies {
    lock: {
        global: boolean;
        customColors?: boolean;
        fonts?: any[];
    };
    tier?: string[];
}

export const defaultTheme = {
    content: {
        brandName: '',
        themeName: '',
        themeDescription: '',
        sender: [],
        replyTo: [],
        trackingDomain: '',
        trackingLists: [],
        language: 'en-AU',
        logos: [],
        customColors: [],
        customFont: [],
        googleFonts: {},
        policies: {
            lock: {
                global: false,
                fonts: [], // Record<fontName|family|source|style, any>[]
                customColors: false,
            },
            // tier: [],
        },
        contactUsUrl: '',
        socialMedia: [],
        appStoreLinks: {
            appStoreUrl: '',
            googlePlayUrl: '',
        },
        viewOnline: {
            viewOnlineLeadingText: 'Is this email a mess?',
            viewOnlineText: 'View the online version',
        },
        header: {
            logo: '',
            logoHeight: '',
            logoWidth: '',
            logoUrl: '',
            logoAlt: '',
        },
        analytics: {
            campaign: { type: '', value: '' },
            medium: { type: '', value: '' },
            source: { type: '', value: '' },
        },
        style: {
            global: {
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '16px',
                textTransform: 'none',
                color: '#333333',
                width: '600px',
                backgroundColor: '#ffffff',
                disableMobile: false,
            },
            header: {
                backgroundColor: '#ffffff',
            },
            headerRow: {
                backgroundColor: '#eeeeee',
            },
            viewOnline: {
                color: '#333333',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '10px',
                disableViewOnline: false,
            },
            blockTitle: {
                color: '#333333',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: '28px',
                textTransform: 'none',
            },
            blockSubtitle: {
                color: '#333333',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '24px',
                textTransform: 'none',
            },
            blockContent: {
                color: '#333333',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '21px',
                textTransform: 'none',
            },
            blockContentLink: {
                color: '#6ba1cb',
                textDecoration: false,
            },
            primaryCTA: {
                backgroundColor: '#6ba1cb',
                borderColor: '',
                borderStyle: 'none',
                borderWidth: '0px',
                borderRadius: '20px',
                color: '#ffffff',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                buttonHeight: '40px',
            },
            secondaryCTA: {
                backgroundColor: '#666666',
                borderColor: '',
                borderWidth: '',
                borderRadius: '',
                color: '#ffffff',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '',
                fontStyle: 'normal',
                fontWeight: 'normal',
                textTransform: 'none',
                buttonHeight: '',
            },
            socialMedia: {
                backgroundColor: '',
                iconColor: '',
                iconWidth: '48px',
                iconRadius: 0,
                alignment: 'center',
                spacing: '0px',
            },
            appStoreLinks: {
                iconHeight: '48px',
                alignment: 'center',
                spacing: '0px',
            },
            footer: {
                backgroundColor: '#dddddd',
                color: '#333333',
                fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                fontSize: '14px',
                fontWeight: 'normal',
                fontStyle: 'normal',
            },
            footerSm: {
                color: '',
            },
            footerLink: {
                color: '',
                textDecoration: false,
            },
        },
        unsub: {
            logo: '',
        },
    },
};

export const themeToCSS = (theme) => {
    return Object.keys(theme.content.style)
        .map((selector) => {
            const selectorStyles = theme.content.style[selector];
            const selectorValue = Object.keys(selectorStyles)
                .filter((key) => selectorStyles[key] !== '')
                .map((key) => {
                    const nkey = key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
                    return `${nkey}: ${selectorStyles[key]};`;
                })
                .join('\n');
            if (selectorValue === '') {
                return '';
            }
            return `.${selector} {\n${selectorValue}\n}`;
        })
        .filter((selector) => selector !== '')
        .join('\n');
};

export const applyThemeToDefault = (theme) => {
    if (!theme) {
        const cssText = themeToCSS(defaultTheme);
        return {
            cssText,
            ...defaultTheme,
        };
    }
    const tt = {
        ...defaultTheme,
        ...theme,
        content: {
            ...defaultTheme.content,
            ...theme.content,
            style: {
                ...defaultTheme.content.style,
                ...theme.content.style,
            },
        },
    };
    const cssText = themeToCSS(tt);
    return {
        cssText,
        ...tt,
    };
};
