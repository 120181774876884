import * as StackTrace from 'stacktrace-js';
import Rx from 'rx';

const _errorSubject: Rx.Subject<IAppErrorData> = new Rx.Subject<IAppErrorData>();

// Session Errors
const DuplicateUserError = 'DuplicateUserError';
const InvalidOTPError = 'InvalidOTPError';
const InvalidOTPCodeRecoveryError = 'InvalidOTPCodeRecoveryError';
const InvalidSessionError = 'InvalidSessionError';
const InvalidPasswordError = 'InvalidPasswordError';
const MissingOTPError = 'MissingOTPError';
const MissingOTPCodeRecoveryError = 'MissingOTPCodeRecoveryError';
const MissingParameterError = 'MissingParameterError';
const MissingUserError = 'MissingUserError';

// SSO Errors
const SSOBadKey = 'SSOBadKey';
const SSOError = 'SSOError';
const SSONoKey = 'SSONoKey';

// Appserver errors
const ApplicationError = 'ApplicationError';
const ImportFileUnsupported = 'ImportFileUnsupported';
const OrgAuthenticationError = 'OrgAuthenticationError';
const WSConnectionError = 'WSConnectionError';
const WSConnectionRetryError = 'WSConnectionRetryError';
const WSConnectionClosedError = 'WSConnectionClosedError';
const PrivilegesError = 'PrivilegesError';
const QueryTimeoutError = 'QueryTimeoutError';
const QueryCancelledError = 'QueryCancelledError';
const UnauthenticatedCommandError = 'UnauthenticatedCommand';

// Session errors
const WSSessionConnectionError = 'WSSessionConnectionError';
const WSSessionConnectionRetryError = 'WSSessionConnectionRetryError';

// TODO TS type
export const Types = Object.freeze({
    ApplicationError,
    DuplicateUserError,
    ImportFileUnsupported,
    InvalidOTPCodeRecoveryError,
    InvalidOTPError,
    InvalidPasswordError,
    InvalidSessionError,
    MissingOTPCodeRecoveryError,
    MissingOTPError,
    MissingParameterError,
    MissingUserError,
    OrgAuthenticationError,
    PrivilegesError,
    QueryTimeoutError,
    QueryCancelledError,
    UnauthenticatedCommandError,
    WSConnectionClosedError,
    WSConnectionError,
    WSConnectionRetryError,
    WSSessionConnectionError,
    WSSessionConnectionRetryError,
    SSOBadKey,
    SSOError,
    SSONoKey,
});

// FIXME AppError Type
export function hasType(type: string) {
    return Object.keys(Types).filter((key) => key === type);
}

export const ErrorStrings: { [errorType: string]: string } = Object.assign(
    {},
    {
        // Login/auth errors
        DuplicateUserError: 'This email address is already in use',
        InvalidOTPCodeRecoveryError: `Invalid OTP code or recovery key. Please confirm your phone's time is set correctly.`,
        InvalidOTPError: 'Invalid OTP code',
        InvalidPasswordError: 'Invalid email address or password',
        InvalidSessionError: 'Your session expired. Please login again.',
        MissingOTPCodeRecoveryError: 'Please enter your OTP code or recovery key',
        MissingOTPError: 'Please enter your OTP',
        MissingParameterError: 'Application Error',
        MissingUserError: 'Invalid email address or password',
    },
    {
        /* App errors */
        ApplicationError: 'Application Error. Please contact support@taguchi.com.au for support',
        ImportFileUnsupported: 'Unable to process file. Please ensure file is in CSV, TSV, or Excel format',
        OrgAuthenticationError: 'You are not authorized to access this organization. Please login.',
        PrivilegesError: 'You do not have enough privileges to perform this action.',
        QueryTimeoutError: 'Your search took too long to complete.',
        QueryCancelledError: 'Your search was cancelled by a subsequent query.',
        UnauthenticatedCommandError: `We're unable to process your request. Please try to reload the page.
        If the problems persist, please contact Taguchi Support`,
        WSConnectionError: 'Unable to connect to server. Please Taguchi for support.',
        WSConnectionRetryError: 'Connection is offline. Trying to reconnect...',
        WSConnectionClosedError: 'Connection closed. Please reload the page.',
        WSSessionConnectionError: 'Unable to connect to session server. Please Taguchi for support.',
        WSSessionConnectionRetryError: 'Session connection disconnected. Reconnecting...',
    },
    {
        // SSO errors
        SSOBadKey: 'Your single sign-on session could not be found. Please reload the page and try again.',
        SSOError: 'Unable to complete the single sign-on process. Please contact your organization administrator.',
        SSONoKey: 'Your single sign-on session expired. Please reload the page and try again.',
    }
);
export default class AppErrors extends Error {
    public name: string;
    public message: string;

    constructor(name = ApplicationError, message: string | null = null) {
        super(message ?? ErrorStrings[name]);
        this.name = name;
        this.message = message || ErrorStrings[name];
    }
}

interface IAppErrorData {
    error: Error | undefined;
    message: string | Event;
    source: any;
    lineno: number | undefined;
    colno: number | undefined;
}

// Error utils
export function getErrorDescription(type: string, error: Error, action: any) {
    return { type, error, action };
}

export function getOrgAuthErrorDescription(error: Error) {
    return getErrorDescription(Types.OrgAuthenticationError, error, {
        method: () => window.location.assign('/'),
        text: 'Go back',
    });
}

/**
 * Send the given error param to HipChat
 */
export function sendError(errorData: IAppErrorData) {
    return _errorSubject.onNext(errorData);
}

function _send(msg: string) {
    const url = 'https://hooks.slack.com/services/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';
    navigator.sendBeacon(
        url,
        JSON.stringify({
            text: msg,
        })
    );
}
function sendToSlack(errorData: any) {
    // Some errors passed to window.error by Firefox are null
    // Process them as is-is.
    let err = errorData && errorData.error;
    if (errorData.error) {
        err = errorData.error.stack;
    }

    if (typeof err === 'object') {
        if (err.constructor.name === 'CloseEvent') {
            err = JSON.stringify({
                code: err.code,
                datetime: err.timeStamp,
                readyState: err.target.readyState,
                type: err.type,
                url: err.target.url,
            });
        } else {
            const target = err.currentTarget || err.target || err;
            err = JSON.stringify(target, Object.getOwnPropertyNames(target));
        }
    }
    const msg = `
    *${errorData.message.toString()}*\n
:earth_asia: ${window.location.toString()}\n
:flashlight: ${errorData.source || ''} / Col: ${errorData.colno || 0} / Line: ${errorData.lineno || 0}\n
:desktop_computer: ${window.navigator.userAgent}\n
\`${JSON.stringify(window.history.state)}\`\n
`;

    const gh = 'https://github.com/taguchimail/taguchi-admin/blob/master/ui';
    StackTrace.fromError(errorData.error)
        .then((stackframes) => {
            const extraMsg = [].map.call(stackframes, (frame: StackTrace.StackFrame) => {
                if (!frame || !frame.fileName || (frame.fileName && frame.fileName.indexOf('~') >= 0)) {
                    return frame.toString();
                }
                let fname = frame.fileName.split('/').splice(1).join('/');
                const ln = frame.lineNumber ? `#L${frame.lineNumber}` : '';
                if (fname.indexOf('webpack-internal:///.') === 0) {
                    fname = fname.slice('webpack-internal:///.'.length);
                    return `${frame.functionName} at <${gh}/${fname}${ln}|${fname}${ln}>`;
                } else if (fname.indexOf('webpack://') === 0) {
                    fname = fname.slice('webpack://'.length);
                    return `${frame.functionName} at <${gh}/${fname}${ln}|${fname}${ln}>`;
                } else {
                    return frame.toString();
                }
            });
            _send(`${msg}${extraMsg.join('\n')}`);
        })
        .catch((sterror) => {
            const extraMsg = `
                \nError while processing stack frames: ${sterror.toString()}
                \nOriginal error: \`${errorData && errorData.error ? errorData.error.toString() : errorData}\`
            `;
            _send(msg + extraMsg);
        });
}

// Send errors to Slack. Tweak throttle if need be.
// TODO Filter 1006/1001 CloseEvents
_errorSubject
    .asObservable()
    .throttle(1000 * 10 /* 10 seconds */)
    .subscribe((data: IAppErrorData) => {
        if (process.env.NODE_ENV === 'production') {
            sendToSlack(data);
        } else {
            console.error(data);
        }
    });
