import * as Rx from 'rx';
import { defineStore } from 'pinia';
import { ITemplate } from '../../modules/Template';
import { SmartTemplate } from '../../SmartTemplate';
import AppServer from '../../api/AppServer';
import Query from '../../api/Query';
import QueryCommand from '../../api/QueryCommand';
import Cache from '../../Cache';

interface IUIStateTemplate {
    _templateList: ITemplate[] | unknown[];
    __templateCache: Map<string, SmartTemplate>;
}
let fetchTemplateSubscription: Rx.Disposable | null = null;
let fetchTemplateListSubscription: Rx.Disposable | null = null;

export const useStoreTemplate = defineStore('template', {
    state: (): IUIStateTemplate => ({
        _templateList: [],
        __templateCache: new Map(),
    }),
    getters: {
        templateList: (state: IUIStateTemplate) => state._templateList as ITemplate[],
        getTemplate: (state: IUIStateTemplate) => (templateUrl: string) => {
            return state.__templateCache.get(templateUrl);
        },
        getTemplateProfiles: (state: IUIStateTemplate) => (templateUrl: string) => {
            const p = state.__templateCache.get(templateUrl)?.getProfiles();
            if (!p) {
                return [];
            }
            const profileKeys = Object.keys(p);
            if (profileKeys.length) {
                return ['Your account'].concat(profileKeys.sort((a, b) => a.localeCompare(b)));
            }
            return [];
        },
    },
    actions: {
        async fetchTemplate({ organization, templateUrl }): Promise<SmartTemplate> {
            console.log('fetchTemplate', { organization, templateUrl });
            let cmd = new QueryCommand(organization.get('organization') as number);
            const query = new Query('templateContent');
            cmd = cmd.query(query.templateUrl(templateUrl)).sortKey('name').limit(1);
            const conn = AppServer.connection(organization);
            if (!conn) {
                return Promise.reject(new Error('Unable to get connection to fetch template'));
            }
            return new Promise((resolve, reject) => {
                fetchTemplateSubscription = conn
                    .execute(cmd)
                    .first()
                    .subscribe(async (response) => {
                        const t = new SmartTemplate(response.result.data[0]);
                        this.__templateCache.set(templateUrl, t);
                        resolve(t);
                    }, reject);
            });
        },
        async fetchTemplateList({ organization }) {
            if (!organization || !organization.get('organization')) {
                throw new Error('Unable to fetch template list without organization');
            }
            const orgName = organization.get('name');
            const cachedTemplateList = await Cache.get(Cache.ns.templateList, 'templateList', orgName).catch(() =>
                console.warn('Failed to fetch template list from cache.')
            );
            if (cachedTemplateList && cachedTemplateList.templates) {
                this._templateList = cachedTemplateList.templates;
            }
            if (fetchTemplateListSubscription) {
                fetchTemplateListSubscription.dispose();
            }
            let cmd = new QueryCommand(organization.get('organization') as number);
            const query = new Query('template');
            cmd = cmd.query(query).sortKey('name').limit(100);
            const conn = AppServer.connection(organization);
            return new Promise((resolve, reject) => {
                fetchTemplateListSubscription = conn
                    .execute(cmd)
                    .first()
                    .subscribe(async (response) => {
                        const dnow = Date.now();
                        this._templateList = response.result.data;
                        await Cache.set(
                            Cache.ns.templateList,
                            'templateList',
                            {
                                lastUpdated: dnow,
                                templates: this._templateList,
                            },
                            orgName
                        );
                        resolve(this._templateList);
                    }, reject);
            });
        },
        reset() {
            if (fetchTemplateSubscription) {
                fetchTemplateSubscription.dispose();
            }
            if (fetchTemplateListSubscription) {
                fetchTemplateListSubscription.dispose();
            }
            this.$reset();
        },
    },
});
