import { ResourceID } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';
import Rx from 'rx';

interface IAnonymizeSubscriberCommand extends ICommand {
    subscriberId: ResourceID;
}

export interface IAnonymizeSubscriberCommandResponse extends ICommandResponse {
    result: {
        type: 'anonymizeSubscriber';
        data: {
            subscriberId: ResourceID;
        };
    };
}

export default class AnonymizeSubscriber extends AbstractCommand<IAnonymizeSubscriberCommand> {
    public readonly _subscriberId: ResourceID;

    constructor(subscriberId: ResourceID, organizationId: number) {
        super(organizationId, 'anonymizeSubscriber');
        if (typeof subscriberId === 'undefined') {
            throw new Error('Subscriber ID is required to anonymize the target');
        }
        this._subscriberId = subscriberId;
    }

    get subscriberId(): number {
        return this._subscriberId;
    }

    public toJSON(): IAnonymizeSubscriberCommand {
        return Object.assign(
            {},
            {
                subscriberId: this._subscriberId,
                organizationId: this.organizationId,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'anonymizeSubscriber' &&
            response.result.data &&
            response.result.data.subscriberId === this._subscriberId
        );
    }
}
