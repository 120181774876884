<template>
    <svg :class="svgClass">
        <use :href="`#icon-${iconName}`" :style="fill" />
    </svg>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
    name: 'SvgIcon',
    props: {
        icon: {
            type: String,
            default: 'edit',
            required: true,
        },
        color: {
            type: String,
            default: 'currentColor',
        },
        size: {
            type: String,
            default: '',
        },
    },
})
export default class SvgIcon extends Vue {
    private iconName = 'edit';
    private iconWatcher: any;

    public created() {
        // Remove this watcher once we've moved the DPE Franchisee components to Vue
        this.iconWatcher = this.$watch(
            'icon',
            (newv) => {
                this.iconName = newv;
            },
            { immediate: true }
        );
    }

    public beforeDestroy() {
        if (this.iconWatcher) {
            this.iconWatcher();
        }
    }

    public get svgClass() {
        const clazzez = ['icon'];
        if (this.$props.size !== '') {
            clazzez.push(`-${this.$props.size}`);
        }
        if (this.$props.color.charAt(0) !== '#' && this.$props.color !== 'currentColor') {
            clazzez.push(`-${this.$props.color}`);
        }
        return clazzez;
    }
    public get fill() {
        if (this.$props.color?.charAt(0) === '#') {
            return {
                fill: this.$props.color,
            };
        }
        return '';
    }
}
</script>
<style>
@import '../../css/utils/all.css';
/* ==========================================================================
   Icons
   ========================================================================== */
.icon {
    width: 18px;
    height: 18px;
    transition: all 0.3s ease-out;
    fill: var(--text-dark);
    pointer-events: none;
    /* Sizes
   ========================================================================== */
    &.-xxs,
    &.-xx-small {
        width: 11px;
        height: 11px;
    }
    &.-xs,
    &.-x-small {
        width: 12px;
        height: 12px;
    }
    &.-s,
    &.-small {
        width: 14px;
        height: 14px;
    }
    &.-m,
    &.-medium {
        width: 16px;
        height: 16px;
    }
    &.-l,
    &.-large {
        width: 21px;
        height: 21px;
    }
    &.-xl,
    &.-x-large {
        width: 24px;
        height: 24px;
    }
    &.-xxl,
    &.-xx-large {
        width: 32px;
        height: 32px;
    }
    /* Colours
   ========================================================================== */
    &.-dimmed {
        color: var(--text-dark-light);
    }
    &.-black {
        fill: #000;
    }
    &.-white {
        fill: #fff;
    }
    &.-light {
        fill: var(--theme-light-text);
    }
    &.-dark {
        fill: var(--text-dark);
    }
    &.-negative {
        fill: var(--status-negative) !important;
    }
    &.-positive {
        fill: var(--status-positive) !important;
    }
    /* Helpers
   ========================================================================== */
    &.-t1px {
        position: relative;
        top: -1px;
    }
}
</style>
