import Rx from 'rx';
import { IOrganizationConfigCommandResponse } from '../api/OrganizationConfigCommands';
import { IOrganizationConfig } from '../types/IOrganizationConfig';
import { default as AppErrors, Types } from '../AppErrors';
import { default as BaseActions } from '../BaseActions';
import BaseStore from '../BaseStore';
import { UserOrganizationProfile } from '../types/IUser';

let _cache: IOrganizationConfig = {
    trackingCname: '',
    sendingCname: '',
    sendingDkimKeys: {},
    trackingDomains: {},
    sendingDomains: {},
    maxThrottlePerActivityType: {},
};

export class Actions extends BaseActions {
    public static addSendingDomain(
        organization: UserOrganizationProfile,
        domain: string,
        envfrom: string,
        selector: string
    ): Rx.Observable<IOrganizationConfigCommandResponse> {
        return super
            .connection(organization)
            .execute(super.addOrganizationSendingDomainCommand(organization, domain, envfrom, selector))
            .flatMapLatest((response: IOrganizationConfigCommandResponse) => {
                Store.update(response);
                return Rx.Observable.from([response.result.data]);
            })
            .catch((error: Error) => Rx.Observable.throw(error))
            .first();
    }

    public static removeSendingDomain(
        organization: UserOrganizationProfile,
        domain: string
    ): Rx.Observable<IOrganizationConfigCommandResponse> {
        return super
            .connection(organization)
            .execute(super.removeOrganizationSendingDomainCommand(organization, domain))
            .flatMapLatest((response: IOrganizationConfigCommandResponse) => {
                Store.update(response);
                return Rx.Observable.from([response.result.data]);
            })
            .catch((error: Error) => Rx.Observable.throw(error))
            .first();
    }

    public static addTrackingDomain(
        organization: UserOrganizationProfile,
        domain: string
    ): Rx.Observable<IOrganizationConfigCommandResponse> {
        return super
            .connection(organization)
            .execute(super.addOrganizationTrackingDomainCommand(organization, domain))
            .flatMapLatest((response: IOrganizationConfigCommandResponse) => {
                Store.update(response);
                return Rx.Observable.from([response.result.data]);
            })
            .catch((error: Error) => Rx.Observable.throw(error))
            .first();
    }

    public static removeTrackingDomain(
        organization: UserOrganizationProfile,
        domain: string
    ): Rx.Observable<IOrganizationConfigCommandResponse> {
        return super
            .connection(organization)
            .execute(super.removeOrganizationTrackingDomainCommand(organization, domain))
            .flatMapLatest((response: IOrganizationConfigCommandResponse) => {
                Store.update(response);
                return Rx.Observable.from([response.result.data]);
            })
            .catch((error: Error) => Rx.Observable.throw(error))
            .first();
    }
}

export class Store extends BaseStore {
    public static getConfig(organization: UserOrganizationProfile): Rx.Observable<IOrganizationConfig> {
        const command = super.command(organization, 'organizationConfig');
        const query = command.query();
        return super
            .runCommand(organization, command.query(query))
            .map((response: IOrganizationConfigCommandResponse) => Store.update(response));
    }

    public static clearCache() {
        _cache = {
            trackingCname: '',
            sendingCname: '',
            sendingDkimKeys: {},
            trackingDomains: {},
            sendingDomains: {},
            maxThrottlePerActivityType: {},
        };
    }
    public static size() {
        return 1;
    }

    public static update(response: IOrganizationConfigCommandResponse): IOrganizationConfig {
        if (response.error) {
            Rx.Observable.throw(new AppErrors(Types.ApplicationError, response.error));
        }
        Object.assign(_cache, response.result.data);
        return _cache;
    }
}
