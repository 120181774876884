import { IAppState } from './types';

export default {
    searchResults(state: IAppState) {
        return (limit: number) => {
            if (typeof limit === 'undefined') {
                return state.searchResults;
            }
            return state.searchResults.slice(0, limit);
        };
    },
    searchResultsCount(state: IAppState) {
        return state.searchResults.length;
    },
    searchValue(state: IAppState) {
        return state.searchValue;
    },

    activePage(state: IAppState) {
        return state.activePage;
    },

    activePartitionIds(state: IAppState) {
        return state.activePartitionIds;
    },

    appError(state: IAppState) {
        return state.appError;
    },

    organizationUsers(state: IAppState) {
        return state.organizationUsers;
    },

    organizationIssuers(state: IAppState) {
        return state.organizationIssuers;
    },

    organizationTokens(state: IAppState) {
        return state.organizationTokens;
    },

    displayView(state: IAppState) {
        return state.displayView;
    },

    chartDateRange(state: IAppState) {
        return state.chartDateRange;
    },
    customFields(state: IAppState) {
        return state.customFields;
    },

    user(state: IAppState) {
        return state.user;
    },

    organization(state: IAppState) {
        return state.organization;
    },

    fileToImport(state: IAppState) {
        return state.fileToImport;
    },

    partitionSets(state: IAppState) {
        return state.partitionSets;
    },
};
