import Rx from 'rx';

import AppErrors from '../AppErrors';
import { ResourceID, ResourceType } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';

interface IWriteCommand<T> extends ICommand {
    type: ResourceType;
    data: T[];
}

export interface IWriteCommandResponse extends ICommandResponse {
    result: {
        data: ResourceID[];
        type: ResourceType;
    };
}

export default class WriteCommand<T> extends AbstractCommand<IWriteCommand<T>> {
    private readonly _type: ResourceType;
    private _data: T[] = [];

    constructor(resourceType: ResourceType, organizationId: number) {
        super(organizationId, 'writeRecords');
        if (!resourceType) {
            throw new Error('Resource type is required');
        }
        this._type = resourceType;
    }

    public resourceType(): ResourceType {
        return this._type;
    }

    public data(): T[];
    public data(...args: T[]): this;
    public data(...args: T[]): this | T[] {
        if (args && args.length) {
            this._data = args;
            return this;
        }
        return this._data;
    }

    public toJSON(): IWriteCommand<T> {
        return Object.assign(
            {},
            {
                data: this._data,
                organizationId: this.organizationId,
                type: this._type,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error) {
            if (response.error.data) {
                const errData = response.error.data[0];
                throw new AppErrors(errData.name, errData.message ? errData.message : errData);
            } else {
                throw new Error(response.error?.message ?? response.error);
            }
        }
        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        // If matchesQuery is false, it means it's a subscription update. Allow those too.
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === this.resourceType() &&
            response.result.data &&
            Array.isArray(response.result.data) &&
            response.result.data.length === 1 &&
            Number.isInteger(response.result.data[0])
        );
    }
}
