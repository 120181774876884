import { ResourceID } from '../AppTypes';

export interface ICommand {
    readonly organizationId: ResourceID;
}

export interface ICommandResponse {
    error: string | null;
    organizationId: number;
}

export abstract class AbstractCommand<T> implements ICommand {
    public readonly organizationId: number;
    public readonly name: string;

    constructor(organizationId: number, name: string) {
        if (!organizationId) {
            throw new Error('organizationId can not be null or 0');
        }
        if (!name) {
            throw new Error('name can not be empty or null');
        }
        this.organizationId = organizationId;
        this.name = name;
    }

    /**
     * Transform object to its JSON representation
     */
    public abstract toJSON(): T;

    /**
     * Transform object to its String representation
     *
     * This is used for command execution.
     */
    public abstract toString(): string;

    /**
     * Transforms the result of this command
     */
    public abstract transformer(result: any): any;

    /**
     * Filters the given result of this command
     */
    public abstract filter(response: any): boolean;
}
