import { Map as ImmMap } from 'immutable';
import Rx from 'rx';
import AppConfig from '../AppConfig';
import { Types, default as AppErrors, getOrgAuthErrorDescription } from '../AppErrors';
import RxSocketSubject from '../RxSocketSubject/rx-socket-subject';
import SessionStore from '../SessionStore';
import { AbstractCommand } from './Command';
import { Actions as UserActions } from '../modules/User';
import { UserOrganizationProfile } from '../types/IUser';

let _cxnCache = ImmMap<string, AppServerConnection>();
let _failedConnects = 0;
let _failedTimeout = -1;

const _subscriptions = Object.create({
    activity: null,
    auth: null,
    campaign: null,
    cluster: null,
    heartbeat: null,
    list: null,
    socket: null,
    view: null,
});

interface IAppServerStatus {
    connected: boolean;
    authenticated: boolean;
    error: Error | null;
}

interface IAppServerError {
    error: Error | null;
    type: string;
    action?: any;
}

interface IAppServerCmdResponse {
    data: string;
}

interface IAppServerCmdResponseData {
    error: string;
    organizationId: number;
    result: {
        [key: string]: string;
    };
}

export interface IAppServerConnection {
    execute<T>(command: AbstractCommand<T>): Rx.Observable<any>;
    getStats(params: ImmMap<string, any>): Rx.Observable<any>;
    disconnect(): Rx.Observable<IAppServerConnection>;
    requestExportZip(exportRequestId: string): void;
    uploadFile(params: any): Promise<any>;
    authObservable(): Rx.Observable<any>;
    errorObservable(): Rx.Observable<IAppServerError>;
    authInfo: any;
}

export class AppServerConnection implements IAppServerConnection {
    private hostname: string;
    private organization: string;
    private organizationId: string;
    private endpoint: string;
    private connection: any;
    private jwt: string | null;
    private errorSubject: Rx.Subject<IAppServerError>;
    private authenticatedSubject: Rx.Subject<any>;

    private _authInfo = Object.create(null);

    private status: IAppServerStatus = {
        authenticated: false,
        connected: false,
        error: null,
    };

    constructor(hostname: string, organization: string, organizationId: string) {
        const protocol = process.env.TAGUCHI_SESSION_SRV === 'local' ? 'ws://' : 'wss://';
        this.hostname = hostname;
        this.organization = organization;
        this.organizationId = organizationId;
        this.endpoint = `${protocol}${hostname}/uiv5`;
        this.connection = this.connect(hostname, organization);
        this.errorSubject = new Rx.Subject();
        this.authenticatedSubject = new Rx.Subject();
        this.jwt = null;
    }

    public get authInfo() {
        return this._authInfo;
    }

    public get isConnected() {
        return this.status.connected;
    }

    public get isAuthenticated() {
        return this.status.authenticated;
    }

    /**
     * Returns the stats for the given parameters.
     *
     * @param {Immutable.Map} params the parameters to pass to the app server
     * @returns {Rx.Observable} a stats object observable
     */
    public getStats(params: ImmMap<string, any>) {
        const socket = this.connect();
        socket.onNext(
            JSON.stringify({
                command: 'getStats',
                parameters: params,
            })
        );
        return socket
            .flatMapLatest((event: IAppServerCmdResponse) => {
                const response = JSON.parse(event.data);
                if (response.error && response.error === Types.UnauthenticatedCommandError) {
                    return Rx.Observable.throw(new AppErrors(response.error));
                }
                if (response.result && !Object.prototype.hasOwnProperty.call(response.result, 'stats')) {
                    return Rx.Observable.empty();
                }
                return Rx.Observable.from([response]);
            })
            .filter(
                (data: IAppServerCmdResponseData) =>
                    data.organizationId === params.get('organizationId') && data.result && data.result.stats
            );
    }

    public disconnect() {
        this._disposeSubscriptions();
        if (this.connection) {
            this.connection.onCompleted();
            this.connection = null;
        }
        return this.connection;
    }

    public execute<T>(command: AbstractCommand<T>) {
        const socket = this.connect();
        socket.onNext(command.toString());
        return socket.flatMapLatest(command.transformer.bind(command)).filter(command.filter.bind(command));
    }

    public requestExportZip(exportRequestId: string) {
        const form = document.createElement('form');
        form.action = `https://${this.hostname}/uiv5-extract/${this.organization}/${exportRequestId}.zip`;
        form.method = 'post';
        form.style.display = 'none';

        const tokenInput = document.createElement('input');
        tokenInput.type = 'hidden';
        tokenInput.name = 'jwt';
        form.appendChild(tokenInput);

        return UserActions.getOrganizationToken(this.organizationId)
            .first()
            .subscribe((result) => {
                tokenInput.value = result.token;
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            });
    }

    public uploadFile(params: any) {
        // FIXME add typings
        if (!params) {
            throw new Error(`Missing parameters. Can't upload file.`);
        }

        return new Promise((resolve, reject) => {
            UserActions.getOrganizationToken(this.organizationId)
                .first()
                .subscribe((result) => {
                    try {
                        const endpoint = `https://${this.hostname}/uiv5-upload/`;
                        const formData = new FormData();
                        for (const [key, value] of params) {
                            formData.append(key, value);
                        }
                        formData.append('jwt', result.token);
                        formData.append('organizationId', this.organization);

                        fetch(endpoint, {
                            body: formData,
                            method: 'POST',
                            mode: 'cors',
                        }).then((response) => {
                            if (!response.ok) {
                                throw new Error(`File upload failed (${response.status} - ${response.statusText}).`);
                            }
                            response.json().then((data) => {
                                resolve(data);
                            });
                        });
                    } catch (error) {
                        reject(error);
                    }
                });
        });
    }

    public authObservable() {
        return this.authenticatedSubject.asObservable();
    }

    public errorObservable() {
        return this.errorSubject.asObservable();
    }

    protected addSubscription(params: ImmMap<string, any>) {
        const socket = this.connect();
        socket.onNext(
            JSON.stringify({
                command: 'addSubscription',
                parameters: params,
            })
        );
        return socket
            .flatMapLatest((event: IAppServerCmdResponse) => Rx.Observable.from([JSON.parse(event.data)]))
            .filter(
                (data: IAppServerCmdResponseData) =>
                    data.organizationId === params.get('organizationId') && data.result === null
            )
            .first();
    }

    private authenticate(params: ImmMap<string, any>) {
        const socket = this.connect();
        socket.onNext(
            JSON.stringify({
                command: 'authenticateConnection',
                parameters: {
                    organizationId: params.get('organizationId'), // instance numeric organization ID
                    jwt: params.get('token'),
                },
            })
        );

        return socket
            .flatMapLatest((event: IAppServerCmdResponse) => {
                const response = JSON.parse(event.data);
                if (response.result && !Object.prototype.hasOwnProperty.call(response.result, 'authenticated')) {
                    return Rx.Observable.empty();
                }
                this.status.authenticated = response.result.authenticated;

                if (response.result && response.result.authenticated === false) {
                    _cxnCache.remove(`${this.hostname}${this.organization}`);
                    return Rx.Observable.throw(new AppErrors(Types.OrgAuthenticationError));
                } else if (response.result && Object.prototype.hasOwnProperty.call(response.result, 'ssoToken')) {
                    this._authInfo = response.result;
                    this.authenticatedSubject.onNext(this._authInfo);
                }
                return Rx.Observable.from([response]);
            })
            .filter((data: IAppServerCmdResponseData) => data.result && data.result.authenticated)
            .first();
    }

    private setupHeartbeat() {
        if (_subscriptions.heartbeat) {
            return;
        }
        _subscriptions.heartbeat = Rx.Scheduler.default.schedulePeriodic(null, 30 * 1000 /* 30 secs */, () => {
            const socket = this.connect();
            socket.onNext(
                JSON.stringify({
                    command: 'ping',
                    parameters: {
                        organizationId: this.organization,
                    },
                })
            );
        });
    }

    private _subscribe() {
        // Maintain a subscription to keep socket alive, so users
        // are free to manage their own subscriptions.
        this._disposeSubscriptions();
        _subscriptions.socket = this.connection.subscribe(
            () => false,
            (error: Error) => {
                console.error(error);
            }
        );
    }

    private _disposeSubscriptions() {
        Object.keys(_subscriptions)
            .filter((k: string) => _subscriptions[k] !== null)
            .map((k) => {
                _subscriptions[k].dispose();
                _subscriptions[k] = null;
            });
    }

    private createOpenObserver(organization) {
        return Rx.Observer.create(() => {
            _failedConnects = 0;
            // Keep a subscription once we've authenticated.
            _subscriptions.auth = this.authenticate(
                ImmMap({
                    organizationId: organization,
                    token: this.jwt,
                })
            ).subscribe(
                () => {
                    // Setup heartbeat once (re)connected
                    this.setupHeartbeat();
                    this.status.connected = true;
                },
                (error: Error) => {
                    this.errorSubject.onNext(getOrgAuthErrorDescription(error));
                    // Dispose subscriptions if we can't authenticate
                    this._disposeSubscriptions();
                    this.status.error = error;
                }
            );

            // Initialise resource subscriptions.
            // Resource subscriptions are here so that for each server (re)connection,
            // we have a subscription to these resources.
            ['activity', 'campaign', 'list', 'cluster', 'contentBlock'].map((resource) => {
                _subscriptions[resource] = this.addSubscription(
                    ImmMap({
                        organizationId: organization,
                        query: { type: resource },
                    })
                ).subscribe();
            });
        });
    }

    private createErrorObserver(hostname, organization) {
        return Rx.Observer.create((error: CloseEvent) => {
            if (this.connection && error) {
                this.connection.onCompleted();
                this.connection = null;
                this._disposeSubscriptions();
            }

            this.status.error = new Error('Connection error', { cause: error });

            // Only try to reconnect if we have a session id
            if (!SessionStore.hasValidSession()) {
                return;
            }

            _failedConnects += 1;
            if (_failedConnects >= AppConfig.Connection.maxRetries) {
                // We can't recover from this so cleanup ourselves
                if (_failedTimeout) {
                    window.clearTimeout(_failedTimeout);
                    _failedTimeout = -1;
                    _failedConnects = 0;
                }

                const appError = new AppErrors(
                    Types.WSConnectionError,
                    'Connection to Taguchi lost. Please reload the page to reconnect.'
                );
                this.errorSubject.onNext({
                    action: {
                        method: () => window.location.reload(),
                        text: 'Reload page',
                    },
                    error: appError,
                    type: Types.WSConnectionError,
                });
                _cxnCache.remove(`${this.hostname}${this.organization}`);
                this.status.error = appError;
                throw error;
            }

            if (_failedConnects === AppConfig.Connection.notifyFailedRetries) {
                // Notify user that we're having problems reconnecting
                const err = new AppErrors(
                    Types.WSConnectionRetryError,
                    'Connection to Taguchi lost. Please reload the page to reconnect.'
                );
                this.errorSubject.onNext({
                    action: {
                        method: () => window.location.reload(),
                        text: 'Reload page',
                    },
                    error: err,
                    type: Types.WSConnectionRetryError,
                });
                this.status.error = err;
            }

            // Reconnect on error with backoff.
            _failedTimeout = window.setTimeout(
                () => this.connect(hostname, organization, true),
                Math.round((Math.pow(2, _failedConnects) - 1) / 2) * 1000
            );
        });
    }

    private createClosingObserver() {
        return Rx.Observer.create((_) => {
            this._disposeSubscriptions();
            _cxnCache.remove(`${this.hostname}${this.organization}`);

            const err = new AppErrors(Types.WSConnectionClosedError, 'Connection closed by server');

            this.status.connected = false;
            this.status.authenticated = false;
            this.status.error = err;

            console.info('Closed app server connection...', err);
        });
    }

    private connect(hostname = this.hostname, organization = this.organization, force = false) {
        if (!force && this.connection) {
            return this.connection;
        }

        this.connection = RxSocketSubject.create(
            // Retrieve a current JWT prior to opening the endpoint
            UserActions.getOrganizationToken(this.organizationId)
                .first()
                .map((result) => {
                    this.jwt = result.token;
                    return this.endpoint;
                }),
            this.createOpenObserver(organization),
            this.createErrorObserver(hostname, organization),
            this.createClosingObserver()
        );
        this._subscribe();
        return this.connection;
    }
}

export default class AppServer {
    public static connect(hostname: string, organization: string, organizationId: string): AppServerConnection {
        if (!(hostname && organization && organizationId)) {
            throw new Error('Appserver requires a hostname and organization to connect to');
        }
        const key = hostname + organization;
        if (!_cxnCache.has(key)) {
            _cxnCache = _cxnCache.set(key, new AppServerConnection(hostname, organization, organizationId));
        }
        return _cxnCache.get(key) as AppServerConnection;
    }

    public static connection(organization: UserOrganizationProfile, force = false) {
        if (force) {
            AppServer.remove(organization.get('hostname') as string, organization.get('organization') as string);
        }
        return AppServer.connect(
            organization.get('hostname') as string,
            organization.get('organization') as string,
            organization.get('organization_id') as string
        );
    }

    public static remove(hostname: string, organization: string) {
        const key = hostname + organization;
        if (!_cxnCache.has(key)) {
            return;
        }
        _cxnCache = _cxnCache.remove(key);
    }

    public static disconnectAll() {
        _cxnCache.forEach((conn: AppServerConnection | undefined) => conn && conn.disconnect());
        _cxnCache = _cxnCache.clear();
    }
}
