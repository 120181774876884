import { Set as ImmSet } from 'immutable';
import { Roles, check, ACLRules } from '../../ACL';

let userWatcher: () => void;

const checkAccess = (user, org, el, binding) => {
    // Check for arg
    const arg = binding.arg;
    let roles: Roles[] = [];
    let orgs: string[] = [];
    let features: ImmSet<string> = ImmSet();
    let capabilities: ImmSet<string> = ImmSet();
    let userAccountDomain: ImmSet<string> = ImmSet();

    if (arg === 'roles') {
        roles = binding.value;
    } else if (arg === 'orgs') {
        orgs = binding.value;
    } else if (arg === 'features') {
        features = ImmSet(binding.value);
    } else if (arg === 'capabilities') {
        capabilities = ImmSet(binding.value);
    } else if (arg === 'userAccountDomain') {
        userAccountDomain = ImmSet(binding.value);
    } else {
        const rules: ACLRules = binding.value;
        roles = rules.roles ?? [];
        orgs = rules.orgs ?? [];
        features = ImmSet(rules.features ?? []);
        capabilities = ImmSet(rules.capabilities ?? []);
        userAccountDomain = ImmSet(rules.userAccountDomain ?? []);
    }

    const userHasAccess = check(
        user,
        org,
        {
            roles,
            orgs,
            features,
            capabilities,
            userAccountDomain,
        },
        binding.modifiers.all,
        binding.modifiers.not
    );
    if (!userHasAccess) {
        el.style.display = 'none';
    } else if (el.style.display === 'none') {
        // Revert to original value
        el.style.display = '';
    }
};

/**
 * A directive to show/hide elements based on user access controls
 * @example
 * <div v-acl:roles="['admin']">Admin only</div>
 * <div v-acl:orgs="['org1']">Org1 only</div>
 * <div v-acl:features="['feature1']">Feature1 only</div>
 * <div v-acl:capabilities="['cap1']">Cap1 only</div>
 * <div v-acl="{roles: ['admin'], orgs: ['org1'], features: ['feature1'], capabilities: ['cap1']}">All</div>
 * <div v-acl:roles.all="['admin']">Admin only</div>
 * <div v-acl:roles.not="['admin']">Not Admin</div>
 * <div v-acl:tiers="['tier1', 'franchisee']">Tier1 only</div>
 *
 * @param {string} arg - The access control type (roles, orgs, features, capabilities)
 * @param {Array<string>|ACLRules} value - The access control value
 * @param {boolean} all - Check if user has all the roles/orgs/features/capabilities
 * @param {boolean} not - Check if user does not have the roles/orgs/features/capabilities
 */
export default {
    bind: function (el, binding, vnode) {
        // don't display the element initially
        el.style.display = 'none';
        // Check if user has access to the element
        const $store = vnode.context.$store;
        userWatcher = $store.watch(
            (_, getters) => [getters.user, getters.organization],
            (newv) => {
                // Ensure we have at least the user profile before we can check for access controls
                // - org is only available if the user is viewing one (i.e., they are not in the orgs list page)
                const [user, org] = newv;
                if (!user || !org || (org && !org.get('organization'))) {
                    return;
                }
                checkAccess(user, org, el, binding);
            },
            { deep: true, immediate: true }
        );
    },

    unbind: function () {
        if (userWatcher) {
            userWatcher();
        }
    },
};
