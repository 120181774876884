export default {
    en: {
        // Time Format or related
        timestamps: {
            // e.g. 15 May 2022 5:50 pm; profile
            defaultTimestamp: 'D MMM YYYY h:mm a',
            // e.g. 15 May 2022; cardface
            shortTimestamp: 'D MMM YYYY',
            // engagement header
            shortDate: '%e %b %Y',
            shortDateTime: '%H:%M, %e %b %Y',
            // May; engagement
            month: '%b',
            asAt: 'as at',
            weekOf: 'week of',
            to: 'to',
            from: 'from',
        },
        // Global
        contactSupport: 'Contact Support',
        // ActionBar
        actionBar: {
            sortBy: 'Sort by',
            filterBy: 'Filter by',
            recentActivities: 'Recent activities',
            recentlyDeployed: 'Recently deployed',
            recentlyModified: 'Recently modified',
            recentlyArchived: 'Recently archived',
            newest: 'Newest',
            moreFilters: 'More filters',
            idAsc: 'ID: Ascending',
            idDesc: 'ID: Descending',
            nameAsc: 'Name: A-Z',
            nameDesc: 'Name: Z-A',
            oldest: 'Oldest',
            name: 'Name',
            target: 'Target',
            archive: 'Archive',
            unarchive: 'Unarchive',
            publish: 'Publish',
            unpublish: 'Unpublish',
            clone: 'Clone',
            delete: 'Delete',
            filter: 'Filter:',
            allLists: 'All lists',
            dateRange: 'Active w/in chart dates',
            active: 'Active lists',
            dormant: 'Dormant lists',
            empty: 'Empty lists',
            proof: 'Proof lists',
            approval: 'Approval lists',
            notification: 'Notification lists',
            archived: 'Archived Lists',
            subList: 'Subscriber Lists',
            testList: 'Test Lists',
            updateTarget: 'Update Target',
            updatetarget: 'Update target',
            priority: 'Priority',
            pubDate: 'Published Date',
            publishedDate: 'Publish Date',
            expDate: 'Expiry Date',
            tagSubs: 'Targeted subscribers',
            tagContactableSubs: 'Targeted contactable subscribers',
            numberOfAssets: 'Number of assets: ',
            externalID: 'External ID',
            tag: 'tag',
            estOpenRate: 'Estimated open rate',
            sms: 'SMS',
            whatsapp: 'WhatsApp',
            displayad: 'Display Ads',
            email: 'Email',
            line: 'Line',
            web: 'Web',
            type: 'Type',
        },
        // Card Face
        cards: {
            setupAndTarget: 'Setup & Target',
            viewResult: 'View Result',
            activityOptions: 'Activity Options',
            manage: 'Manage',
            edit: 'Edit',
            viewErrors: 'View Errors',
            reports: 'Reports',
            viewResults: 'View Results',
            viewOnline: 'View Online',
            extract: 'Extract',
            viewers: 'viewers',
            recipients: 'recipients',
            content: 'Content',
            viewWebpage: 'View Webpage',
            downloadQR: 'Download QR',
            download: 'Download',
            cardNotViewable: 'This activity is not viewable.',
            extractRecipientsViewers: 'Extract recipients/viewers',
            extractrvsmall: 'extract recipients/viewers',
            extractDownloadReciViewers: 'Extract and download recipients/viewers',
            setup: 'Setup',
            export: 'Export',
            add: 'Add',
            bulkAdd: 'Bulk Add',
            partitionSelector: 'Partitions',
        },
        // Lists
        lists: {
            date: 'Date',
            results: 'Results',
            // similar one in campaignPage.activityTotal, with difference of capital/small A in original text
            numberOfActivities: 'Number Of Activities: ',
            noActivities: 'No activities available within the filter.',
            allLoaded: 'ALL LOADED',
            loadMore: 'LOAD MORE',
            noList: 'No lists available within the filter.',
            type: 'Type',
            public: 'Public',
            proof: 'Proof',
            approval: 'Approval',
            notification: 'Notification',
            listSetup: 'List Setup',
            templateTotal: 'Number of templates: ',
            tagsTotal: 'Number of Tags: ',
            credentialsTotal: 'Number of credentials: ',
            userTotal: 'Number of users: ',
            providerTotal: 'Number of providers: ',
            uploadSubscribers: 'Upload subscribers to list',
            profileFieldsTotal: 'Number of profile field configurations: ',
            fieldsTotal: 'Number of custom fields: ',
            partitionsTotal: 'Number of partitions: ',
            details: 'Details',
            notes: 'Notes',
        },
        // Charts
        charts: {
            engagementChart: {
                title: 'Subscriber engagement',
            },
            contactable: 'contactable',
            contacted: 'contacted',
            engaged: 'engaged',
            converted: 'converted',
            conversionValue: 'conversion value',
            lastweek: 'last week',
            current: 'current',
            campaignChart: {
                title: 'Campaign performance',
            },
            sent: 'sent',
            uniqueOpens: 'unique opens',
            uniqueClicks: 'unique clicks',
            valueSent: 'value / send',
            valueViewed: 'value / viewed',
            listGrowth: 'List growth',
            predictedEngagement: 'predicted engagement',
            predictedConversion: 'predicted conversions',
            predictedTotalValue: 'predicted total value',
            added: 'added',
            removed: 'removed',
            bounced: 'bounced',
            bounceRate: 'bounce rate',
            totalValue: 'total value',
            unsubscribed: 'unsubscribed',
            unsubscribeRate: 'unsubscribe rate',
            totalOpens: 'total opens',
            totalClicks: 'total clicks',
            avgValue: 'avg. value',
            clickToOpen: 'click-to-open',
            convToClick: 'conv.-to-click',
            valuePerClick: 'value / click',
            replied: 'replied',
            invalid: 'invalid',
            value: 'value',
            avgValue2: 'avg value',
            predAvgValue: 'predicted avg value',
            engaged2: 'Engaged',
            converted2: 'Converted',
            value2: 'Value',
            predValue: 'Predicted value',
            avgValue3: 'Avg value',
            predAvgValue2: 'Predicted avg value',
            uniqueViews: 'unique views',
            totalViews: 'total views',
            submitted: 'submitted',
            clickToView: 'click-to-view',
        },
        // Schedule
        schedule: {
            schedule: 'Schedule',
            noScheduled: 'No scheduled activities',
            fetching: 'Fetching scheduled activities...',
            fetchScheduledError: 'An error occurred while fetching scheduled activities. ',
        },
        assetPage: {
            new: 'New Asset',
            allAssets: 'All assets',
            published: 'Published assets',
            archived: 'Archived assets',
            nameAsc: 'Name: A-Z',
            nameDesc: 'Name: Z-A',
            assetSetup: 'Asset Setup',
            selectTemp: 'Select Template...',
            preview: 'Preview',
            deviceType: 'Device type:',
            desktop: 'Desktop',
            mobile: 'Mobile',
            metadata: 'Metadata',
            publishAsset: 'Publish Asset?',
            statistics: 'Statistics',
            lastUpdated: 'Last updated',
            noAssetsAvailable: 'No assets available within the filter.',
            numberOfAssets: 'Number of assets: ',
            downloadAssets: 'Download Assets',
            uploadAssets: 'Upload Assets',
            manageDominosAssets: "Manage Domino's Assets",
        },
        // Settings nav headings
        settingsNav: {
            testProfile: 'Test profile',
            access: 'Access',
            security: 'Security',
            credentials: 'Credentials',
            integrations: 'Integrations',
            organization: 'Organization',
            templateThemes: 'Themes',
            templates: 'Templates',
            tags: 'Tags',
            users: 'Users',
            fileImport: 'Import rules',
            domains: 'Domains',
            advanced: 'Advanced',
            profileFields: 'Profile validation & automation',
            customFields: 'Custom fields',
            partitions: 'Partitions',
            rules: 'Rules',
        },
        // Login page
        login: {
            emailAddress: 'email address',
            password: 'password',
            rememberEmail: 'Remember email address',
            continue: 'Continue',
            forgotPassword: 'Forgot password?',
            createAccount: 'Create an account',
            otpRecoveryCode: 'OTP or Recovery Code',
            submit2fa: 'Submit',
            passwordWeak: 'Password is weak',
            newPassword: 'new password',
            confirmPassword: 'confirm password',
            passwordResetAlert: 'Your password has been reset. Please enter a new password below.',
            passwordsDontMatch: 'Passwords do not match',
            changePasswordAndLogin: 'Change Password and Log In',
            termsOfUse: 'Website Terms of Use',
            privacyPolicy: 'Privacy Policy',
            passwordWasReset:
                'Your password has been reset. Please check your email and follow the instructions you have been sent.',
            returnToLogin: 'Return to login page',
            emailInvalid: 'Email is not valid',
            resetPassword: 'Reset password',
        },
        // User account
        account: {
            detailsCategory: 'Account Details',
            signedInAs: 'Signed in as',
            details: 'Account details',
            logout: 'Log out',
            you: 'You',
            email: 'Email',
            phone: 'Phone',
            language: 'Language',
            otpActivationDisabled: '2FA is disabled. Click to enable.',
            otpActivationEnabled: '2FA is enabled. Click to disable.',
            otpActivationInProgress: '2FA activation in progress...',
            ssoStatus: 'Single sign-on (SSO) Status',
            ssoProvider: 'Your account is managed via the authorized single sign-on provider for',
            twoFaHeading: 'Two-factor (2FA) Authentication',
            twoFaEnable: 'Enable two-factor authentication',
            twoFaDescription:
                "This requires a compliant mobile app like the Google Authenticator app which can be downloaded from your phone's app store.",
            twoFaReadMore: 'Read more',
            scanQrCode: 'Please scan the QR Code below',
            enterOtpCode:
                "Enter the code from the app below. 2FA won't be enabled unless we've verified that the code is correct.",
            otpCodeIncorrect: 'The code you entered is incorrect. Please try again.',
            twoFaNowEnabled: '2FA is now enabled. Download your recovery codes below.',
            twoFaActivationError: 'An error occurred while trying to enable 2FA.',
            twoFaVerificationError: 'An error occurred while trying to verify your 2FA code.',
            otpVerify: 'Verify',
            otpVerificationCode: 'Verification code',
            downloadRecoveryCodes: 'Download recovery codes',
            downloadRecoveryCodesDescription:
                'Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.',
            downloadRecoveryCodesOnlyTime:
                'This will be the only time the following codes are displayed. <b>PLEASE DOWNLOAD</b> for safekeeping.',
            downloadRecoveryCodesButton: 'Download Recovery Codes',
            downloadRecoveryCodesWarning: 'Please download your recovery codes now and save them in a safe place.',
        },
        campaignPage: {
            newCampaign: 'New Campaign',
            startDate: 'Start Date',
            endDate: 'End date (optional)',
            campaignTotal: 'Number of campaigns: ',
            activityTotal: 'Number of activities: ',
            allCampaigns: 'All campaigns',
            dateRange: 'All w/in chart dates',
            active: 'Active campaigns',
            upcoming: 'Upcoming campaigns',
            completed: 'Completed campaigns',
            archived: 'Archived campaigns',
            campaignSetup: 'Campaign Setup',
            details: 'Details',
            notes: 'Notes',
        },
        subscriberPage: {
            newList: 'New List',
            importSubscribers: 'Import Subscribers',
            supported: 'CSV, TSV, XLS & XLSX supported',
            name: 'Name',
            dateAdded: 'Date Added',
            contactable: 'Contactable',
            predEngagement: 'Pred. Engagement',
            predConversion: 'Pred. Conversion',
            predTotalValue: 'Pred. Total Value',
            predAvgValue: 'Pred. Avg. Value',
            listTotal: 'Number of lists: ',
        },
        pagehead: {
            setup: 'setup',
            setupComment: 'Edit settings',
            downloadpdf: 'download pdf',
            downloadcsv: 'download csv',
        },
        activityEditor: {
            activityEditor: 'Activity Editor',
            setupTarget: 'setup & target',
            editActDetails: 'Edit activity details',
            viewWebpage: 'view webpage',
            viewReport: 'view report',
            extract: 'extract ',
            web: 'web',
            recipients: 'recipients',
            viewers: 'viewers',
            generateExtract: 'Generate recipient extract',
            downloadContent: 'download content',
            downloadContentContent: 'Download activity content',
            viewErrors: 'view errors',
            viewErrorContent: 'View activity error(s)',
            errorOccurred: 'The following error(s) have occurred: ',
            idInvalid: 'Activity ID is not valid',
            unableFetch: 'Unable to fetch activity',
            needHelp: 'Need more help?',
            contactSupport: 'Contact our Support team.',
            unsavedChanges:
                'Changes have been made since the last revision was saved. Unsaved changes will not be shown in \
                        proof messages, or in approval requests.',
            runCheck: 'Run Check',
            runTestMatrix: 'Run test matrix',
            runCheckContent: 'Run an activity check',
            uploadTest: 'Upload test matrix',
            uploadTestContent:
                "Test specific profiles to see if they're included in your target expression and view proof emails generated for them",
            replaceTestContent: 'Replace the test matrix for this activity',
            downloadTest: 'Download test matrix',
            downloadTestContent: 'Download test matrix for this activity',
            approvedSetting: 'Activity will be approved with the following setting(s):',
            activityApproved: 'Activity is approved for deployment. Click to reset approval.',
            approveActivity: 'Approve activity for deployment',
            confirm: 'Confirm',
            deployment: 'deployment ',
            deployedSetting: 'Activity will be deployed with the following setting(s):',
            confirmDeploy: 'Confirm deployment of revision ',
            deploy: 'Deploy',
            suspend: 'Suspend',
            resumeSetting: 'Activity will be resumed with the following setting(s):',
            upTo: 'Up to',
            livePreview: 'Live preview',
            revert: 'Revert',
            combination: 'Combination ',
            desktop: 'Desktop HTML',
            mobile: 'Mobile HTML',
            plain: 'Plain Text',
            raw: 'Raw MIME',
            debug: 'Debug info',
            noOverlay: 'No overlay',
            heatmap: 'Heatmap',
            clickP: 'Clickmap (%)',
            clickH: 'Clickmap (#)',
            downloadArchive:
                'Download an archive containing proof emails for all recipients in the current test matrix',
            queuedContent: 'Number of messages currently queued',
            scheduledContent: 'Number of future deployments scheduled',
            sendTime: 'Time of next scheduled send',
            launchPage: 'Launch webpage in a new window',
            summary: 'Summary',
            taguchiError:
                'Taguchi has encountered errors while trying to send this activity. Errors can be caused by \
                        mistakes in template coding, or by incorrectly-configured personalization tags. If you do not \
                        know what the cause of these errors is, please contact your template developer, or Taguchi \
                        support. Include a link to this page in your enquiry.',
            error: ' error',
            s: 's',
            haveOccurred: ' have occurred while attempting to send activity revision ',
            recentError: '. The most recent error occurred at ',
            trackback: 'Traceback',
            context: 'Context',
            warning: 'Warning: ',
            warningExpressionValidation: 'You have not included a subscription list to the Target Expression.',
            wishToProceed: 'Do you wish to proceed?',
        },
        workflow: {
            approvedRevision: 'approved revision ',
            for: ' for ',
            toRecipients: ' to recipients matching ',
            // Chinese have different translation for at in different scenario
            at1: ' at ',
            at2: ' at ',
            upTo: 'up to ',
            mperm: ' messages per minute',
            forDeployment: 'for deployment',
            whatsapp: 'WhatsApp',
            requestWhatsapp: 'WhatsApp template approval',
            whatsappPending: 'pending revision ',
            whatsappReceived: 'received revision ',
            whatsappPaused: 'paused revision ',
            whatsappDisabled: 'disabled revision ',
            whatsappRejectMessage1: "the language and/or template category selected don't match the template content",
            whatsappRejectMessage2: 'placeholders or other elements were formatted incorrectly',
            requestApproval: 'requested approval for revision ID ',
            finishedBroadcast: 'finished broadcasting to ',
            recipients: ' recipients',
            ranCheck: 'ran a check for revision ID ',
            results: 'Results',
            links: 'Links:',
            images: 'Images',
            subjectLines: 'Subject lines',
            fromHeader: "'From ' header:",
            openTrackImage: 'Open tracking image:',
            broadcastDestination: 'Broadcast destination:',
            segment: 'Segment ',
            noSegments: 'No segments have been defined',
            totalRecipients: 'Number of recipients: ',
            contains: ' contains ',
            subscribers: ' subscribers',
            subMatch: ' subscribers match ',
            segments: ' segment',
            emailContent: 'Email content:',
            emailSize: 'Email size:',
            sentA: 'sent a ',
            proofRevisionID: 'proof of revision ID ',
            to: ' to ',
            cancelDeployment: 'cancelled deployment of this activity',
            deployedLatest: 'deployed the latest revision of this activity',
            scheduled: ' scheduled ',
            hourlyDeployment: 'hourly deployment at ',
            pastEachHour: ' past each hour',
            from: ', from ',
            scheduledDeployment: ' scheduled deployment',
            daily: ' daily',
            every: 'every ',
            onThe: 'on the ',
            dayOfMonth: ' day of each month at ',
            scheduledBroadcast: ' scheduled broadcast',
            immeBroadcast: 'immediate broadcast',
            deployActivity: 'deployed this activity',
            view: 'View ',
            rejectApproval: 'rejected approval of revision ID ',
            savedRevisionID: 'saved a new revision ID ',
            revertedRevisionID: '(reverted to revision ID ',
            resetApprovalStatus: 'reset the approval status of this activity ',
            changeTE: 'by changing the target expression to ',
            changeDate: 'by changing the date to ',
            changeTypeTrigger: 'by changing the distribution type to Broadcast or API Trigger from Scheduled Trigger',
            setThrottle: 'set throttle to',
            suspendedDeployment: 'suspended deployment',
            commencedBroadcast: 'commenced broadcast to ',
            addedWorkflowPart: 'added workflow participant',
            removedWorkflowPart: 'removed workflow participant',
            changedSettings: 'changed the following activity settings:',
            TEto: 'target expression to ',
            scheduleFor: 'schedule for ',
            distributionType: 'distribution type to Broadcast or API Trigger from Scheduled Trigger',
            dateTo: 'date to ',
            nameTo: 'name to ',
            campaignIDTo: 'campaign ID to ',
            sendLimitTo: 'send limit to ',
            testMatrix: 'test matrix (',
            download: 'download',
            savedActivitySettings: 'saved activity settings',
            notes: 'notes',
            deployment: 'deployment ',
            broadcast: ' broadcast',
            immediate: 'immediate',
            triggered: 'triggered',
            target: 'Target: ',
            schedule: 'Schedule: ',
            sendUpTo: 'Send up to ',
            pmin: 'per minute',
            atLeast: 'At Least ',
            min: 'minute',
            remianing: ' remaining',
            revisionID: 'Revision ID ',
            participants: 'Participants',
            selected: ' selected',
            unsavedChangesMessage: 'There are unsaved changes. Save your changes before sending proofs.',
            sendProof: 'Send Proof to Participants',
            failedToAddParticipant: 'Failed to add participant for activity ',
            cannotFindParticipant: '. Unable to find a Participant role in this org.',
            throttleWarning: 'Setting the throttle to less than 1 will suspend deployment of this activity.',
            maxThrottleReached:
                'Maximum send rate is set up to {maxThrottle}. Contact Taguchi Support if you need more.',
            addChat: 'Add chat participants',
            cannotAddParticipant: 'Cannot add participants.',
            mention: 'Mention',
            addMe: 'Add me as a participant',
            cancelAdd: 'Cancel Add Participant',
            addParticipant: 'Add participants to your workflow. They can be new or existing users',
            failedCheckImages: 'Failed to check images',
            checked: 'Checked ',
            imagesValid: ' images; all are valid',
            imagesFailed: ' images failed',
            failedCheckLinks: 'Failed to check links',
            linksValid: ' links; all are valid',
            linksFailed: ' links failed',
            failedCheckSub: 'Failed to check subject lines',
            found: 'Found ',
            subLines: ' subject lines',
            subLinesFailed: ' subject lines failed',
            failedCheckContent:
                'Failed to check for bad content in email. Please check email size and/or formatting manually.',
            containsXML:
                'This email contains XML content that may have been copied from Microsoft products, which can cause excessive email size and/or unexpected formatting.',
            passed: 'Passed',
            failedCheckSize: 'Failed to check email size',
            tooBigEmailSize: 'Email size greater than or equal to 200K. Current size: ',
            bigEmailSize: 'Email size should be less than 100KB. Current size: ',
            emailSizeOK: 'Email size is OK: ',
            failedCheckFrom: 'Failed to check for "From" header in email',
            noFrom: 'No "From" header defined',
            failedCheckOpenTrackingImage: 'Failed to check if there is an open-tracking image',
            imageNotPresent: 'Open-tracking image is not present',
            present: 'Present',
            failedCheckDestination: 'Failed to check for broadcast destination',
            noDestination: 'No broadcast destination found',
            failedCheckRecipients: 'Failed to check for number of recipients',
            noRecipients: 'No recipients found',
        },
        report: {
            emailReport: 'Email Activity Report',
            smsReport: 'SMS Activity Report',
            whatsappReport: 'WhatsApp Activity Report',
            setupAndTarget: 'setup & target',
            edit: 'edit',
            extractRecipients: 'extract recipients',
            download: 'download ',
            viewErrors: 'view errors',
            content: 'content',
            activeLinks: 'active links',
            heatmap: 'Click-through heatmap',
            overallPerformance: 'Overall performance',
            lastDeployed: '. Last deployed ',
            performanceOverTime: 'Performance over time',
            allTime: 'all time',
            lastYear: 'last year',
            clickThroughPerformance: 'Click-through performance',
            clientsDevices: 'Clients and devices',
            platform: 'platform usage',
            topBrowsers: 'top browsers',
            overall: 'overall',
            desktop: 'desktop',
            mobile: 'mobile',
            allMobile: 'All mobile',
            allDesktop: 'All desktop',
            decilePerformance: 'Decile performance',
            optiPerformance: 'Optimisation performance',
            combinations: 'combinations',
            topComb: 'top combination',
            estOpenUplift: 'est. open uplift',
            estClickUplift: 'est. click uplift',
            estConvUplift: 'est. conversion uplift',
            estValueUplift: 'est. value uplift',
            listReport: 'List Report',
            setup: 'setup',
            addSub: 'add subscriber',
            import: 'import',
            export: 'export',
            total: 'total',
            subsBySource: 'Contactable subscribers by acquisition source',
            acqType: 'acquisition type',
            topSources: 'top sources',
            campaign: 'campaign',
            allCampaigns: 'All campaigns',
            allImports: 'All imports',
            acqSourcePerformance: 'Acquisition source performance',
            sources: 'sources',
            source: 'Source',
            webActReport: 'Web Activity Report',
            viewWebpage: 'view webpage',
            extractViewers: 'extract viewers',
            viewAd: 'view ad',
            generateExtract: 'Generate extract',
        },
        activitySetup: {
            activitySetup: 'Activity Setup',
            details: 'Details',
            sms: 'SMS',
            distributionChannel: 'Distribution Channel',
            web: 'Web',
            ad: 'Display Ad',
            line: 'LINE Message',
            displayAd: 'Display Ad image type',
            whatsappTwilio: 'WhatsApp Twilio',
            scheduleAutomation: 'Schedule & Automation',
            distributionType: 'Distribution type',
            broadcastTrigger: 'Broadcast or API trigger',
            scheduledTrigger: 'Scheduled trigger',
            eventTrigger: 'Event-based trigger',
            scheduleDateTime: 'Schedule date/time',
            startDateTime: 'Start date/time',
            interval: 'Trigger interval',
            hourly: 'Hourly',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            triggerEvent: 'Trigger event',
            sentActivity: 'Being sent an activity…',
            openActivity: 'Opening an activity…',
            clickActivity: 'Clicking an activity…',
            submitForm: 'Submitting a form…',
            subscribeList: 'Subscribing to a list…',
            purchased: 'Purchasing…',
            abandoned: 'Abandoned',
            viewPage: 'Viewing a web page…',
            purchase: 'Purchase',
            visit: 'Visit',
            conditions: ' conditions',
            addCriterion: 'add criterion',
            activities: 'Activity(-ies)',
            lists: 'List(s)',
            search: 'Search…',
            delay: 'Delay between event and send',
            fraquency: 'Subscriber send frequency',
            notLimited: 'Not Limited',
            sendOnce: 'Send only once',
            sendEvery: 'Send once every…',
            sendLimit: 'Send limit',
            sendLimitContent:
                'Setting this value will cause broadcasts or triggers to abort with no messages sent at all if the distribution would target more than the specified number of recipients. This can be used to protect against targeting mistakes or data problems.',
            notes: 'Notes',
            target: 'Target',
            targetValidationError: 'Your target expression does not include `subscribed list`.',
        },
        activityCheck: {
            failedCheckImages: 'Failed to check images',
            checked: 'Checked ',
            imagesValid: ' images; all are valid',
            imagesFailed: ' images failed',
            failedCheckLinks: 'Failed to check links',
            linksValid: ' links; all are valid',
            linksFailed: ' links failed',
            failedCheckSubjectline: 'Failed to check subject lines',
            found: 'Found',
            subjectlines: ' subject lines',
            subjectFailed: ' subject lines failed',
            failedCheckContent:
                'Failed to check for bad content in email. Please check email size and/or formatting manually.',
            contentError:
                'This email contains XML content that may have been copied from Microsoft products, which can cause excessive email size and/or unexpected formatting.',
            passed: 'Passed',
        },
        te: {
            leastOnce: 'at least once',
            leastNs: 'at least N times',
            nTimes: 'exactly N times',
            oneToNTimes: 'from 1 to N times',
            selectField: 'Select field…',
            anywhere: 'anywhere',
            inActivities: 'in activity(-ies)',
            starting: 'starting',
            matchFollowing: 'that match all the following criteria:',
            anyTime: 'at any time',
            hours: 'Hours',
            nHoursAgo: 'N hour(s) ago',
            inLastNHour: 'in the last N hour(s)',
            inNextNHour: 'in the next N hour(s)',
            days: 'Days',
            yesterday: 'yesterday',
            today: 'today',
            tomorrow: 'tomorrow',
            nDaysAgo: 'N day(s) ago',
            inLastNDays: 'in the last N day(s)',
            inNextNDays: 'in the next N day(s)',
            weeks: 'Weeks',
            lastWeek: 'last week',
            thisWeek: 'this week',
            nextWeek: 'next week',
            nWeekAgo: 'N week(s) ago',
            inLastNWeeks: 'in the last N week(s)',
            inNextNWeeks: 'in the next N week(s)',
            months: 'Months',
            lastMonth: 'last month',
            thisMonth: 'this month',
            nextMonth: 'next month',
            nMonthAgo: 'N month(s) ago',
            inLastNMonths: 'in the last N month(s)',
            inNextNMonths: 'in the next N month(s)',
            years: 'Years',
            lastYear: 'last year',
            thisYear: 'this year',
            nextYear: 'next year',
            nYearAgo: 'N year(s) ago',
            inLastNYears: 'in the last N year(s)',
            inNextNYears: 'in the next N year(s)',
            staticRange: 'Static range',
            fromTo: 'from … to …',
            anything: 'anything',
            activities: 'activity(ies)',
            campaigns: 'campaign(s)',
            allSubs: 'All subscribers',
            random: 'Random N subscribers',
            highestValue: 'Highest-value N subscribers',
            lowestValue: 'Lowest-value N subscribers',
            highestFutureValue: 'Highest predicted value N subscribers',
            lowestFutureValue: 'Lowest predicted value N subscribers',
            mostEngaged: 'Most-engaged N subscribers',
            leastEngaged: 'Least-engaged N subscribers',
            mostRecentlyEngaged: 'N subscribers who most recently engaged',
            mostRecentlyPurchased: 'N subscribers who most recently purchased',
            // All subscribers who ...
            who: 'who',
            select: 'select…',
            like: 'like',
            notLike: 'not like',
            ilike: 'ilike',
            notIlike: 'not ilike',
            oneOf: 'one of',
            notOneOf: 'not one of',
            is: 'is',
            isNot: 'is not',
            logicalOp: 'Logical operators',
            doNotMatch: 'do not match:',
            matchAllOf: 'match all of:',
            matchAnyOf: 'match any of:',
            subQueries: 'Subscription queries',
            subToList: 'are subscribed to any of list(s)',
            unsubFromList: 'are unsubscribed from any of list(s)',
            subProfileQueries: 'Subscriber profile queries',
            birthday: 'have a birthday',
            profilesMatching: 'have profiles matching',
            matchAnyView: 'match any of view(s)',
            contactable: 'are contactable',
            clickQueries: 'Click history queries',
            clickedURL: 'clicked URLs',
            clickedURLStartsWith: 'clicked URL starts with',
            purchaseQueries: 'Purchase history queries',
            purchases: 'have made purchases',
            trackQueries: 'Tracking history queries',
            visited: 'have visited web pages',
            logExternalEvent: 'have logged external event',
            interactionQueries: 'Interaction history queries',
            sent: 'were sent',
            opened: 'opened',
            clicked: 'clicked',
            subVia: 'subscribed via',
            unsubVia: 'unsubscribed via',
            updatedVia: 'updated their profiles via',
            repliedTo: 'replied to',
            viewed: 'viewed',
            entered: 'entered via',
            converted: 'converted via',
            bounced: 'bounced',
            liked: 'liked',
            abandoned: 'abandoned cart via',
            open: 'open',
            view: 'view',
            abandon: 'abandon',
            submit: 'submit',
            update: 'update',
            enter: 'enter',
            addCriterion: 'add criterion',
            withParameter: 'with parameter',
            profileFields: 'Profile fields',
            customFields: 'Custom fields',
            partition: 'belong to partitions',
        },
        subscriber: {
            address: 'Address',
            address2: 'Address line 2',
            address3: 'Address line 3',
            cluster_id: 'Tag ID',
            country: 'Country',
            dob: 'Date of birth',
            email: 'Email',
            extra: 'Additional data',
            firstname: 'First name',
            gender: 'Gender',
            is_invalid: 'Invalid flag',
            is_unsubscribed: 'Global unsubscribe flag',
            lastname: 'Last name',
            notifications: 'Notifications',
            phone: 'Phone',
            postcode: 'Postcode',
            ref: 'External ID',
            resume_date: 'Resume Date',
            state: 'State',
            suburb: 'Suburb',
            title: 'Title',
            is_list_unsubscribed: 'Unsubscribe flag',
            list_option: 'Subscription option',
            subProfile: 'Subscriber Profile',
            uniqueKey: 'Unique key:',
            invalid: 'Invalid',
            unsub: 'Globally unsubscribed',
            profile: 'Profile',
            field: 'Field',
            value: 'Value',
            custom: 'Custom',
            listMember: 'List memberships',
            addSub: 'Add subscriber to list',
            noList: 'Unable to find lists matching',
            option: 'Options: ',
            source: 'Source: ',
            interactionHistory: 'Interaction history',
            view: 'view',
            bounceReason: 'bounce reason: ',
            requestContent: 'Request content: ',
            data: 'Data: ',
            loadedAdditional: 'Loaded additional ',
            items: ' items',
            resetInvalid: 'Reset invalid status',
            markInvalid: 'Mark as invalid',
            resetUnsub: 'Reset globally unsubcribed status',
            markUnsub: 'Mark as globally unsubscribed',
            all: 'all',
            bounced: 'Bounced',
            clicked: 'Clicked',
            entered: 'Entered (web form)',
            opened: 'Opened',
            converted: 'Converted',
            replied: 'Replied',
            sent: 'Sent',
            unsubscribed: 'Unsubscribed',
            viewed: 'Viewed',
            abandoned: 'Abandoned',
            subscribed: 'Subscribed (web form)',
            updated: 'Updated (web form)',
            analytics: 'Analytics',
            saveChangesToField: 'Save changes to field ',
            the: 'The ',
            fieldCannotUpdate: ' field cannot be updated for existing users.',
            enterValue: 'Enter value',
            for: 'for',
            remove: 'Remove ',
            removeCustom: 'Remove custom field ',
            fromSub: ' from subscriber',
            editCustom: 'Edit custom fields',
            addCustom: 'Add custom field',
            enterList: 'Enter list name or ID to search',
            clickToAdd: 'Click to add subscriber to this list',
            subTo: 'Subscribe to ',
            unsubTo: 'Unsubscribe to ',
            sub: 'subscribe',
            unsubscribe: 'unsubscribe',
            newCustom: 'New custom field…',
            listFields: 'List Fields',
            enterCustom: 'Enter custom field name',
            updateSubSuccessfully: 'Successfully updated subscriber profile.',
            editProfile: 'Edit Profile',
            addStandard: 'Add standard profile field',
            selectField: 'Select field...',
            selectCustomField: 'Select custom field...',
            showHide: 'Show/Hide',
            clickToCopy: 'Click to copy',
            apiUpdated: 'Updated',
            apiCreated: 'Created',
        },
        // Settings
        adminPanel: {
            title: 'Admin Panel',
            notes: 'Notes',
            configuration: 'Configuration',
            missingFieldsWarning:
                'No fields were loaded, please configure the required fields for this organization to continue',
            logs: 'Logs',
            buttonView: 'View',
            buttonHide: 'Hide',
        },
        testProfile: {
            title: 'Test profile',
            usage: 'Your test profile is used when generating previews in the activity edit screen and when generating \
            activity previews in reports.',
            changes: 'Changes to these settings',
            donot: 'DO NOT ',
            affect: 'affect other users in your organization.',
            fname: 'First name',
            lname: 'Last name',
            email: 'Email',
            mobile: 'Mobile',
        },
        access: {
            access: 'Access',
            inOrg: 'In this organisation, you ',
            privileges: ' privileges.',
            readMore: 'To read more about user roles click',
            here: 'here',
            changeRole:
                '. If you need your user role changed, please contact an administrator in your organisation. For\
             further assistance, please contact',
            support: 'Support',
            admin: 'Administrator',
            user: 'User',
            baseUser: 'Base User',
            powerUser: 'Power User',
            baseWith: 'are a Base user with ',
            have: `have `,
        },
        template: {
            template: 'Templates',
            available: 'These templates can be used in activities created in this organization.',
        },
        tags: {
            tags: 'Tags',
            usage: "If activities are tagged appropriately, over time your organization's subscribers will\
             be automatically grouped into these tags based on engagement.",
            addNew: 'Add new tag',
            contactable: 'Contactable Subscribers',
            predEngagement: 'Predicted engagement',
            predConversion: 'Predicted conversion',
            predValue: 'Predicted value',
            tagSetup: 'Tag Setup',
            newTag: 'New Tag',
        },
        advanced: {
            advanced: 'Advanced Settings',
            usage: 'These settings may change the way your Taguchi user interface behaves, \
            activate unreleased features, or cause problems. Use with caution, or under the \
            guidance of Taguchi support.',
            debug: 'Enable user interface debugging mode',
            interface: 'Enable beta user interface features',
            keys: 'Beta feature keys',
        },
        security: {
            security: 'Security',
            usage: "The settings below apply additional layers of security to protect your organization's \
            subscriber database.",
            twoFA: 'Require two-factor authentication for all users',
            twoApproval: 'Require two-person approval for extracts',
            adminIP: 'Admin IP whitelist (one per line)',
            noPrivilege: 'You do not have enough privileges to modify the security settings of this organisation.',
            error: 'An unexpected error occurred. Contact Taguchi Support for assistance.',
        },
        // not finished yet
        credentials: {
            credentials: 'Credentials',
            usage: 'Credentials are used when integrating with other third-party services like Facebook or Google Ads, or \
            a custom SFTP integration.',
            newCred: 'Add new credential',
            usedBy: 'Used by',
            createdDate: 'Created date',
            modifiedDate: 'Modified date',
            lastAccessed: 'Last accessed date',
            credentialSetup: 'Credential Setup',
            newCredential: 'New credential',
            credentialType: 'Credential type',
            serverHost: 'Server host or address',
            port: 'Port',
            accountName: 'Account username',
            accountPW: 'Account password',
            accountSID: 'Account SID',
            authObtainedFor: 'Authorization obtained for ',
            requestFromFacebook: ' to request authorization from Facebook.',
            requestFromTikTok: ' to request authorization from TikTok.',
            fromGoogleAds: ' from Google Ads',
            clickHere: 'Click here',
            requestAuthorization: 'Request authorization',
            saveCredentials: 'You must save this credential before authorization can be obtained.',
            zipFilePW: 'ZIP file password (AES)',
            authToken: 'Auth token',
            lineSecret: 'LINE secret',
            authenticationToken: 'Authentication token',
            selectType: 'Please select a valid credential type.',
            apiPublicKey: 'Public key in PEM format',
            apiSecretKey: 'Secret key',
            apiToken: 'API token',
            awsKeyId: 'Access key ID',
            awsSecret: 'Secret access key',
            azureKeyType: 'Key Type',
            azureSecret: 'Secret access key',
            gpgEncryption: 'GPG Encryption Public Key',
            gpgDecryption: 'GPG Decryption Private Key',
            mmApiKey: 'API key',
            mmApiSecret: 'API secret',
            rxWorksApi: 'RXWorks',
            clinicCode: 'Clinic Code',
            alias: 'Alias',
            rxWorksApiKey: 'API key',
        },
        domains: {
            title: 'Tracking domains',
            usage: 'Tracking domains may be used for open and click tracking in emails, click tracking in SMS, website \
            behaviour tracking, and Taguchi web page hosting.',
            addDomain: 'Add tracking domain',
            dnsRecord: 'DNS Record',
            status: 'Status',
            sendingDomains: 'Sending domains',
            sendDomainUsage: 'Sending domains may be used as the From address in emails sent via Taguchi servers.',
            addSendDomain: 'Add sending domain',
            domain: 'Domain',
            dkimSelector: 'DKIM selector',
            returnPath: 'Return-Path',
            dnsRecords: 'DNS records',
            returnPathDomain: 'Return-Path domain:',
            downloadTrackingDomain: 'Download Tracking Domains',
            downloadSendingDomain: 'Download Sending Domains',
            sendingDomainSetup: 'Sending Domain Setup',
            trackingDomainSetup: 'Tracking Domain Setup',
        },
        import: {
            title: 'Import Rules',
            head: 'Messaging and manual list import rules',
            usage: 'Set rules when importing subscribers to your lists, or when creating messages requiring certain texts to be present.',
            rulesJSON: 'Rules JSON',
            saveMessageRules: 'Save messaging rules',
            saveImportRules: 'Save import rules',
        },
        integration: {
            newIntegration: 'New Integration',
            activate: 'Activate',
            deactivate: 'Deactivate',
            all: 'All integrations',
            active: 'Active integrations',
            inactive: 'Inactive integrations',
            idAsc: 'ID: Asc',
            idDesc: 'ID: Desc',
            category: 'Category',
            selectType: 'Select integration type...',
            schedule: 'Schedule start date/time',
            repeats: 'Repeats',
            interval: 'Interval',
            inboundWebhookURL: 'Inbound Webhook URL',
            configuration: 'Configuration',
            extractTarget: 'Extract target',
            dataMapping: 'Map Data',
            saveChanges: 'Save changes',
            credentialToUse: 'Credential to use',
            intSetup: 'Integrations Setup',
            endpointURL: 'Endpoint URL',
            testEndpointURL: 'Test Endpoint URL',
            productionEndpointURL: 'Production Endpoint URL',
            exportDataMapping: 'Map Data for extract',
            exportStaticData: 'Static Data for extract',
        },
        // Organization settings page
        theme: {
            title: 'Organization',
            usage: "These fields determine the appearance of the header bar for this organization, and the organization's \
            card in the organization overview.",
            orgLogo: 'Organization logo URI',
            headerBkgColor: 'Header background color',
            noPrivilege: 'You do not have enough privileges to modify the organization settings.',
            lockedOverlayWarning: 'This item cannot be edited or deleted.',
        },
        themes: {
            title: 'Themes',
            usage: 'Create and customize template themes for use in activities.',
        },
        users: {
            title: 'Users',
            usage: 'These users have access to this organization.',
            addNew: 'Add new user',
            roles: 'Roles',
            validFrom: 'Validity from',
            lastLogin: 'Last login',
            editRole: 'Edit user roles',
            revoke: 'Revoke access',
            revokeAccess: 'Revoke user access from organisation',
            idProviders: 'Identity Providers',
            idProviderUsage: 'These identity providers are authoritative for organization user access.',
            addNewProvider: 'Add new identity provider',
            desc: 'Description',
            exclForDomain: 'Exclusive for domain',
            grantConsent: 'Grant consent',
            disableProvider: 'Disable provider',
            noPrivilege: 'You do not have enough privileges to modify the access settings of this organisation.',
            downloadUser: 'Download Users',
            userSetup: 'User Setup',
            userType: 'User type',
            franchisee: 'Franchisee',
            baseUser: 'Base user',
            powerUser: 'Power user',
            admin: 'Administrator',
            contentRoles: 'Content roles',
            contentContent: 'Content roles involve Campaigns, Activities and Assets.',
            editContent: 'Allow user to create, edit and delete campaigns, activities and assets',
            edit: 'Edit',
            approveContent: 'Allow user to approve activities',
            approve: 'Approve',
            deployContent: 'Allow user to deploy approved activities',
            deploy: 'Deploy',
            dbRoles: 'Database roles',
            dbRolesContent:
                'Database roles involve privileges on subscriber data. This does not involve activity or campaign reports.',
            viewContent: 'Allow user to view subscriber profiles',
            view: 'View',
            manageContent: 'Allow user to manage subscribers, and create, edit and delete subscriber lists',
            manage: 'Manage',
            extractContent: 'Allow user to extract subscriber profiles',
            extract: 'Extract',
            integrationRoles: 'Integration roles',
            intRolesContent: 'Integration roles involve managing integrations.',
            customRoles: 'Custom roles',
            adminHave: ' have all privileges and need 2FA enabled to manage users',
            puHave: ' have all Content and Database rights, but no Settings and user management privileges.',
            buHave: ' can have customised Content and Database roles.',
            admins: 'Administrators',
            powerUsers: 'Power Users',
            baseUsers: 'Base Users',
            newUser: 'New User',
        },
        buttons: {
            save: 'Save',
            cancel: 'Cancel',
            reset: 'Reset',
            delete: 'Delete',
            approve: 'Approve',
            deploy: 'Deploy',
            suspend: 'Suspend',
            resume: 'Resume',
            saveEdit: 'Save & Edit',
            close: 'Close',
            anonymize: 'Anonymize',
            add: 'Add',
            remove: 'Remove',
            proceed: 'Proceed',
        },
        mainHeader: {
            contact: 'Contact',
            support: 'Support',
            supportPortal: 'Support portal',
            supportDoc: 'Support documentation',
            userGuide: 'User Guide',
            smsGuide: 'SMS user guide (PDF)',
            knowledgeBase: 'Knowledge base',
            webFormsDoc: 'Web forms documentation',
            apiDoc: 'API documentation',
            sourceCode: 'Source code',
        },
        store: {
            subEngagement: 'Subscriber engagement',
        },
        fields: {
            clear: 'Clear',
            selectField: 'Select field...',
        },
        notifications: {
            fromMe: 'From Me',
            allOrg: 'All Organizations',
            proofs: 'Proofs',
            extracts: 'Extracts',
            checks: 'Checks',
            approvals: 'Approvals',
            sends: 'Sends',
        },
        profileFields: {
            addNew: 'Add new field configuration',
            usage: 'Manage organization profile field automation and validation',
            field: 'Field name',
            description: 'Description',
            schema: 'Schema',
            statistics: {
                profileFieldStatistics: 'Profile Field Statistics',
                subWithValue: 'Number of subscribers with field value:',
                subWithInvalidValue: 'Number of subscribers with invalid field value:',
                uniqueValue: 'Number of unique values:',
                uniqueInvalidValue: 'Number of unique invalid values:',
                commonValidValue: 'Most common valid values:',
                commonInvalidValue: 'Most common invalid values:',
                lastUpdate: 'Statistics last updated at:',
            },
            profileFieldSetup: 'Profile field setup',
            selectStandardField: 'Select standard field',
            calculationType: 'Calculation type',
            calculationTypes: {
                static: 'Static profile field',
                calculated: 'Calculated profile field',
            },
            rule: 'Rule',
            clear: 'Clear value from subscribers not matching the target expression',
            update: 'Replace previous value for subscribers matching the target expression',
            targetExpression: 'Target expression',
            regex: 'Validation regex',
        },
        customFields: {
            addNew: 'Add new field',
            usage: 'Manage organization custom fields',
            customFields: 'Custom fields',
            type: 'Field type',
            calculationType: 'Calculation type',
            calculationTypes: {
                static: 'Static custom field',
                calculated: 'Calculated custom field',
            },
            field: 'Field name',
            groupName: 'Group name',
            targetExpression: 'Target expression',
            rule: 'Rule',
            clear: 'Clear value from subscribers not matching the target expression',
            update: 'Replace previous value for subscribers matching the target expression',
            frequency: 'Frequency',
            types: {
                subscriber: 'Subscriber',
                purchase: 'Purchase',
                product: 'Product',
                purchaseProduct: 'Purchase line item',
                visit: 'Visit',
                subscriberEvent: 'Subscriber event',
                page: 'Page',
                partition: 'Partition',
                subscriberList: 'Subscriber list',
            },
            customFieldSetup: 'Custom field setup',
            newCustomField: 'New field',
            description: 'Description',
            schema: 'Schema',
            downloadCustomFields: 'Download custom fields',
            uploadCustomFields: 'Upload custom fields',
            startDateTime: 'Start date/time',
            interval: 'Trigger interval',
            once: 'Once',
            hourly: 'Hourly',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            regex: 'Validation regex',
            statistics: {
                customFieldStatistics: 'Custom Field Statistics',
                subWithValue: 'Number of subscribers with field value:',
                subWithInvalidValue: 'Number of subscribers with invalid field value:',
                uniqueValue: 'Number of unique values:',
                uniqueInvalidValue: 'Number of unique invalid values:',
                commonValidValue: 'Most common valid values:',
                commonInvalidValue: 'Most common invalid values:',
                lastUpdate: 'Statistics last updated at:',
            },
        },
        partitions: {
            addNew: 'Add new partition',
            usage: 'Manage organization partitions',
            partitions: 'Partitions',
            customFields: 'Custom fields',
            clearCustomField: 'Clear',
            ref: 'External ID',
            partitionSetup: 'Partition setup',
            newPartition: 'New partition',
            downloadPartitions: 'Download partitions',
            uploadPartitions: 'Upload partitions',
            partitionSelector: 'Partition Selector',
        },
        featureGmailPromo: {
            dealAnnotation: 'Deal Annotation',
            dealAnnotationContent: 'Add Deal Annotations to your emails to help your recipients take action.',
        },
        select: 'Select...',
        // Side nav
        organization: 'Organization',
        dashboard: 'Dashboard',
        campaigns: 'Campaigns',
        campaign: 'Campaign',
        assets: 'Assets',
        subscribers: 'Subscribers',
        settings: 'Settings',
        franchisees: 'Franchisees',
        // Dashboard
        newActivity: 'New Activity',
        // Placeholders
        searchPlaceholder: 'Search',
        enterToSearchPlaceholder: 'Press enter key to apply filter',
    },
    'id-ID': {
        // ActionBar
        actionBar: {
            sortBy: 'Sortir dengan',
            filterBy: 'Menyaring menurut',
            recentActivities: 'Aktivitas terbaru',
            recentlyDeployed: 'Baru-baru ini dikerahkan',
            recentlyModified: 'Baru dimodifikasi',
            recentlyArchived: 'Baru-baru ini diarsipkan',
            newest: 'Terbaru',
            moreFilters: 'Lebih banyak filter',
            sms: 'SMS',
            whatsapp: 'WhatsApp',
            displayad: 'Display Ads',
            email: 'Email',
            line: 'Line',
            web: 'Web',
            type: 'Tipe',
        },
        charts: {
            engagementChart: {
                title: 'Keterlibatan pelanggan',
            },
            contactable: 'dapat dihubungi',
            contacted: 'dihubungi',
            engaged: 'terlibat',
            converted: 'dikonversi',
            conversionValue: 'nilai konversi',
            lastweek: 'minggu lalu',
            current: 'saat ini',
        },
        // Schedule
        schedule: {
            schedule: 'Jadwal',
            noScheduled: 'Tidak ada kegiatan terjadwal',
            fetching: 'Mengambil aktivitas yang dijadwalkan...',
        },
        // Side nav
        organization: 'Organisasi',
        dashboard: 'Dasbor',
        campaigns: 'Kampanye',
        campaign: 'Kampanye',
        assets: 'Aset',
        subscribers: 'Pelanggan',
        settings: 'Pengaturan',
        franchisees: 'Penerima Waralaba',
        // Dashboard
        newActivity: 'Aktivitas Baru',
        // Placeholders
        searchPlaceholder: 'Mencari',
    },
    ja: {},
    'zh-TW': {
        // Timestamp or related
        timestamps: {
            defaultTimestamp: 'YYYY-MM-DD h:mm a',
            shortTimestamp: 'YYYY年MM月DD日',
            shortDate: '%Y-%m-%d',
            shortDateTime: '%Y-%m-%d, %H:%M',
            month: '%m月',
            asAt: '查詢日 ',
            weekOf: '查詢周 ',
            to: ' 至 ',
            from: '从',
        },
        // Global
        contactSupport: '聯繫客服',
        // ActionBar
        actionBar: {
            sortBy: '排序',
            filterBy: '篩選',
            recentActivities: '近期活動',
            recentlyDeployed: '近期部署',
            recentlyModified: '近期修改',
            recentlyArchived: '近期歸檔',
            newest: '最新',
            moreFilters: '更多篩選',
            idAsc: 'ID: 升序',
            idDesc: 'ID: 降序',
            nameAsc: '活動名: A-Z',
            nameDesc: '活動名: Z-A',
            oldest: '最舊',
            name: '名稱',
            target: '目标',
            archive: '歸檔',
            unarchive: '取消歸檔',
            publish: '發佈',
            unpublish: '取消發佈',
            clone: '克隆',
            delete: '刪除',
            filter: '篩選: ',
            allLists: '所有列表',
            dateRange: '查詢周內活躍列表',
            active: '活躍列表',
            dormant: '休眠列表',
            empty: '空列表',
            proof: '校樣列表',
            approval: '批准列表',
            notification: '通知列表',
            archived: '歸檔列表',
            subList: '订阅用户列表',
            testList: '测试列表',
            updateTarget: '更新目標',
            updatetarget: '更新目標',
            priority: '重要度',
            pubDate: '發佈日期',
            publishedDate: '發佈日期',
            expDate: '過期日期',
            tagSubs: '目標訂閱者',
            tagContactableSubs: '目標可聯繫訂閱者',
            numberOfAssets: '圖集數：',
            externalID: '外部ID',
            tag: '標籤',
            estOpenRate: '預計打開比例',
            sms: 'SMS',
            whatsapp: 'WhatsApp',
            displayad: '展示广告',
            email: '郵件',
            line: 'Line',
            web: '網頁',
            type: '類型',
        },
        // Card Face
        cards: {
            setupAndTarget: '設置與目標',
            viewResult: '查看結果',
            activityOptions: '活動選項',
            manage: '管理',
            edit: '編輯',
            viewErrors: '查看錯誤',
            reports: '報告',
            viewResults: '查看結果',
            viewOnline: '在線查看',
            extract: '提取',
            viewers: '查看人',
            recipients: '收件人',
            content: '內容',
            viewWebpage: '查看頁面',
            downloadQR: '下載二維碼',
            download: '下載',
            cardNotViewable: '此項目不可見',
            extractRecipientsViewers: '提取收件人/查看人',
            extractrvsmall: '提取收件人/查看人',
            extractDownloadReciViewers: '提取並下載收件人/查看人',
            setup: '設置',
            export: '導出',
            add: '添加',
            bulkAdd: '批量添加',
            partitionSelector: '分區選擇器',
        },
        // Lists
        lists: {
            date: '日期',
            results: '結果',
            numberOfActivities: '活動數量：',
            noActivities: '無活動符合篩選條件。',
            allLoaded: '已全部加載',
            loadMore: '加載更多',
            noList: '該篩選下沒有符合的列表。',
            type: '類型',
            public: '公共',
            proof: '校對',
            approval: '批准',
            notification: '通知',
            listSetup: '列表設置',
            templateTotal: '模版總數：',
            tagsTotal: '標籤總數：',
            credentialsTotal: '憑證總數：',
            userTotal: '用戶總數：',
            providerTotal: '身份提供者總數：',
            uploadSubscribers: '上傳訂閱者至列表',
            fieldsTotal: '客製信息欄總數：',
            partitionsTotal: '分區總數：',
            details: '詳情',
            notes: '筆記',
        },
        // Charts
        charts: {
            engagementChart: {
                title: '用戶互動',
            },
            contactable: '可聯係',
            contacted: '已聯繫',
            engaged: '已互動',
            converted: '已轉換',
            conversionValue: '轉換金額',
            lastweek: '上週',
            current: '當前',
            campaignChart: {
                title: '系列活動表現',
            },
            sent: '已發送',
            uniqueOpens: '不重複打開',
            uniqueClicks: '不重複點擊',
            valueSent: '價值/已發送',
            valueViewed: '價值/已查看',
            listGrowth: '列表增長',
            predictedEngagement: '預期互動',
            predictedConversion: '預期轉換值',
            predictedTotalValue: '預期總價值',
            added: '新增',
            removed: '移除',
            bounced: '反彈',
            bounceRate: '反彈率',
            totalValue: '總價值',
            unsubscribed: '退訂',
            unsubscribeRate: '退訂率',
            totalOpens: '總打開',
            totalClicks: '總點擊',
            avgValue: '平均價值',
            clickToOpen: '點擊對打開',
            convToClick: '轉化對點擊',
            valuePerClick: '價值/點擊',
            replied: '已回復',
            invalid: '無效',
            value: '價值',
            avgValue2: '平均價值',
            predAvgValue: '預期平均價值',
            engaged2: '已互動',
            converted2: '已轉化',
            value2: '價值',
            predValue: '預期價值',
            avgValue3: '平均價值',
            predAvgValue2: '預期平均價值',
            uniqueViews: '不重複閱覽',
            totalViews: '總閱覽',
            submitted: '已提交',
            clickToView: '點擊對閱覽',
        },
        // Schedule
        schedule: {
            schedule: '計劃進行',
            noScheduled: '無計劃中的活動',
            fetching: '正在獲取計劃中的活動...',
            fetchScheduledError: '獲取計劃活動時發生錯誤',
        },
        assetPage: {
            new: '新圖片',
            allAssets: '所有圖片',
            published: '發佈的圖片',
            archived: '歸檔的圖片',
            nameAsc: '名稱: A-Z',
            nameDesc: '名稱: Z-A',
            assetSetup: '圖片設置',
            selectTemp: '選擇模版',
            preview: '預覽',
            deviceType: '設備類型：',
            desktop: '電腦',
            mobile: '移動',
            metadata: '元數據',
            publishAsset: '發佈圖片？',
            statistics: '數據',
            lastUpdated: '最後更新',
            noAssetsAvaible: '沒有圖片符合該搜索條件',
            numberOfAssets: '圖片數：',
            downloadAssets: '下載圖片',
            uploadAssets: '上傳圖片',
            manageDominosAssets: '管理達美樂的圖片',
        },
        // Settings nav headings
        settingsNav: {
            testProfile: '測試用戶',
            access: '訪問',
            security: '安全',
            credentials: '憑證',
            integrations: '集成',
            templateTheming: '品牌',
            organization: '企業',
            templates: '模版',
            tags: '標籤',
            users: '用戶',
            fileImport: '文件導入',
            domains: '域名',
            advanced: '高級',
            customFields: '客製資料欄',
            partitions: '分區',
            rules: '規則',
        },
        // Login page
        login: {
            emailAddress: '電子郵箱',
            password: '密碼',
            rememberEmail: '記住我的郵箱',
            continue: '繼續',
            forgotPassword: '忘記密碼？',
            createAccount: '創建一個新賬戶',
            otpRecoveryCode: 'OTP或恢復碼',
            submit2fa: '提交',
            passwordWeak: '密碼太簡單',
            newPassword: '新密碼',
            confirmPassword: '確認密碼',
            passwordResetAlert: '您的密碼已重置。請輸入新密碼。',
            passwordsDontMatch: '密碼不一致',
            changePasswordAndLogin: '修改密碼並登錄',
            termsOfUse: '網站使用條款',
            privacyPolicy: '隱私政策',
            passwordWasReset: '您的密碼已重置。請查閱您的郵箱並根據說明操作。',
            returnToLogin: '回到登錄頁面',
            emailInvalid: '無效郵箱',
            resetPassword: '重置密碼',
        },
        // User account
        account: {
            detailsCategory: '賬戶信息',
            signedInAs: '登錄身份',
            details: '賬戶信息',
            logout: '登出',
            you: '你',
            email: '郵箱',
            phone: '電話',
            language: 'Language',
            otpActivationDisabled: '2FA已停用。點擊啟用',
            otpActivationEnabled: '2FA已啟用。點擊停用。',
            otpActivationInProgress: '2FA啟動中...',
            ssoStatus: '單一登入(SSO)狀態',
            ssoProvider: '您的賬戶由已授權的單一登入提供商管理。',
            twoFaHeading: '雙重認證(2FA)',
            twoFaEnable: '啟動雙重認證',
            twoFaDescription: '需要有合規的移動程序，如Google Authenticator, 可前往手機應用商店下載',
            twoFaReadMore: '閱讀更多',
            scanQrCode: '請掃描下方二維碼',
            enterOtpCode: '請在下方輸入移動程序中的驗證碼。驗證碼確定正確後，雙向驗證才會啟動。',
            otpCodeIncorrect: '驗證碼不正確，請重試。',
            twoFaNowEnabled: '雙向驗證已啟用。下方下載恢復碼。',
            twoFaActivationError: '啟動雙向驗證時發生錯誤。',
            twoFaVerificationError: '驗證雙向驗證碼時發生錯誤。',
            otpVerify: '驗證',
            otpVerificationCode: '驗證碼',
            downloadRecoveryCodes: '下載恢復碼',
            downloadRecoveryCodesDescription: '恢復碼可以在您丟失您的手機，無法收到雙向驗證碼時使用訪問您的賬戶。',
            downloadRecoveryCodesOnlyTime: '此恢復碼僅顯示一次。請下載並妥善保存。',
            downloadRecoveryCodesButton: '下載恢復碼',
            downloadRecoveryCodesWarning: '請下載您的恢復碼並妥善保存。',
        },
        campaignPage: {
            newCampaign: '新系列活動',
            startDate: '開始日期',
            endDate: '結束日期（可選）',
            campaignTotal: '系列活動總數：',
            activityTotal: '活動總數：',
            allCampaigns: '所有系列活动',
            dateRange: '查询日期内的系列活动',
            active: '活躍的系列活动',
            upcoming: '未開始的系列活动',
            completed: '完成的系列活动',
            archived: '歸檔的系列活动',
            campaignSetup: '係列活動設置',
            details: '詳情',
            notes: '筆記',
        },
        subscriberPage: {
            newList: '新列表',
            importSubscribers: '導入訂閱用戶',
            supported: '支持CSV, TSV, XLS & XLSX',
            name: '名稱',
            dateAdded: '添加日期',
            contactable: '可聯繫',
            predEngagement: '預期互動',
            predConversion: '預期平均轉換',
            predTotalValue: '預期總價值',
            predAvgValue: '預期均價',
            listTotal: '列表總數：',
        },
        pagehead: {
            setup: '設置',
            setupComment: '編輯設置',
            downloadpdf: '下載pdf',
            downloadcsv: '下載csv',
        },
        activityEditor: {
            activityEditor: '編輯活動',
            setupTarget: '設置與目標',
            editActDetails: '編輯活動細節',
            viewWebpage: '查看網頁',
            viewReport: '查看報告',
            extract: '提取 ',
            web: '網頁',
            recipients: '收件人',
            viewers: '瀏覽者',
            generateExtract: '提取收件人',
            downloadContent: '下載內容',
            downloadContentContent: '下載活動內容',
            viewErrors: '查看錯誤',
            viewErrorContent: '查看活動錯誤',
            errorOccurred: '發生了以下錯誤：',
            idInvalid: '活動ID無效',
            unableFetch: '無法獲取活動',
            needHelp: '需要更多活動？',
            contactSupport: '聯繫我們的技術支持團隊。',
            unsavedChanges: '上次保存的修改後發生了改變。未保存的修改不會出現在校樣郵件或批准請求中。',
            runCheck: '檢查',
            runTestMatrix: '運行測試數據',
            runCheckContent: '運行活動檢查',
            uploadTest: '上傳測試數據',
            uploadTestContent: '測試特定用戶，以確認他們是否在您的目標表達中，並查看他們的校驗郵件',
            replaceTestContent: '替換這個活動的測試數據',
            downloadTest: '下載測試數據',
            downloadTestContent: '下載這個活動的測試數據',
            approvedSetting: '活動將被通過，包括以下設置：',
            activityApproved: '活動已被批准部署。點擊這裡重置批准狀態。',
            approveActivity: '批准活動以部署',
            confirm: '確認',
            deployment: '部署',
            deployedSetting: '活動將被部署，包括以下設置：',
            confirmDeploy: '確認修改的部署 ',
            deploy: '部署',
            suspend: '停止',
            resumeSetting: '活動將被恢復，包括以下設置：',
            upTo: '最多',
            livePreview: '實時預覽',
            revert: '還原',
            combination: '組合',
            desktop: '電腦端HTML',
            mobile: '手機端HTML',
            plain: '文本',
            raw: '原始MIME',
            debug: '調試信息',
            noOverlay: '無重疊',
            heatmap: '熱度圖',
            clickP: '點擊圖(%)',
            clickH: '點擊圖(#)',
            downloadArchive: '下載含所有當前測試數據的校驗郵件的歸檔',
            queuedContent: '當前排隊中的郵件',
            scheduledContent: '當前計劃中的未來部署',
            sendTime: '下一個計劃送出的時間',
            launchPage: '在新窗口中打開頁面',
            summary: '總結',
            taguchiError:
                'Taguchi在嘗試發送活動時發生了錯誤。錯誤可能存在在模版代碼中，或由錯誤的客製標簽導致。如果您不確定錯誤發生的原因，請聯繫您的模版開發者，或聯繫Taguchi Support, 並將該頁面的鏈接附在您的詢問中。',
            //這裡更改了語序。修改時參考英文原版。
            error: '個錯誤',
            s: '',
            haveOccurred: '在嘗試發送活動修改版本',
            recentError: '時發生了。最近一個錯誤發生於',
            trackback: '回溯',
            context: '上下文',
            warning: '警告：',
            warningExpressionValidation: '目標表達式中沒有訂閱列表。',
            wishToProceed: '您想繼續嗎？',
        },
        workflow: {
            approvedRevision: '批准了修改版本',
            for: '進行',
            toRecipients: '，收件人符合',
            // at1 e.g. 以最多100信息每分鐘
            at1: '以',
            // at2 e.g. 在10:15 每天
            at2: '在',
            upTo: '最多',
            mperm: '信息每分鐘',
            forDeployment: '以部署',
            requestApproval: '請求了批准，修改版本ID ',
            finishedBroadcast: '結束了廣播，共',
            recipients: '收件人',
            ranCheck: '運行了一個檢查，修改版本ID',
            results: '結果',
            links: '鏈接：',
            images: '圖像：',
            subjectLines: '主題行：',
            fromHeader: "'來自 '標頭：",
            openTrackImage: '打開追蹤圖像：',
            broadcastDestination: '廣播目標：',
            segment: '分類',
            noSegments: '無已定義的分類',
            totalRecipients: '收件人總數：',
            contains: '包括',
            subscribers: '訂閱用戶',
            subMatch: '訂閱用戶符合',
            segments: '分類',
            emailContent: '郵件內容：',
            emailSize: '郵件大小：',
            sentA: '發送了一個',
            proofRevisionID: '校驗，修改版本ID',
            to: '至',
            cancelDeployment: '取消了這個活動的部署',
            deployedLatest: '部署了這個活動的最新修改版本',
            scheduled: '安排了',
            hourlyDeployment: '每小時一次的部署，在',
            pastEachHour: '的每小時',
            from: '，從',
            scheduledDeployment: '安排了部署',
            daily: '每天',
            every: '每',
            onThe: '在',
            dayOfMonth: '日的每個月，在',
            scheduledBroadcast: '安排了廣播',
            immeBroadcast: '立刻廣播',
            deployActivity: '部署了這個活動',
            view: '查看',
            rejectApproval: '拒絕了這個批准請求，修改版本ID ',
            savedRevisionID: '保存了修改版本ID ',
            revertedRevisionID: '（恢復至修改版本ID ',
            resetApprovalStatus: '重設了該活動的批准狀態',
            changeTE: '，通過修改目標表達式為',
            changeDate: '，通過修改日期為',
            changeTypeTrigger: '，通過修改發佈類型，從計劃出發改為廣播或API觸發',
            setThrottle: '設置閥門為',
            suspendedDeployment: '停止部署',
            commencedBroadcast: '開始廣播至',
            addedWorkflowPart: '添加工作流程參與者',
            removedWorkflowPart: '去除工作流程參與者',
            changedSettings: '改變了以下活動設置：',
            TEto: '目標表達式為',
            scheduleFor: '計劃了 ',
            distributionType: '發佈類型從計劃触發改為廣播或API觸發',
            dateTo: '日期至',
            nameTo: '名字至',
            campaignIDTo: '系列活動ID至',
            sendLimitTo: '發送上限為',
            testMatrix: '測試矩陣（',
            download: '下載',
            savedActivitySettings: '保存了活動設置',
            notes: '筆記',
            deployment: '部署',
            broadcast: '廣播',
            immediate: '即時',
            triggered: '觸發',
            target: '目標：',
            schedule: '計劃：',
            sendUpTo: '至多發送',
            pmin: '每分鐘',
            atLeast: '最少',
            min: '分',
            remianing: '剩餘',
            revisionID: '修改版本ID',
            participants: '參與者',
            // 1 selected 加了一個單位
            selected: '位已選擇',
            unsavedChangesMessage: '存在未保存的更改。發送校驗郵件前請先保存更改。',
            sendProof: '發送校驗郵件給參與者。',
            failedToAddParticipant: '未能成功添加參與者至活動',
            cannotFindParticipant: '。企業內未能找到該參與者。',
            throttleWarning: '設置上限小於1會導致該活動的部署被中止。',
            maxThrottleReached: '發送速率上限為6萬。如果需要更高，請聯繫Taguchi客服。',
            addChat: '添加聊天參與者',
            cannotAddParticipant: '不能添加參與者',
            mention: '提到',
            addMe: '把我添加為參與者',
            cancelAdd: '取消添加參與者',
            addParticipant: '添加參與者到你的工作流程。他們可以是新的或是已有的用戶。',
            failedCheckImages: '檢查圖片失敗',
            checked: '檢查了',
            imagesValid: '張圖片；全部合格',
            imagesFailed: '張圖片失敗',
            failedCheckLinks: '檢查鏈接失敗',
            linksValid: '個鏈接；全部合格',
            linksFailed: '個鏈接失敗',
            failedCheckSub: '檢查主題行失敗',
            found: '檢測到',
            subLines: '個主題行',
            subLinesFailed: '個主題行失敗',
            failedCheckContent: '檢查郵件中錯誤內容失敗。請自行檢查郵件大小和/或格式。',
            containsXML: '該郵件中含有微軟產品中拷貝來的XML內容，這會導致郵件過大或排版格式錯誤。',
            passed: '通過',
            failedCheckSize: '檢查郵件大小失敗',
            tooBigEmailSize: '郵件大於200K。現在大小：',
            bigEmailSize: '郵件大於100K。現在大小：',
            emailSizeOK: '郵件大小OK：',
            failedCheckFrom: '檢查郵件中“From”失敗',
            noFrom: '"From"未定義',
            failedCheckOpenTrackingImage: '檢查打開追蹤圖片失敗',
            imageNotPresent: '打開追蹤圖片不存在',
            present: '存在',
            failedCheckDestination: '檢查廣播目標失敗',
            noDestination: '未找到廣播目標',
            failedCheckRecipients: '檢查收件人數失敗',
            noRecipients: '未找到收件人',
        },
        report: {
            emailReport: '郵件活動報告',
            smsReport: 'SMS活動報告',
            whatsappReport: 'WhatsApp 活动报告',
            setupAndTarget: '設置與目標',
            edit: '編輯',
            extractRecipients: '提取收件人',
            download: '下載',
            viewErrors: '查看錯誤',
            content: '內容',
            activeLinks: '活躍鏈接',
            heatmap: '點擊熱度圖',
            overallPerformance: '總體表現',
            lastDeployed: '. 上次部署 ',
            performanceOverTime: '長時間表現',
            allTime: '全部時間',
            lastYear: '去年',
            clickThroughPerformance: '點擊表現',
            clientsDevices: '客戶和設備',
            platform: '平台使用',
            topBrowsers: '常用瀏覽器',
            overall: '總體',
            desktop: '電腦端',
            mobile: '移動端',
            allMobile: '全部移動端',
            allDesktop: '全部電腦端',
            decilePerformance: '十分位表現',
            optiPerformance: '優化表現',
            combinations: '組合',
            topComb: '最優組合',
            estOpenUplift: '預計打開提升',
            estClickUplift: '預計點擊提升',
            estConvUplift: '預計轉化提升',
            estValueUplift: '預計價值提升',
            listReport: '列表報告',
            setup: '設置',
            addSub: '添加訂閱者',
            import: '導入',
            export: '導出',
            total: '總計',
            subsBySource: '可聯係訂閱者 - 添加源',
            acqType: '添加類型',
            topSources: '常見途徑',
            campaign: '系列活動',
            allCampaigns: '所有系列活動',
            allImports: '所有導入',
            acqSourcePerformance: '添加源表現',
            sources: '添加源',
            source: '添加源',
            webActReport: '網頁活動報告',
            viewWebpage: '瀏覽網頁',
            extractViewers: '提取瀏覽者',
            viewAd: '查看廣告',
            generateExtract: '生成提取',
        },
        activitySetup: {
            activitySetup: '活動設置',
            details: '詳情',
            sms: '短訊',
            distributionChannel: '傳播方式',
            web: '網頁',
            ad: '廣告',
            line: 'LINE訊息',
            displayAd: '展示廣告類型',
            scheduleAutomation: '計劃與自動',
            distributionType: '傳播類型',
            broadcastTrigger: '廣播API觸發',
            scheduledTrigger: '計劃觸發',
            eventTrigger: '事件觸發',
            scheduleDateTime: '計劃日期/時間',
            startDateTime: '開始日期/時間',
            interval: '觸發間隔',
            hourly: '每小時',
            daily: '每天',
            weekly: '每週',
            monthly: '每月',
            triggerEvent: '觸發事件',
            sentActivity: '被發送了一個活動...',
            openActivity: '打開了一個活動...',
            clickActivity: '點擊了一個活動...',
            submitForm: '提交了一個表格...',
            subscribeList: '訂閱了一個列表...',
            purchased: '購買了...',
            abandoned: '放棄了',
            viewPage: '訪問了一個網頁...',
            purchase: '購買',
            visit: '訪問',
            conditions: '條件',
            addCriterion: '添加條件',
            activities: '活動',
            lists: '列表',
            search: '搜索...',
            delay: '事件與發送之間的間隔',
            fraquency: '訂閱者發送頻率',
            notLimited: '沒有限制',
            sendOnce: '發送一次',
            sendEvery: '每...發送一次',
            sendLimit: '發送限制',
            sendLimitContent:
                '設置了這個數值，如果發送目標多於設置的收件人數量，會導致廣播或觸發在沒有信息告知的情況下停止。這個數值通常被用來防止目標錯誤或數據問題。',
            notes: '筆記',
            target: '目標',
            targetValidationError: '您的目標表達式裡沒有‘訂閱列表’。',
        },
        activityCheck: {
            failedCheckImages: '檢查圖片失敗',
            checked: '檢查了 ',
            // 加了一個單位 ‘個’
            imagesValid: ' 個圖片; 全部合格',
            imagesFailed: ' 個圖片失敗',
            failedCheckLinks: '未能成功檢查鏈接',
            linksValid: ' 個鏈接；全部合格',
            linksFailed: ' 個鏈接失敗',
            failedCheckSubjectline: '未能成功檢查主題行',
            found: '發現',
            subjectlines: ' 個主題行',
            subjectFailed: ' 個主題行失敗',
            failedCheckContent: '未能成功檢查郵件中的無效內容。請手動檢查郵件大小和格式。',
            contentError: '該郵件中含有（可能是從微軟產品中拷貝的）XML內容，會導致郵件過大或格式錯誤。',
            passed: '通過',
        },
        te: {
            leastOnce: '至少一次',
            leastNs: '至少n次',
            nTimes: '恰好n次',
            oneToNTimes: '1到n次',
            selectField: '選擇...',
            anywhere: '任何地方',
            inActivities: '在活動中',
            starting: '起始於',
            matchFollowing: '滿足以下所有條件：',
            anyTime: '任何時間',
            hours: '小時',
            nHoursAgo: 'n小時前',
            inLastNHour: '過去n小時內',
            inNextNHour: '將來n小時內',
            days: '天',
            yesterday: '昨天',
            today: '今天',
            tomorrow: '明天',
            nDaysAgo: 'n天以前',
            inLastNDays: '過去n天內',
            inNextNDays: '將來n天內',
            weeks: '禮拜',
            lastWeek: '上禮拜',
            thisWeek: '這禮拜',
            nextWeek: '下禮拜',
            nWeekAgo: 'n禮拜以前',
            inLastNWeeks: '過去n天內',
            inNextNWeeks: '將來n天內',
            months: '月',
            lastMonth: '上個月',
            thisMonth: '這個月',
            nextMonth: '下個月',
            nMonthAgo: 'n個月前',
            inLastNMonths: '過去n月內',
            inNextNMonths: '將來n月內',
            years: '年',
            lastYear: '去年',
            thisYear: '今年',
            nextYear: '明年',
            nYearAgo: 'N年前',
            inLastNYears: '過去N年內',
            inNextNYears: '將來N年內',
            staticRange: '靜態範圍',
            fromTo: '從...至...',
            anything: '任意事情',
            activities: '活動',
            campaigns: '係列活動',
            allSubs: '所有訂閱者',
            random: '任意N個訂閱者',
            highestValue: '最高N值订阅者',
            lowestValue: '最低N值订阅者',
            highestFutureValue: '將來最高N值订阅者',
            lowestFutureValue: '將來最低N值订阅者',
            mostEngaged: '最多互動的N个订阅者',
            leastEngaged: '最少互動的N个订阅者',
            mostRecentlyEngaged: '最近互動的N个订阅者',
            mostRecentlyPurchased: '最近購買的N个订阅者',
            // All subscribers who ...
            who: '那些',
            select: '選擇...',
            like: '類似（大小寫敏感）',
            notLike: '不類似（大小寫敏感）',
            ilike: '類似',
            notIlike: '不類似',
            oneOf: '之一',
            notOneOf: '非之一',
            is: '是',
            isNot: '不是',
            logicalOp: '逻辑运算',
            doNotMatch: '不满足',
            matchAllOf: '满足所有',
            matchAnyOf: '满足任意',
            subQueries: '訂閱者查詢',
            subToList: '訂閱了任意列表',
            unsubFromList: '取消訂閱了任意列表',
            subProfileQueries: '訂閱者資料查詢',
            birthday: '生日在',
            profilesMatching: '資料符合',
            matchAnyView: '滿足任意視圖',
            contactable: '是可聯係的',
            clickQueries: '點擊歷史查詢',
            clickedURL: '點擊鏈接',
            purchaseQueries: '購買歷史查詢',
            purchases: '購買了',
            trackQueries: '追蹤歷史查詢',
            visited: '瀏覽了網頁',
            logExternalEvent: '有過以下事件',
            interactionQueries: '交互曆史查詢',
            sent: '被發送了',
            opened: '打開了',
            clicked: '點擊了',
            subVia: '訂閱了，通過',
            unsubVia: '取消訂閱了，通過',
            updatedVia: '更新了他們的資料，通過',
            repliedTo: '回復了',
            viewed: '瀏覽了',
            entered: '輸入了表格，通過',
            converted: '轉化了，通過',
            bounced: '退回了',
            liked: '喜歡了',
            abandoned: '放棄了購物車，通過',
            open: '打開',
            view: '瀏覽',
            abandon: '放棄',
            submit: '提交',
            update: '更新',
            enter: '輸入',
            addCriterion: '添加條件',
            withParameter: '參數',
            profileFields: '資料欄',
            customFields: '客製資料欄',
            partition: '屬於區',
        },
        subscriber: {
            address: '地址',
            address2: '地址欄2',
            address3: '地址欄3',
            cluster_id: '標籤ID',
            country: '國家',
            dob: '出生日期',
            email: '電郵',
            extra: '附加信息',
            firstname: '名',
            gender: '性別',
            is_invalid: '無效標記',
            is_unsubscribed: '全局退訂標記',
            lastname: '姓',
            notifications: '通知',
            phone: '電話',
            postcode: '郵編',
            ref: '外部ID',
            resume_date: '恢復日期',
            state: '州/省',
            suburb: '區',
            title: '稱謂',
            is_list_unsubscribed: '退訂標記',
            list_option: '訂閱選項',
            subProfile: '訂閱者資料',
            uniqueKey: '唯一關鍵值：',
            invalid: '無效',
            unsub: '全局退訂',
            profile: '資料',
            field: '信息欄',
            value: '值',
            custom: '自定義',
            listMember: '會員列表',
            addSub: '添加訂閱者至列表',
            noList: '無法找到匹配的列表',
            option: '選項：',
            source: '源：',
            interactionHistory: '交互歷史',
            view: '查看',
            bounceReason: '彈回原因：',
            requestContent: '請求內容：',
            data: '數據：',
            loadedAdditional: '已加載額外',
            items: '項目',
            resetInvalid: '重置無效狀態',
            markInvalid: '標記為無效',
            resetUnsub: '重置全局退訂狀態',
            markUnsub: '標記為全局退訂',
            all: '全部',
            bounced: '彈回了',
            clicked: '點擊了',
            entered: '輸入了',
            opened: '打開了',
            converted: '轉化了',
            replied: '回復了',
            sent: '發送了',
            unsubscribed: '退訂了',
            viewed: '查看了',
            abandoned: '放棄了',
            subscribed: '訂閱了',
            updated: '更新了',
            analytics: '分析了',
            saveChangesToField: '保存修改至',
            the: '',
            fieldCannotUpdate: '不能為已有用戶更新',
            enterValue: '輸入值至',
            // 這裡更改了語序，原文為remove field from subscriber
            remove: '從訂閱用戶移除信息欄',
            removeCustom: '從訂閱用戶移除自定義信息欄',
            fromSub: '',
            editCustom: '編輯自定義信息欄',
            addCustom: '添加自定義信息欄',
            enterList: '輸入列表名或ID以搜索',
            clickToAdd: '點擊此，添加訂閱者至該列表',
            subTo: '訂閱',
            unsubTo: '取消訂閱',
            sub: '訂閱',
            unsubscribe: '取消訂閱',
            newCustom: '新自定義信息欄...',
            listFields: '列表信息欄',
            enterCustom: '輸入自定義信息欄名稱',
            updateSubSuccessfully: '成功更新了用戶資料。',
            editProfile: '編輯資料',
            addStandard: '添加標準信息欄',
            selectField: '選擇信息欄...',
            selectCustomField: '選擇客製信息欄...',
            showHide: '顯示/隱藏',
            clickToCopy: '點擊復製',
            apiUpdated: '更新',
            apiCreated: '创建',
        },
        testProfile: {
            title: '測試用戶',
            usage: '您的測試用戶將被用於生成活動編輯頁面的預覽以及生成報告中的活動預覽。',
            changes: '改變這些設置',
            donot: '不會',
            affect: '影響該企業中的其他用戶。',
            fname: '名',
            lname: '姓',
            email: '郵箱',
            mobile: '手機號碼',
        },
        access: {
            access: '訪問權限',
            inOrg: '在這個企業中，您',
            privileges: '權限',
            readMore: '閱讀更多關於用戶請點擊',
            here: '這裡',
            changeRole: '。如果您需要變更您的用戶角色，請聯繫您企業的管理員。如果有更多需要，請聯繫',
            support: '支持',
            admin: '管理員',
            user: '用戶',
            baseUser: '基礎用戶',
            powerUser: '權力用戶',
            baseWith: '是以為基礎用戶，並有',
            have: `有`,
        },
        template: {
            template: '模版',
            available: '這些模版可以在該企業的活動中使用。',
        },
        tags: {
            tags: '標籤',
            usage: '如果活動被合理地標記了，一段時間後您企業的訂閱用戶會根據互動被自動歸入這些標籤中。',
            addNew: '添加新標籤',
            contactable: '可聯係的訂閱用戶',
            predEngagement: '預測的互動',
            predConversion: '預測的轉化',
            predValue: '預測的價值',
            tagSetup: '設置標籤',
            newTag: '新標籤',
        },
        advanced: {
            advanced: '高級設置',
            usage: '這些設置可能會更改您的Taguchi用戶界面，激活未放出的功能，或造成問題。請謹慎使用，或在Taguchi技術支持的指導下使用。',
            debug: '啟用用戶界面調試模式',
            interface: '啟用測試用戶界面功能',
            keys: '測試功能關鍵詞',
        },
        security: {
            security: '安全',
            usage: '以下的設置會被用來增強您企業的訂閱用戶數據庫的安全保護。',
            twoFA: '所有用戶必須開啟雙重認證。',
            twoApproval: '提取數據必須有兩人批准。',
            adminIP: '管理員IP地址白名單（一行一個）',
            noPrivilege: '您沒有更改該企業安全設置的權限。',
            error: '發生意外錯誤。請聯繫Taguchi技術支持。',
        },
        // not finished yet
        credentials: {
            credentials: '憑證',
            usage: '憑證被用來和其他第三方服務，如臉書，谷歌廣告或自定義SFTP集成。',
            newCred: '添加新的憑證',
            usedBy: '使用於',
            createdDate: '創建日期',
            modifiedDate: '修改日期',
            lastAccessed: '上次訪問日期',
            credentialSetup: '憑證設置',
            newCredential: '新憑證',
            credentialType: '憑證類型',
            serverHost: '服務器地址',
            port: '端口',
            accountName: '賬戶用戶名',
            accountPW: '賬戶密碼',
            accountSID: '賬戶SID',
            authObtainedFor: '已獲得憑證',
            requestFromFacebook: '請求臉書的授權。',
            fromGoogleAds: '來自谷歌廣告的',
            clickHere: '點擊此處',
            requestAuthorization: '授權請求',
            saveCredentials: '您必須先保存憑證以獲得授權。',
            zipFilePW: 'ZIP文件密碼(AES)',
            authToken: '授權令牌',
            lineSecret: 'LINE密匙 | secret',
            authenticationToken: '授權令牌',
            selectType: '請選擇一個合適的憑證類型',
            apiPublicKey: 'PEM格式公共密匙',
            apiSecretKey: '密匙',
            apiToken: 'API令牌 | API Token',
            awsKeyId: '訪問鍵ID | Access key ID',
            awsSecret: '秘密訪問鍵 | Secret access key',
            azureKeyType: '| Key Type',
            azureSecret: '秘密訪問鍵 | Secret access key',
            gpgEncryption: 'GPG加密公钥 | GPG Encryption Public Key',
        },
        domains: {
            title: '追蹤域名',
            usage: '追蹤域名可被用來追蹤郵件中的打開，點擊行為，SMS，網站的點擊行為以及Taguchi網頁空間的點擊行為追蹤。',
            addDomain: '添加追蹤域名',
            dnsRecord: 'DNS記錄',
            status: '狀態',
            sendingDomains: '發送域名',
            sendDomainUsage: '發送域名可被用於Taguchi服務器郵件發送中的‘From Address’。',
            addSendDomain: '添加發送域名',
            domain: '域名',
            dkimSelector: 'DKIM選擇',
            returnPath: '返回路徑',
            dnsRecords: 'DNS記錄',
            returnPathDomain: '返回路徑域名：',
            downloadTrackingDomain: '下載追蹤域名',
            downloadSendingDomain: '下載發送域名',
            sendingDomainSetup: '發送域名設置',
            trackingDomainSetup: '追蹤域名設置',
        },
        import: {
            title: '導入規則',
            usage: '設置導入訂閱用戶或創建信息時需要展示的特殊詞彙的規則。',
            rulesJSON: '規則（格式JSON）',
            head: '信息及手動列表導入規則',
            saveMessageRules: '保存信息規則',
            saveImportRules: '保存導入規則',
        },

        integration: {
            newIntegration: '新集成',
            activate: '激活',
            deactivate: '停止',
            all: '所有集成',
            active: '激活的集成',
            inactive: '停止的集成',
            idAsc: 'ID: 升序',
            idDesc: 'ID: 降序',
            category: '類別',
            selectType: '選擇集成類型...',
            schedule: '安排開始日期/時間',
            repeats: '重複',
            inboundWebhookURL: '入站網鉤URL | Inbound Webhook URL',
            configuration: '設置',
            extractTarget: '提取目標',
            dataMapping: '',
            saveChanges: '保存更改',
            credentialToUse: '使用的憑證',
            intSetup: '集成設置',
            endpointURL: '端點URL',
            testEndpointURL: '測試端點URL',
            productionEndpointURL: '生產端點URL',
            exportDataMapping: '為提取部署數據',
            exportStaticData: '提取的數據',
        },
        theme: {
            title: '主題',
            usage: '這些項目決定了該企業頭部區域的外觀，以及該企業在企業總覽中卡片的外觀。',
            orgLogo: '企業圖標URI',
            headerBkgColor: '頭部背景色',
            noPrivilege: '您沒有更改該企業主題設置的權限。',
        },
        themes: {
            title: '主題',
            usage: '為活動創建並自定義模板主題',
        },
        users: {
            title: '用戶',
            usage: '這些用戶有这些用户有权限访问该企業。',
            addNew: '添加新用户',
            roles: '角色',
            validFrom: '有效期开始日',
            lastLogin: '上次登陆',
            editRole: '修改用户角色',
            revoke: '撤回权限',
            revokeAccess: '撤回用户对企業的权限',
            idProviders: '身份提供者',
            idProviderUsage: '这些身份提供者有管理用户访问权的权限。',
            addNewProvider: '添加新的身份提供者',
            desc: '描述',
            exclForDomain: '仅对此域名有效',
            grantConsent: '通过同意',
            disableProvider: '取消提供者',
            noPrivilege: '您没有权限更改该企業的访问权限。',
            downloadUser: '下載用戶',
            userSetup: '用戶設置',
            userType: '用戶類型',
            franchisee: '加盟商',
            baseUser: '基礎用戶',
            powerUser: '權利用戶',
            admin: '係統管理員',
            contentRoles: '內容用戶',
            contentContent: '內容用戶包括係列活動，活動和圖片集',
            editContent: '允許用戶創建，編輯和刪除係列活動，活動和圖片集',
            edit: '編輯',
            approveContent: '允許用戶批准活動',
            approve: '批准',
            deployContent: '允許用戶部署活動',
            deploy: '部署',
            dbRoles: '數據庫用戶',
            dbRolesContent: '數據庫用戶包括對訂閱用戶數據的特權。但不包括活動或係列活動報告。',
            viewContent: '允許查看訂閱用戶的資料',
            view: '查看',
            manageContent: '允許用戶管理訂閱用戶，並創建，編輯和刪除用戶列表',
            manage: '管理',
            extractContent: '允許用戶提取訂閱用戶資料',
            extract: '提取',
            integrationRoles: '整合用戶',
            intRolesContent: '集成用戶可以管理集成',
            customRoles: '自定義用戶',
            adminHave: '擁有所有權利，並需要打開2FA以管理用戶',
            puHave: '擁有所有的內容和數據庫權利，但沒有設置和用戶管理的權利。',
            buHave: '可以是客製內容用戶和數據庫用戶',
            admins: '管理員',
            powerUsers: '權利用戶',
            baseUsers: '基礎用戶',
            newUser: '新用戶',
        },
        buttons: {
            save: '保存',
            cancel: '取消',
            reset: '重置',
            delete: '刪除',
            approve: '批准',
            deploy: '部署',
            suspend: '中止',
            resume: '恢復',
            saveEdit: '保存並編輯',
            close: '關閉',
            anonymize: '匿名',
            add: '添加',
            remove: '去除',
            proceed: '繼續',
        },
        mainHeader: {
            contact: '聯繫',
            support: '支援',
            supportPortal: '支持入口',
            supportDoc: '支持文檔',
            userGuide: '用戶指南',
            smsGuide: 'SMS用戶指南(PDF)',
            knowledgeBase: '知識庫',
            webFormsDoc: '網頁表格文檔',
            apiDoc: 'API文檔',
            sourceCode: '源代碼',
        },
        store: {
            subEngagement: '订阅者管理',
        },
        fields: {
            clear: '清除',
            selectField: '選擇信息欄...',
        },
        notifications: {
            fromMe: '來自我的',
            allOrg: '所有公司',
            proofs: '校驗',
            extracts: '提取',
            checks: '檢查',
            approvals: '通過',
            sends: '發送',
        },
        customFields: {
            addNew: '添加新信息欄',
            usage: '管理企業客製信息欄',
            customFields: '客製信息欄',
            type: '信息欄類別',
            calculationType: '計算類型',
            calculationTypes: {
                static: '靜態客製信息欄',
                calculated: '計算客製信息欄',
            },
            field: '信息欄名稱',
            groupName: '群組名稱',
            targetExpression: '目標表達式',
            rule: '規則',
            clear: '清除不符合目標表達式的訂閱用戶的信息欄內容。',
            update: '更新滿足目標表達式的訂閱用戶的信息欄內容。',
            frequency: '頻率',
            types: {
                subscriber: '訂閱者',
                purchase: '購買',
                product: '產品',
                purchaseProduct: '購買線項目',
                visit: '查看',
                subscriberEvent: '訂閱者事件',
                page: '頁',
                partition: '區',
                subscriberList: '訂閱列表',
            },
            customFieldSetup: '客製信息欄設置',
            newCustomField: '信息欄',
            description: '描述',
            schema: '架構',
            downloadCustomFields: '下載客製信息欄',
            uploadCustomFields: '上傳客製信息欄',
            startDateTime: '開始日期 時間',
            interval: '觸發間隔',
            once: '一次',
            hourly: '每小時',
            daily: '每天',
            weekly: '每週',
            monthly: '每月',
            regex: '驗證正則表達式',
            statistics: {
                customFieldStatistics: '客製信息欄數據',
                subWithValue: '有數據的訂閱用戶數量:',
                subWithInvalidValue: '信息欄數據無效的訂閱用戶數量:',
                uniqueValue: '特殊值數量:',
                uniqueInvalidValue: '特殊無效值數量:',
                commonValidValue: '最常見有效值:',
                commonInvalidValue: '最常見無效值:',
                lastUpdate: '數據最後更新於:',
            },
        },
        partitions: {
            addNew: '添加分區',
            usage: '管理企業分區',
            partitions: '分區',
            customFields: '客製信息欄',
            clearCustomField: '清除',
            ref: '外部ID',
            partitionSetup: '分區設置',
            newPartition: '新分區',
            downloadPartitions: '下載分區',
            uploadPartitions: '上傳分區',
            partitionSelector: '分區選擇器',
        },
        featureGmailPromo: {
            dealAnnotation: 'Deal Annotation',
            dealAnnotationContent: 'Add Deal Annotations to your emails to help your recipients take action.',
        },
        select: '選擇...',
        // Side nav
        organization: '企業',
        dashboard: '主界面',
        campaigns: '系列活動',
        campaign: '系列活動',
        assets: '圖片集',
        subscribers: '訂閱用戶',
        settings: '設置',
        franchisees: '加盟商',
        // Dashboard
        newActivity: '新的活動',
        // Placeholders
        searchPlaceholder: '搜索',
        enterToSearchPlaceholder: '點擊回車以應用過濾條件',
    },
    'zh-CN': {
        // Timestamp or related
        timestamps: {
            defaultTimestamp: 'YYYY-MM-DD h:mm a',
            shortTimestamp: 'YYYY年MM月DD日',
            shortDate: '%Y-%m-%d',
            shortDateTime: '%Y-%m-%d, %H:%M',
            month: '%m月',
            asAt: '查询日 ',
            weekOf: '查询周 ',
            to: ' 至 ',
            from: '从',
        },
        // Global
        contactSupport: '联系客服',
        // ActionBar
        actionBar: {
            sortBy: '排序',
            filterBy: '筛选',
            recentActivities: '近期活动',
            recentlyDeployed: '近期部署',
            recentlyModified: '近期修改',
            recentlyArchived: '近期归档',
            newest: '最新',
            moreFilters: '更多筛选',
            idAsc: 'ID: 升序',
            idDesc: 'ID: 降序',
            nameAsc: '活动名: A-Z',
            nameDesc: '活动名: Z-A',
            oldest: '最旧',
            name: '名称',
            target: '目标',
            archive: '归档',
            unarchive: '取消归档',
            publish: '发布',
            unpublish: '取消发布',
            clone: '克隆',
            delete: '删除',
            filter: '筛选: ',
            allLists: '所有列表',
            dateRange: '查询周内活跃列表',
            active: '活跃列表',
            dormant: '休眠列表',
            empty: '空列表',
            proof: '校样列表',
            approval: '批准列表',
            notification: '通知列表',
            archived: '归档列表',
            subList: '订阅用户列表',
            testList: '测试列表',
            updateTarget: '更新目标',
            updatetarget: '更新目标',
            priority: '重要度',
            pubDate: '发布日期',
            publishedDate: '发布日期',
            expDate: '过期日期',
            tagSubs: '目标订阅者',
            tagContactableSubs: '目标可联系订阅者',
            numberOfAssets: '图集数：',
            externalID: '外部ID',
            tag: '标签',
            estOpenRate: '预计打开比例',
            sms: 'SMS',
            whatsapp: 'WhatsApp',
            displayad: '展示广告',
            email: '邮件',
            line: 'Line',
            web: '网页',
            type: '类型',
        },
        // Card Face
        cards: {
            setupAndTarget: '设置与目标',
            viewResult: '查看结果',
            activityOptions: '活动选项',
            manage: '管理',
            edit: '编辑',
            viewErrors: '查看错误',
            reports: '报告',
            viewResults: '查看结果',
            viewOnline: '在线查看',
            extract: '提取',
            viewers: '查看人',
            recipients: '收件人',
            content: '内容',
            viewWebpage: '查看页面',
            downloadQR: '下载二维码',
            download: '下载',
            cardNotViewable: '此项目不可见',
            extractRecipientsViewers: '提取收件人/查看人',
            extractrvsmall: '提取收件人/查看人',
            extractDownloadReciViewers: '提取并下载收件人/查看人',
            setup: '设置',
            export: '导出',
            add: '添加',
            bulkAdd: '批量添加',
            partitionSelector: '分区选择器',
        },
        // Lists
        lists: {
            date: '日期',
            results: '结果',
            numberOfActivities: '活动的数量：',
            noActivities: '无活动符合筛选条件。',
            allLoaded: '已全部加载',
            loadMore: '加载更多',
            noList: '无符合条件的列表。',
            type: '类型',
            public: '公共',
            proof: '校对',
            approval: '批准',
            notification: '通知',
            listSetup: '列表设置',
            templateTotal: '模版总数：',
            tagsTotal: '标签总数：',
            credentialsTotal: '凭证总数：',
            userTotal: '用户总数：',
            providerTotal: '身份提供者总数：',
            uploadSubscribers: '上传订阅者至列表',
            fieldsTotal: '自定义信息栏数量：',
            partitionsTotal: '分区数量：',
            details: '详情',
            notes: '笔记',
        },
        // Charts
        charts: {
            engagementChart: {
                title: '用户互动',
            },
            contactable: '可联系',
            contacted: '已联系',
            engaged: '已互动',
            converted: '已转换',
            conversionValue: '转换金额',
            lastweek: '上周',
            current: '当前',
            campaignChart: {
                title: '系列活动表现',
            },
            sent: '已发送',
            uniqueOpens: '不重复打开',
            uniqueClicks: '不重复点击',
            valueSent: '价值/已发送',
            valueViewed: '价值/已查看',
            listGrowth: '列表增长',
            predictedEngagement: '预期互动',
            predictedConversion: '预期转换值',
            predictedTotalValue: '预期总价值',
            added: '新增',
            removed: '移除',
            bounced: '反弹',
            bounceRate: '反弹率',
            totalValue: '总价值',
            unsubscribed: '退订',
            unsubscribeRate: '退订率',
            totalOpens: '总打开',
            totalClicks: '总点击',
            avgValue: '平均价值',
            clickToOpen: '点击对打开',
            convToClick: '转化对点击',
            valuePerClick: '价值/点击',
            replied: '已恢复',
            invalid: '无效',
            value: '价值',
            avgValue2: '平均价值',
            predAvgValue: '预期平均价值',
            engaged2: '已互动',
            converted2: '已转化',
            value2: '价值',
            predValue: '预期价值',
            avgValue3: '平均价值',
            predAvgValue2: '预期平均价值',
            uniqueViews: '不重复阅览',
            totalViews: '总阅览',
            submitted: '已提交',
            clickToView: '点击对阅览',
        },
        // Schedule
        schedule: {
            schedule: '计划进行',
            noScheduled: '无计划中的活动',
            fetching: '正在获取计划中的活动...',
            fetchScheduledError: '获取计划活动时发生错误',
        },
        assetPage: {
            new: '新图片',
            allAssets: '所有图片',
            published: '发布的图片',
            archived: '归档的图片',
            nameAsc: '名称: A-Z',
            nameDesc: '名称: Z-A',
            assetSetup: '图片设置',
            selectTemp: '选择模板',
            preview: '预览',
            deviceType: '设备类型：',
            desktop: '电脑',
            mobile: '移动',
            metadata: '元数据',
            publishAsset: '发布图片?',
            statistics: '数据',
            lastUpdated: '最后更新',
            noAssetsAvaible: '没有图片符合该搜索条件',
            numberOfAssets: '图片数',
            downloadAssets: '下载图片',
            uploadAssets: '上传图片',
            manageDominosAssets: '管理达美乐的图片',
        },
        // Settings nav headings
        settingsNav: {
            testProfile: '测试用户',
            access: '访问',
            security: '安全',
            credentials: '凭证',
            integrations: '集成',
            templateTheming: '品牌',
            organization: '企业',
            templates: '模版',
            tags: '标签',
            users: '用户',
            fileImport: '文件导入',
            domains: '域名',
            advanced: '高级',
            customFields: '自定义信息栏',
            partitions: '分区',
            rules: '規則',
        },
        // Login page
        login: {
            emailAddress: '电子邮箱',
            password: '密码',
            rememberEmail: '记住我的邮箱',
            continue: '继续',
            forgotPassword: '忘记密码？',
            createAccount: '创建一个新账户',
            otpRecoveryCode: 'OTP或恢复码',
            submit2fa: '提交',
            passwordWeak: '密码太简单',
            newPassword: '新密码',
            confirmPassword: '确认密码',
            passwordResetAlert: '您的密码已重置。请输入新密码',
            passwordsDontMatch: '密码不一致',
            changePasswordAndLogin: '修改密码并登录',
            termsOfUse: '网站使用条款',
            privacyPolicy: '隐私政策',
            passwordWasReset: '您的密码已重置。请查看您的邮箱并根据说明操作。',
            returnToLogin: '回到登录页面',
            emailInvalid: '无效邮箱',
            resetPassword: '重置密码',
        },
        // User account
        account: {
            detailsCategory: '账户信息',
            signedInAs: '登录身份',
            details: '账户信息',
            logout: '登出',
            you: '你',
            email: '邮箱',
            phone: '电话',
            language: 'Language',
            otpActivationDisabled: '2FA已停用。点击启用',
            otpActivationEnabled: '2FA已启用。点击停用。',
            otpActivationInProgress: '2FA启动中...',
            ssoStatus: '单一登入(SSO)状态',
            ssoProvider: '您的账户由已授权的单一登入提供商管理。',
            twoFaHeading: '双重认证(2FA)',
            twoFaEnable: '启动双重认证',
            twoFaDescription: '需要有合规的移动程序，如Google Authenticator, 可前往手机应用商店下载',
            twoFaReadMore: '阅读更多',
            scanQrCode: '请扫描下方二维码',
            enterOtpCode: '请在下方输入程序中的验证码。验证码确定正确后，双向验证才会启动。',
            otpCodeIncorrect: '验证码不正确，请重试',
            twoFaNowEnabled: '双向验证已启用，请在下方下载您的恢复码。',
            twoFaActivationError: '启动双向验证时发生错误。',
            twoFaVerificationError: '验证双向验证码时发生错误。',
            otpVerify: '验证',
            otpVerificationCode: '验证码',
            downloadRecoveryCodes: '下载恢复码',
            downloadRecoveryCodesDescription: '恢复码可以在您丢失手机，无法收到双向验证码时使用访问您的账户。',
            downloadRecoveryCodesOnlyTime: '此恢复码仅显示一次。请下载并妥善保存。',
            downloadRecoveryCodesButton: '下载恢复码',
            downloadRecoveryCodesWarning: '请下载恢复码，并妥善保存。',
        },
        campaignPage: {
            newCampaign: '新系列活动',
            startDate: '开始日期',
            endDate: '结束日期（可选）',
            campaignTotal: '系列活动总数：',
            activityTotal: '活动总数：',
            allCampaigns: '所有系列活动',
            dateRange: '查询日期内的系列活动',
            active: '活跃的系列活动',
            upcoming: '未开始的系列活动',
            completed: '完成的系列活动',
            archived: '归档的系列活动',
            campaignSetup: '系列活动设置',
            details: '详情',
            notes: '笔记',
        },
        subscriberPage: {
            newList: '新列表',
            importSubscribers: '导入订阅用户',
            supported: '支持CSV, TSV, XLS & XLSX',
            name: '名称',
            dateAdded: '添加日期',
            contactable: '可联系的',
            predEngagement: '预期互动',
            predConversion: '预期转换',
            predTotalValue: '预期总价值',
            predAvgValue: '预期均价',
            listTotal: '列表总数：',
        },
        pagehead: {
            setup: '设置',
            setupComment: '编辑设置',
            downloadpdf: '下载pdf',
            downloadcsv: '下载csv',
        },
        activityEditor: {
            activityEditor: '编辑活动',
            setupTarget: '设置和目标',
            editActDetails: '编辑活动细节',
            viewWebpage: '查看网页',
            viewReport: '查看报告',
            extract: '提取 ',
            web: '网页',
            recipients: '收件人',
            viewers: '浏览者',
            generateExtract: '生成报告',
            downloadContent: '下载内容',
            downloadContentContent: '下载活动内容',
            viewErrors: '查看错误',
            viewErrorContent: '查看活动错误',
            errorOccurred: '发生了以下错误：',
            idInvalid: '活动ID无效',
            unableFetch: '无法获取活动',
            needHelp: '需要更多帮助？',
            contactSupport: '联系我们的技术支持团队。',
            unsavedChanges: '上次保存的更改后有新的更改。未保存的更改不会出现在校对信息或批准请求中。',
            runCheck: '运行检查',
            runTestMatrix: '运行测试检查',
            runCheckContent: '运行活动检查',
            uploadTest: '上传测试数据',
            uploadTestContent: '测试特定用户以检查他们是否在目标表达式里，并查看他们的校验邮件。',
            replaceTestContent: '替换这个活动的测试数据',
            downloadTest: '下载测试数据',
            downloadTestContent: '下载这个活动的测试数据',
            approvedSetting: '活动将被通过，包括以下设置：',
            activityApproved: '活动已被批准部署。点击这里重置批准状态。',
            approveActivity: '批准活动已部署',
            confirm: '确认',
            deployment: '部署',
            deployedSetting: '活动将被部署，包括以下设置：',
            confirmDeploy: '确认修改的部署 ',
            deploy: '部署',
            suspend: '停止',
            resumeSetting: '活动将被回复，包括以下设置：',
            upTo: '最多',
            livePreview: '实时预览',
            revert: '还原',
            combination: '组合',
            desktop: '电脑端HTML',
            mobile: '手机端HTML',
            plain: '文本',
            raw: '原始MIME',
            debug: '调试信息',
            noOverlay: '无重叠',
            heatmap: '热度图',
            clickP: '点击图(%)',
            clickH: '点击图(#)',
            downloadArchive: '下载含所有当前测试数据的校验邮件的归档',
            queuedContent: '当前排队中的邮件',
            scheduledContent: '当前计划中的未来部署',
            sendTime: '下一个计划送出的时间',
            launchPage: '在新窗口中打开页面',
            summary: '总结',
            taguchiError:
                'Taguchi在尝试发送活动时发生了错误。错误可能存在在模板代码中，或由错误的个性化标签导致。如果您不确定错误发生的原因，请联系您的模板开发者，或联系Taguchi Support, 并将该页面的链接附在您的查询中。',
            //這裡更改了語序。修改時參考英文原版。
            error: '个错误',
            s: '',
            haveOccurred: '在尝试发送活动修改版本',
            recentError: '时发生了。最近一个错误发生于',
            trackback: '回溯',
            context: '上下文',
            warning: '警告：',
            warningExpressionValidation: '目标表达式里没有订阅列表。',
            wishToProceed: '您想继续吗？',
        },
        workflow: {
            approvedRevision: '批准了修改版本',
            for: '进行',
            toRecipients: '，收件人符合',
            // at1 e.g. 以最多100信息每分鐘
            at1: '以',
            // at2 e.g. 在10:15 每天
            at2: '在',
            upTo: '最多',
            mperm: '信息每分钟',
            forDeployment: '以部署',
            requestApproval: '请求了批准，修改版本ID ',
            finishedBroadcast: '结束了广播，共',
            recipients: '收件人',
            ranCheck: '运行了一个检查，修改版本ID',
            results: '结果',
            links: '链接：',
            images: '图像：',
            subjectLines: '主题行：',
            fromHeader: "'来自 '标头：",
            openTrackImage: '打开追踪图像：',
            broadcastDestination: '广播目标：',
            segment: '分类',
            noSegments: '无已定义的分类',
            totalRecipients: '收件人总数：',
            contains: '包括',
            subscribers: '订阅用户',
            subMatch: '订阅用户符合',
            segments: '分类',
            emailContent: '邮件内容：',
            emailSize: '邮件大小：',
            sentA: '发送了一个',
            proofRevisionID: '校验，修改版本ID',
            to: '至',
            cancelDeployment: '取消了这个活动的部署',
            deployedLatest: '部署了这个活动的最新修改版本',
            scheduled: '安排了',
            hourlyDeployment: '每小时一次的部署，在',
            pastEachHour: '的每小时',
            from: '，从',
            scheduledDeployment: '安排了部署',
            daily: '每天',
            every: '每',
            onThe: '在',
            dayOfMonth: '日的每個月，在',
            scheduledBroadcast: '安排了广播',
            immeBroadcast: '立刻广播',
            deployActivity: '部署了这个活动',
            view: '查看',
            rejectApproval: '拒绝了这个批准请求，修改版本ID ',
            savedRevisionID: '保存了修改版本ID ',
            revertedRevisionID: '（恢复至修改版本ID ',
            resetApprovalStatus: '重设了该活动的批准状态',
            changeTE: '，通过修改目标表达式为',
            changeDate: '，通过修改日期为',
            changeTypeTrigger: '，通过修改发布类型，从计划出发改为广播或API触发',
            setThrottle: '设置阀门为',
            suspendedDeployment: '停止部署',
            commencedBroadcast: '开始广播至',
            addedWorkflowPart: '添加工作流程参与者',
            removedWorkflowPart: '去除工作流程参与者',
            changedSettings: '改变了以下活动设置：',
            TEto: '目标表达式为',
            scheduleFor: '计划了 ',
            distributionType: '发布类型从计划触发改为广播或API触发',
            dateTo: '日期至',
            nameTo: '名字至',
            campaignIDTo: '系列活动ID至',
            sendLimitTo: '发送上限为',
            testMatrix: '测试矩阵（',
            download: '下载',
            savedActivitySettings: '保存了活动设置',
            notes: '笔记',
            deployment: '部署',
            broadcast: '广播',
            immediate: '即时',
            triggered: '触发',
            target: '目标：',
            schedule: '计划：',
            sendUpTo: '至多发送',
            pmin: '每分钟',
            atLeast: '最少',
            min: '分',
            remianing: '剩余',
            revisionID: '修改版本ID',
            participants: '参与者',
            // 1 selected 加了一個單位
            selected: '位已选择',
            unsavedChangesMessage: '存在未保存的更改。发送校验邮件前请先保存更改。',
            sendProof: '发生校验邮件给参与者。',
            failedToAddParticipant: '未能成功添加参与者至活动',
            cannotFindParticipant: '。企业内未能找到该参与者。',
            throttleWarning: '设置上限小于1会导致该活动的部署被中止。',
            maxThrottleReached: '发送速率上限为6万。如果需要更高，请联系Taguchi客服。',
            addChat: '添加聊天参与者',
            cannotAddParticipant: '不能添加参与者',
            mention: '提到',
            addMe: '把我添加为参与者',
            cancelAdd: '取消添加参与者',
            addParticipant: '添加参与者到你的工作流程。他们可以是新的或是已有的用户。',
            failedCheckImages: '检查图片失败',
            checked: '检查了',
            imagesValid: '张图片；全部合格',
            imagesFailed: '张图片失败',
            failedCheckLinks: '检查链接失败',
            linksValid: '个链接；全部合格',
            linksFailed: '个链接失败',
            failedCheckSub: '检查主题行失败',
            found: '检测到',
            subLines: '个主题行',
            subLinesFailed: '个主题行失败',
            failedCheckContent: '检查邮件中错误内容失败。请自行检查邮件大小和/或格式。',
            containsXML: '该邮件中含有微软产品中拷贝来的XML内容，这会导致邮件过大或排版格式错误。',
            passed: '通过',
            failedCheckSize: '检查邮件大小失败',
            tooBigEmailSize: '邮件大于200K。现在大小：',
            bigEmailSize: '邮件大于100K。现在大小：',
            emailSizeOK: '邮件大小OK：',
            failedCheckFrom: '检查邮件中“From”失败',
            noFrom: '"From"未定义',
            failedCheckOpenTrackingImage: '检查打开追踪图片失败',
            imageNotPresent: '打开追踪图片不存在',
            present: '存在',
            failedCheckDestination: '检查广播目标失败',
            noDestination: '未找到广播目标',
            failedCheckRecipients: '检查收件人数失败',
            noRecipients: '未找到收件人',
        },
        report: {
            emailReport: '邮件活动报告',
            smsReport: 'SMS活动报告',
            whatsappReport: 'WhatsApp 活动报告',
            setupAndTarget: '设置与目标',
            edit: '编辑',
            extractRecipients: '提取收件人',
            download: '下载',
            viewErrors: '查看错误',
            content: '内容',
            activeLinks: '活跃链接',
            heatmap: '点击热度图',
            overallPerformance: '总体表现',
            lastDeployed: '。上次部署 ',
            performanceOverTime: '长期表现',
            allTime: '全部时间',
            lastYear: '去年',
            clickThroughPerformance: '点击表现',
            clientsDevices: '客户和设备',
            platform: '平台使用',
            topBrowsers: '常用浏览器',
            overall: '总体',
            desktop: '电脑端',
            mobile: '移动端',
            allMobile: '全部移动端',
            allDesktop: '全部电脑端',
            decilePerformance: '十分位表现',
            optiPerformance: '优化表现',
            combinations: '组合',
            topComb: '最优组合',
            estOpenUplift: '预计打开提升',
            estClickUplift: '预计点击提升',
            estConvUplift: '预计转化提升',
            estValueUplift: '预计价值提升',
            listReport: '列表报告',
            setup: '设置',
            addSub: '添加订阅者',
            import: '导入',
            export: '导出',
            total: '总计',
            subsBySource: '可联系订阅者 - 添加源',
            acqType: '添加类型',
            topSources: '常见途径',
            campaign: '系列活动',
            allCampaigns: '所有系列活动',
            allImports: '所有导入',
            acqSourcePerformance: '添加源表现',
            sources: '添加源',
            source: '添加源',
            webActReport: '网页活动报告',
            viewWebpage: '浏览网页',
            extractViewers: '提取浏览者',
            viewAd: '查看广告',
            generateExtract: '生成提取',
        },
        activitySetup: {
            activitySetup: '活动设置',
            details: '详情',
            sms: '短讯',
            distributionChannel: '传播方式',
            web: '网页',
            ad: '广告',
            line: 'LINE讯息',
            displayAd: '展示广告类型',
            scheduleAutomation: '计划与自动',
            distributionType: '传播类型',
            broadcastTrigger: '广播API触发',
            scheduledTrigger: '计划触发',
            eventTrigger: '事件触发',
            scheduleDateTime: '计划日期/时间',
            startDateTime: '开始日期/时间',
            interval: '触发间隔',
            hourly: '每小时',
            daily: '每天',
            weekly: '每周',
            monthly: '每月',
            triggerEvent: '触发事件',
            sentActivity: '被发送了一个活动...',
            openActivity: '打开了一个活动...',
            clickActivity: '点击了一个活动...',
            submitForm: '提交了一个表格...',
            subscribeList: '订阅了一个列表...',
            purchased: '购买了...',
            abandoned: '放弃了',
            viewPage: '访问了一个网页...',
            purchase: '购买',
            visit: '访问',
            conditions: '条件',
            addCriterion: '添加条件',
            activities: '活动',
            lists: '列表',
            search: '搜索...',
            delay: '事件与发送之间的间隔',
            fraquency: '订阅者发送频率',
            notLimited: '没有限制',
            sendOnce: '发送一次',
            sendEvery: '每...发送一次',
            sendLimit: '发送限制',
            sendLimitContent:
                '设置了这个数值，如果发送目标多于设置的收件人数量，会导致广播或触发在没有信息告知的情況下停止。这个数值通常被用來防止目标错误或数据问题。',
            notes: '笔记',
            target: '目标',
            targetValidationError: '您的目标表达式里没有‘订阅列表’',
        },
        activityCheck: {
            failedCheckImages: '检查图片失败',
            checked: '检查了 ',
            // 添加了一个单位 ‘个’
            imagesValid: ' 个图片；全部合格',
            imagesFailed: ' 个图片失败',
            failedCheckLinks: '未成功检查链接',
            linksValid: ' 链接：全部合格',
            linksFailed: ' 链接失败',
            failedCheckSubjectline: '未能成功检查主题行',
            found: '发现',
            subjectlines: ' 个主题行',
            subjectFailed: ' 个主题行失败',
            failedCheckContent: '未能成功检查邮件中无效内容。请手动检查邮件大小和格式。',
            contentError: '该邮件中含有（可能是从微软产品中拷贝的）XML内容，会导致邮件过大或格式错误。',
            passed: '通过',
        },
        te: {
            leastOnce: '至少一次',
            leastNs: '至少n次',
            nTimes: '恰好n次',
            oneToNTimes: '1到n次',
            selectField: '选择...',
            anywhere: '任何地方',
            inActivities: '在活动中',
            starting: '起始于',
            matchFollowing: '满足以下所有条件：',
            anyTime: '任何时间',
            hours: '小时',
            nHoursAgo: 'n小时前',
            inLastNHour: '过去n小时内',
            inNextNHour: '将来n小事内',
            days: '天',
            yesterday: '昨天',
            today: '今天',
            tomorrow: '明天',
            nDaysAgo: 'n天以前',
            inLastNDays: '过去n天内',
            inNextNDays: '将來n天内',
            weeks: '星期',
            lastWeek: '上星期',
            thisWeek: '这星期',
            nextWeek: '下星期',
            nWeekAgo: 'n星期以前',
            inLastNWeeks: '过去n天内',
            inNextNWeeks: '将来n天内',
            months: '月',
            lastMonth: '上个月',
            thisMonth: '这个月',
            nextMonth: '下个月',
            nMonthAgo: 'n个月前',
            inLastNMonths: '过去n月内',
            inNextNMonths: '过來n月内',
            years: '年',
            lastYear: '去年',
            thisYear: '今年',
            nextYear: '明年',
            nYearAgo: 'N年前',
            inLastNYears: '过去N年内',
            inNextNYears: '将來N年内',
            staticRange: '静态范围',
            fromTo: '从...至...',
            anything: '任意事情',
            activities: '活动',
            campaigns: '系列活动',
            allSubs: '所有订阅者',
            random: '任意N个订阅者',
            highestValue: '最高N值订阅者',
            lowestValue: '最低N值订阅者',
            highestFutureValue: '将来最高N值订阅者',
            lowestFutureValue: '将来最低N值订阅者',
            mostEngaged: '最多互动的N个订阅者',
            leastEngaged: '最少互动的N个订阅者',
            mostRecentlyEngaged: '最近互动的N个订阅者',
            mostRecentlyPurchased: '最近购买的N个订阅者',
            // All subscribers who ...
            who: '那些',
            select: '选择...',
            like: '类似（大小写敏感）',
            notLike: '不类似（大小写敏感）',
            ilike: '类似',
            notIlike: '不类似',
            oneOf: '之一',
            notOneOf: '非之一',
            is: '是',
            isNot: '不是',
            logicalOp: '逻辑运算',
            doNotMatch: '不满足',
            matchAllOf: '满足所有',
            matchAnyOf: '满足任意',
            subQueries: '订阅者查询',
            subToList: '订阅了任意列表',
            unsubFromList: '取消订阅了任意列表',
            subProfileQueries: '订阅者资料查询',
            birthday: '生日在',
            profilesMatching: '资料符合',
            matchAnyView: '满足任意视图',
            contactable: '是可联系的',
            clickQueries: '点击历史查询',
            clickedURL: '点击链接',
            purchaseQueries: '购买历史查询',
            purchases: '购买了',
            trackQueries: '追踪历史查询',
            visited: '浏览了网页',
            logExternalEvent: '有过以下事件',
            interactionQueries: '交互历史查询',
            sent: '被发送了',
            opened: '打开了',
            clicked: '点击了',
            subVia: '订阅了，通过',
            unsubVia: '取消订阅了，通过',
            updatedVia: '更新了他們的资料，通过',
            repliedTo: '回复了',
            viewed: '浏览了',
            entered: '输入了表格，通过',
            converted: '转化了，通过',
            bounced: '退回了',
            liked: '喜欢了',
            abandoned: '放弃了购物车，通过',
            open: '打开',
            view: '浏览',
            abandon: '放弃',
            submit: '提交',
            update: '更新',
            enter: '输入',
            addCriterion: '添加条件',
            withParameter: '参数',
            profileFields: '资料栏',
            customFields: '自定义资料栏',
            partition: '属于分区',
        },
        subscriber: {
            address: '地址',
            address2: '地址栏2',
            address3: '地址栏3',
            cluster_id: '标签ID',
            country: '国家',
            dob: '出生日期',
            email: '电邮',
            extra: '附加信息',
            firstname: '名',
            gender: '性別',
            is_invalid: '无效标记',
            is_unsubscribed: '全局退订标记',
            lastname: '姓',
            notifications: '通知',
            phone: '电话',
            postcode: '邮编',
            ref: '外部ID',
            resume_date: '恢复日期',
            state: '州/省',
            suburb: '区',
            title: '称谓',
            is_list_unsubscribed: '退订标记',
            list_option: '订阅选项',
            subProfile: '订阅者资料',
            uniqueKey: '唯一关键值：',
            invalid: '无效',
            unsub: '全局退订',
            profile: '资料',
            field: '信息栏',
            value: '值',
            custom: '自定义',
            listMember: '会员列表',
            addSub: '添加订阅者至列表',
            noList: '无法找到匹配的列表',
            option: '选项：',
            source: '源：',
            interactionHistory: '交互历史',
            view: '查看',
            bounceReason: '弹回原因：',
            requestContent: '請求內容：',
            data: '数据：',
            loadedAdditional: '已加载额外',
            items: '项目',
            resetInvalid: '重置无效状态',
            markInvalid: '标记为无效',
            resetUnsub: '重置全局退订状态',
            markUnsub: '标记为全局退订',
            all: '全部',
            bounced: '弹回了',
            clicked: '点击了',
            entered: '输入了',
            opened: '打开了',
            converted: '转化了',
            replied: '回复了',
            sent: '发送了',
            unsubscribed: '退订了',
            viewed: '查看了',
            abandoned: '放弃了',
            subscribed: '订阅了',
            updated: '更新了',
            analytics: '分析了',
            saveChangesToField: '保存修改至',
            the: '',
            fieldCannotUpdate: '不能为已有用户更新',
            enterValue: '输入值至',
            // 這裡更改了語序，原文為remove field from subscriber
            remove: '从订阅用户移除信息栏',
            removeCustom: '从订阅用户移除自定义信息栏',
            fromSub: '',
            editCustom: '编辑自定义信息栏',
            addCustom: '添加自定义信息栏',
            enterList: '输入列表名或ID以搜索',
            clickToAdd: '点击此处，添加订阅者至该列表',
            subTo: '订阅',
            unsubTo: '取消订阅',
            sub: '订阅',
            unsubscribe: '取消订阅',
            newCustom: '新自定义信息栏...',
            listFields: '列表信息栏',
            enterCustom: '输入自定义信息栏名称',
            updateSubSuccessfully: '成功更新了用户资料。',
            editProfile: '编辑资料',
            addStandard: '添加标准信息栏',
            selectField: '选择信息栏...',
            selectCustomField: '选择自定义信息栏...',
            showHide: '显示/隐藏',
            clickToCopy: '点击拷贝',
            apiUpdated: '更新',
            apiCreated: '创建',
        },
        // Settings
        testProfile: {
            title: '测试用户',
            usage: '您的测试用户被用来生成活动编辑页面的预览以及生成报告中的活动预览。',
            changes: '对以下设置的改变',
            donot: '不会',
            affect: '影响您企业里的其他用户。',
            fname: '名',
            lname: '姓',
            email: '邮箱',
            mobile: '手机号',
        },
        access: {
            access: '访问',
            inOrg: '在这个企业中，你',
            privileges: '权限',
            readMore: '阅读更过关于用户角色，点击',
            here: '此处',
            changeRole: '。如果您需要更改用户角色，请联系企业管理员。更多帮助请联系',
            support: '支持',
            admin: '管理员',
            user: '用户',
            baseUser: '基础用户',
            powerUser: '权力用户',
            baseWith: '是一个基础用户，并有',
            have: '有',
        },
        template: {
            template: '模版',
            available: '这些模版可以用在该企业的以下活动中。',
        },
        tags: {
            tags: '标签',
            usage: '如果活动被标签恰当，订阅用户会根据互动逐渐被归类进这些标签里。',
            addNew: '添加新标签',
            contactable: '可联系的订阅用户',
            predEngagement: '预测互动',
            predConversion: '预测转化',
            predValue: '预测价值',
            tagSetup: '设置标签',
            newTag: '新标签',
        },
        advanced: {
            advanced: '高级设置',
            usage: '高级设置会改变Taguchi用户界面，启用未发布的功能或导致问题。请谨慎使用，或在Taguchi技术支持的指导下使用。',
            debug: '启动用户界面调试模式',
            interface: '启动测试用户界面功能',
            keys: '测试功能关键词',
        },
        security: {
            security: '安全',
            usage: '以下设置会增强该企业订阅用户数据库的安全。',
            twoFA: '所有用户必须开启双层认证。',
            twoApproval: '提取数据必须有两人批准。',
            adminIP: '管理员IP地址白名单（一行一个）',
            noPrivilege: '您没有修改该企业安全设置的权限。',
            error: '发生意外错误，请联系Taguchi技术支持。',
        },
        // not finished yet
        credentials: {
            credentials: '凭证',
            usage: '凭证被用来和第三方服务，如脸书，谷歌逛逛或自定义SFTP集成。',
            newCred: '添加新凭证',
            usedBy: '使用于',
            createdDate: '创建日期',
            modifiedDate: '修改日期',
            lastAccessed: '上次访问日期',
            credentialSetup: '凭证设置',
            newCredential: '新凭证',
            credentialType: '凭证类型',
            serverHost: '服务器地址',
            port: '端口',
            accountName: '账户用户名',
            accountPW: '账户密码',
            accountSID: '账户SID',
            authObtainedFor: '已获得凭证',
            requestFromFacebook: '请求脸书的授权。',
            fromGoogleAds: '来自谷歌广告的',
            clickHere: '点击此处',
            requestAuthorization: '授权请求',
            saveCredentials: '您必须先保存凭证以获得授权。',
            zipFilePW: 'ZIP文件密码(AES)',
            authToken: '授权令牌',
            lineSecret: 'LINE密匙 | secret',
            authenticationToken: '授权令牌',
            selectType: '请选择一个合适的凭证类型',
            apiPublicKey: 'PEM格式公共密匙',
            apiSecretKey: '密匙',
            apiToken: 'API令牌 | API Token',
            awsKeyId: '访问键ID | Access key ID',
            awsSecret: '秘密访问键 | Secret access key',
            azureKeyType: '| Key Type',
            azureSecret: '秘密访问键 | Secret access key',
            gpgEncryption: 'GPG加密公钥 | GPG Encryption Public Key',
        },
        domains: {
            title: '追踪域名',
            usage: '追踪域名可被用来追踪邮件中的打开，点击行为，SMS，网站的点击行为以及Taguchi网页的点击行为的追踪。',
            addDomain: '添加追踪域名',
            dnsRecord: 'DNS记录',
            status: '状态',
            sendingDomains: '发送域名',
            sendDomainUsage: '发送域名可被用于Taguchi服务器邮件发送中的`From Address`',
            addSendDomain: '添加发送域名',
            domain: '域名',
            dkimSelector: 'DKIM选择',
            returnPath: '返回路径',
            dnsRecords: 'DNS记录',
            returnPathDomain: '返回路径名：',
            downloadTrackingDomain: '下载追踪域名',
            downloadSendingDomain: '下载发送域名',
            sendingDomainSetup: '发送域名设置',
            trackingDomainSetup: '追踪域名设置',
        },
        import: {
            title: '导入规则',
            usage: '设置导入订阅用户或创建信息时需要展示的特殊词汇的规则。',
            rulesJSON: '规则（格式JSON）',
            head: '信息及手动列表导入规则',
            saveMessageRules: '保存信息规则',
            saveImportRules: '保存导入规则',
        },
        integration: {
            newIntegration: '新集成',
            activate: '激活',
            deactivate: '停止',
            all: '所有集成',
            active: '激活的集成',
            inactive: '停止的集成',
            idAsc: 'ID: 升序',
            idDesc: 'ID: 降序',
            category: '类别',
            selectType: '选择集成类型...',
            schedule: '安排开始日期/时间',
            repeats: '重复',
            inboundWebhookURL: '入站网钩URL | Inbound Webhook URL',
            configuration: '设置',
            extractTarget: '提取目标',
            dataMapping: '',
            saveChanges: '保存更改',
            credentialToUse: '使用的凭证',
            intSetup: '集成设置',
            endpointURL: '端点URL',
            testEndpointURL: '测试端点URL',
            productionEndpointURL: '生产端点URL',
            exportDataMapping: '为提取部署数据',
            exportStaticData: '静态数据',
        },
        theme: {
            title: '主题',
            usage: '这些项目决定了该企业头部区域的外观，以及该企业在企业总览中卡片的外观。',
            orgLogo: '企业图标URI',
            headerBkgColor: '头部背景色',
            noPrivilege: '您没有更改该企业主体设置的权限。',
        },
        themes: {
            title: '主题',
            usage: '为活动而创建和自定义模板主题。',
        },
        users: {
            title: '用户',
            usage: '这些用户有权限访问该企业。',
            addNew: '添加新用户',
            roles: '角色',
            validFrom: '有效期开始日',
            lastLogin: '上次登陆',
            editRole: '修改用户角色',
            revoke: '撤回权限',
            revokeAccess: '撤回用户对该企业的权限',
            idProviders: '身份提供者',
            idProviderUsage: '这些身份提供者有管理用户访问权的权限。',
            addNewProvider: '添加新的身份提供者',
            desc: '描述',
            exclForDomain: '仅对此域名有效',
            grantConsent: '通过同意',
            disableProvider: '取消提供者',
            noPrivilege: '您没有权限更改企业的访问权限。',
            downloadUser: '下载用户',
            userSetup: '用户设置',
            userType: '用户类型',
            franchisee: '加盟商',
            baseUser: '基础用户',
            powerUser: '权利用户',
            admin: '系统管理员',
            contentRoles: '内容用户',
            contentContent: '内容用户包括系列活动，活动和图片集',
            editContent: '允许用户创建，编辑和刪除系列活动，活动和图片集',
            edit: '编辑',
            approveContent: '允许用户批准活动',
            approve: '批准',
            deployContent: '允许用户部署活动',
            deploy: '部署',
            dbRoles: '数据库用户',
            dbRolesContent: '数据库用户包括对订阅用户数据的特权。但不包括活动或系列活动报告。',
            viewContent: '允许查看订阅用户的资料',
            view: '查看',
            manageContent: '允许用户管理订阅用户，并创建，编辑和刪除用户列表',
            manage: '管理',
            extractContent: '允许用户提取订阅用户资料',
            extract: '提取',
            integrationRoles: '整合用户',
            intRolesContent: '集成用户可以管理集成',
            customRoles: '自定义用户',
            adminHave: '拥有所有权利，并需要打开2FA以管理用户。',
            puHave: '拥有所有的内容和数据库权利，但没有设置和用户管理的权利。',
            buHave: '可以是自定义内容用户和数据库用户',
            admins: '管理員',
            powerUsers: '权利用户',
            baseUsers: '基础用户',
            newUser: '新用户',
        },
        buttons: {
            save: '保存',
            cancel: '取消',
            reset: '重置',
            delete: '删除',
            approve: '批准',
            deploy: '部署',
            suspend: '中止',
            resume: '恢复',
            saveEdit: '保存并编辑',
            close: '关闭',
            anonymize: '匿名',
            add: '添加',
            remove: '去除',
            proceed: '继续',
        },
        mainHeader: {
            contact: '联系',
            support: '支持',
            supportPortal: '支持入口',
            supportDoc: '支持文档',
            userGuide: '用户指南',
            smsGuide: 'SMS用户指南(PDF)',
            knowledgeBase: '知识库',
            webFormsDoc: '网页表格文档',
            apiDoc: 'API文档',
            sourceCode: '源代码',
        },
        store: {
            subEngagement: '订阅者管理',
        },
        fields: {
            clear: '清除',
            selectField: '选择信息栏...',
        },
        notifications: {
            fromMe: '来自我的',
            allOrg: '所有公司',
            proofs: '校验',
            extracts: '提取',
            checks: '检查',
            approvals: '通过',
            sends: '发送',
        },
        customFields: {
            addNew: '添加新信息栏',
            usage: '管理企业自定义信息栏',
            customFields: '自定义信息栏',
            type: '信息栏类别',
            calculationType: '计算类型',
            calculationTypes: {
                static: '静态信息栏 | Static custom field',
                calculated: '计算自定义信息栏 | Calculated custom field',
            },
            field: '信息栏名称',
            groupName: '组群名称',
            targetExpression: '目标表达式',
            rule: '规则',
            clear: '清除不符合目标表达式的订阅用户的信息栏内容。',
            update: '更新符合目标表达式的订阅用户的信息栏内容。',
            frequency: '频率',
            types: {
                subscriber: '订阅者',
                purchase: '购买',
                product: '产品',
                purchaseProduct: '产品线项目',
                visit: '查看',
                subscriberEvent: '订阅者项目',
                page: '页',
                partition: '分区',
                subscriberList: '订阅用户',
            },
            customFieldSetup: '自定义信息栏设置',
            newCustomField: '新信息栏',
            description: '描述',
            schema: '架构',
            downloadCustomFields: '下载自定义信息栏',
            uploadCustomFields: '上传自定义信息栏',
            startDateTime: '开始时间 日期',
            interval: '触发间隔',
            once: '一次',
            hourly: '每小时',
            daily: '每天',
            weekly: '每周',
            monthly: '每月',
            regex: '验证正则表达式',
            statistics: {
                customFieldStatistics: '自定义信息栏数据',
                subWithValue: '有该信息栏的订阅用户数量:',
                subWithInvalidValue: '有无效值的订阅用户数量:',
                uniqueValue: '特殊值数量:',
                uniqueInvalidValue: '特殊无效值数量:',
                commonValidValue: '最常见有效值:',
                commonInvalidValue: '最常见无效值:',
                lastUpdate: '数据最后更新于:',
            },
        },
        partitions: {
            addNew: '添加新分区',
            usage: '管理企业分区',
            partitions: '分区',
            customFields: '自定义信息栏',
            clearCustomField: '清除',
            ref: '外部ID',
            partitionSetup: '分区设置',
            newPartition: '新分区',
            downloadPartitions: '下载分区',
            uploadPartitions: '上传分区',
            partitionSelector: '分区选择器',
        },
        featureGmailPromo: {
            dealAnnotation: 'Deal Annotation',
            dealAnnotationContent: 'Add Deal Annotations to your emails to help your recipients take action.',
        },
        select: '选择...',
        // Side nav
        organization: '企业',
        dashboard: '主界面',
        campaigns: '系列活动',
        campaign: '系列活动',
        assets: '图片集',
        subscribers: '订阅用户',
        settings: '设置',
        franchisees: '加盟商',
        // Dashboard
        newActivity: '新的活动',
        // Placeholders
        searchPlaceholder: '搜索',
        enterToSearchPlaceholder: '点击回车以应用过滤条件',
    },
};
