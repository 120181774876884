import { Store as UserStore } from './modules/User';
import { Set as ImmSet } from 'immutable';

export type Roles = 'admin' | 'users' | 'franchisee';

const defaultRoleAccess: Roles[] = ['admin', 'users'];

export interface ACLRules {
    orgs?: string[];
    roles?: Roles[];
    features?: string[];
    capabilities?: string[];
    userAccountDomain?: string[];
}

function organizationAccessCheck(_, org, orgs: string[]): boolean {
    // Check if user has access to organization
    if (!orgs || !orgs?.length) {
        return false;
    }

    return orgs.includes(org.get('organization_id'));
}

function notificationAccessCheck(_, __, roles): boolean {
    // Check if the user has the given role across all organizations they belong to
    if (!roles) {
        return roles;
    }
    const allOrgRoles = UserStore.getAllOrgRoles();
    if (!allOrgRoles) {
        return false;
    }
    const allRoles = allOrgRoles.filter((r) => r);
    // Empty 'allRoles' meant roles were null, which means admin/users.
    if (allRoles.count()) {
        return allRoles.filter((role) => roles?.includes(role)).count() > 0;
    } else {
        return defaultRoleAccess.filter((role) => roles?.includes(role)).length > 0;
    }
}

function roleCheck(_, org, roles) {
    // Check if user has the required role to access certain resources
    if (!roles || !roles?.length) {
        return false;
    }

    const orgRoles = org.get('roles');
    if (orgRoles) {
        return orgRoles.filter((role) => roles?.includes(role)).count() > 0;
    } else if (roles.length === 1 && roles[0] === 'admin' && !org.get('admin')) {
        return false;
    }
    return true;
}

function featureCheck(user, _, features) {
    // Check if user can access feature
    if (!features) {
        return false;
    }

    const settings = user.settings;
    const advancedSettings = settings?.advanced;
    const betaEnabled = advancedSettings?.enableBetaFeatures;
    const enabledKeys = betaEnabled
        ? ImmSet(
              advancedSettings.featureKeys
                  .split('\n')
                  .map((k) => k.trim())
                  .filter((k) => !!k)
          )
        : ImmSet();
    const featureDisabled = !features.some((key) => {
        if (key.indexOf('!') === 0) {
            // Invert the match -- show if beta is disabled or if the key isn't present
            return !betaEnabled || !enabledKeys.has(key.substring(1));
        } else {
            // Normal match -- show if beta is enabled and the key is present
            return betaEnabled && enabledKeys.has(key);
        }
    });
    return !featureDisabled;
}

function capabilityCheck(_, org, capabilities) {
    // Check if user has the required capability
    return org.get('capabilities').toSet().intersect(capabilities).count() > 0;
}

function userAccountDomainCheck(user, _, domains) {
    // Check if user has the required email domain
    const email = user.email;
    if (!email) {
        return false;
    }

    return domains.includes(email.split('@')[1]);
}

/**
 * Check if the logged-in user has access to certain resources or feature sets.
 *
 * @param user the user profile
 * @param org  the organization that is currently loaded or being viewed by the u ser
 * @param rules the ACL rules to check against
 * @param allOrgs  whether to check all the organizations the user belongs to
 *
 * @returns true if the logged-in user can access, otherwise false.
 */
export function check(user, org, rules, allOrgs = false, not = false) {
    let hasNotificationAccess = true;
    if (allOrgs && rules.roles?.length) {
        hasNotificationAccess = notificationAccessCheck(user, org, rules.roles);
    }

    // User is not viewing an org, we're done.
    if (!org) {
        return hasNotificationAccess;
    }

    let hasOrgAccess = true;
    if (rules.orgs && rules.orgs.length) {
        hasOrgAccess = organizationAccessCheck(user, org, rules.orgs);
    }

    let hasRightRoles = true;
    if (rules.roles && rules.roles.length) {
        hasRightRoles = roleCheck(user, org, rules.roles);
    }

    let hasEnabledFeature = true;
    if (rules.features && rules.features.size && user) {
        hasEnabledFeature = featureCheck(user, org, rules.features);
    }

    let hasCapability = true;
    if (rules.capabilities && rules.capabilities.size && org) {
        hasCapability = capabilityCheck(user, org, rules.capabilities);
        if (not) {
            hasCapability = !hasCapability;
        }
    }

    let hasUserAccountDomain = true;
    if (rules.userAccountDomain?.size && user) {
        hasUserAccountDomain = userAccountDomainCheck(user, org, rules.userAccountDomain);
    }

    return (
        hasNotificationAccess &&
        hasOrgAccess &&
        hasRightRoles &&
        hasEnabledFeature &&
        hasCapability &&
        hasUserAccountDomain
    );
}
