import UAParser from 'ua-parser-js';

const uap = new UAParser();
const uaInfo = uap.getResult();

export function addCSSBrowserSelectors() {
    const engine = uaInfo.engine.name ? uaInfo.engine.name.toLowerCase() : null;
    const browser = uaInfo.browser.name ? uaInfo.browser.name.toLowerCase() : null;
    const browserVer = uaInfo.browser.major;
    const osname = uaInfo.os.name ? uaInfo.os.name.split(' ') : null;
    let os = '';
    if (osname && osname.length) {
        os = osname.shift() as string;
    }
    os = os.toLowerCase();
    const osVersion = uaInfo.os.version && uaInfo.os.version.split('.').shift();
    if (!document.documentElement) {
        return;
    }
    document.documentElement.className = [
        engine || '',
        browser || '',
        `${browser}${browserVer}`,
        os,
        `${os}${osVersion}`,
    ].join(' ');
}
