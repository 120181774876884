import WriteCommand from './WriteCommand';

interface IActivityLayout {
    id?: number;
    name?: string;
    description?: string;
    basedOnRevisionId?: number;
    activityType?: string;
    content?: Record<string, any>;
    isDeleted?: boolean;
}

interface IActivityTheme extends Record<string, any> {
    id?: number;
    content?: Record<string, any>;
}

abstract class ActivityWriteCommand<T> extends WriteCommand<T> {
    abstract filter(response: any): boolean;
    abstract transformer(result: any): any;
}

export class ActivityLayoutWriteCommand extends ActivityWriteCommand<IActivityLayout> {
    constructor(organizationId: number) {
        super('activityLayout', organizationId);
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error) {
            if (response.error.data) {
                const errData = response.error.data[0];
                throw new Error(errData.name, errData.message ? errData.message : errData);
            } else {
                throw new Error(response.error?.message ?? response.error);
            }
        }
        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'activityLayout' &&
            response.result.data &&
            Array.isArray(response.result.data)
        );
    }
}

export class ActivityThemeWriteCommand extends ActivityWriteCommand<IActivityTheme> {
    constructor(organizationId: number) {
        super('activityTheme', organizationId);
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error) {
            if (response.error.data) {
                const errData = response.error.data[0];
                throw new Error(errData.name, errData.message ? errData.message : errData);
            } else {
                throw new Error(response.error?.message ?? response.error);
            }
        }
        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'activityTheme' &&
            response.result.data &&
            Array.isArray(response.result.data)
        );
    }
}
