import AppServer from './api/AppServer';
import { isKeyed } from 'immutable';

import Query from './api/Query';
import QueryCommand from './api/QueryCommand';

import { ResourceType } from './AppTypes';
import WorkflowCommand, { WorkflowAction } from './api/WorkflowCommand';
import { UserOrganizationProfile } from './types/IUser';

export default class Store {
    public static command(organization: UserOrganizationProfile, resourceType: ResourceType): QueryCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create query command for org ${organization} and resource ${resourceType}`);
        }

        let cmd = new QueryCommand(organization.get('organization') as number);
        if (resourceType && resourceType !== 'stats') {
            cmd = cmd.query(new Query(resourceType)) as QueryCommand;
        }
        return cmd;
    }

    public static workflowCommand<T>(organization: UserOrganizationProfile, action: WorkflowAction) {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create workflow command for ${organization}`);
        }
        return new WorkflowCommand<T>(organization.get('organization'), action);
    }

    // FIXME any - convert AppServer to TS
    public static connection(organization: UserOrganizationProfile): any {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create Store connection for ${organization}`);
        }
        return AppServer.connect(
            organization.get('hostname') as string,
            organization.get('organization') as string,
            organization.get('organization_id') as string
        );
    }

    public static runCommand(organization: UserOrganizationProfile, command: QueryCommand) {
        return Store.connection(organization).execute(command);
    }

    public static size() {
        return -1;
    }

    public static clearCache(): void {
        /* no-op */
    }
}
