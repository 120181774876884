import Rx from 'rx';
import { default as QueryCommand, IQueryCommandResponse } from '../api/QueryCommand';
import { default as WriteCommand, IWriteCommandResponse } from '../api/WriteCommand';
import AppConfig from '../AppConfig';
import BaseActions from '../BaseActions';
import BaseStore from '../BaseStore';
import { default as AppErrors, Types } from '../AppErrors';
import { ResourceID } from '../AppTypes';
import { IAnonymizeSubscriberCommandResponse } from '../api/AnonymizeSubscriberCommand';

import { Map as ImmMap, List as ImmList } from 'immutable';
import { UserOrganizationProfile } from '../types/IUser';
import VueI18n from 'vue-i18n';
let i18n: VueI18n | null = null;

let _cache = ImmMap();
const _actionSubject = new Rx.Subject();
const _hotObservable = _actionSubject.publish().refCount();

export interface ISubscriber {
    address: string | null;
    address2: string | null;
    address3: string | null;
    bounced?: string | null;
    country: string | null;
    custom: {
        [key: string]: string | number | null;
    } | null;
    dob: string | null;
    email: string;
    firstname: string | null;
    gender: string | null;
    id: ResourceID;
    invalidTimestamp?: string | null;
    lastname: string | null;
    lists: ISubscriberList[];
    name?: string;
    notifications?: string | null;
    phone: string | null;
    postcode: string | null;
    ref: string | null;
    resumeDate: string | null;
    segments?: Record<string, any> | null;
    state: string | null;
    suburb: string | null;
    title: string | null;
    unsubscribed?: string | null;
    unsubscribedTimestamp?: string | null;
    emailHash: string;
    __resourceType?: 'subscriber';
    [key: string]: any;
}

interface ISubscriberList {
    listId: number;
    listName: string;
    subscribedTimestamp: string | null;
    subscriptionOption: any;
    unsubscribedTimestamp: string | null;
    listImportName: string | null;
}

interface ISubscriberAction {
    id?: ResourceID;
    email: string;
    ref?: string;
    phone?: string;
    lists?: {
        listId: number;
        option: string | null;
        unsubscribed: boolean;
    };
}

export let defaultProfileFields = {
    address: 'Address',
    address2: 'Address line 2',
    address3: 'Address line 3',
    cluster_id: 'Tag ID',
    country: 'Country',
    dob: 'Date of birth',
    email: 'Email',
    extra: 'Additional data',
    firstname: 'First name',
    gender: 'Gender',
    is_invalid: 'Invalid flag',
    is_unsubscribed: 'Global unsubscribe flag',
    lastname: 'Last name',
    notifications: 'Notifications',
    phone: 'Phone',
    postcode: 'Postcode',
    ref: 'External ID',
    resume_date: 'Resume Date',
    state: 'State',
    suburb: 'Suburb',
    title: 'Title',
};

export let writableProfileFields = {
    address: 'Address',
    address2: 'Address line 2',
    address3: 'Address line 3',
    country: 'Country',
    dob: 'Date of birth',
    email: 'Email',
    extra: 'Additional data',
    firstname: 'First name',
    gender: 'Gender',
    lastname: 'Last name',
    notifications: 'Notifications',
    phone: 'Phone',
    postcode: 'Postcode',
    resume_date: 'Resume Date',
    state: 'State',
    suburb: 'Suburb',
    title: 'Title',
};

export let defaultListFields = {
    is_list_unsubscribed: 'Unsubscribe flag',
    list_option: 'Subscription option',
};

export function setI18n(val: VueI18n) {
    i18n = val;
    defaultProfileFields = {
        address: String(i18n.t('subscriber.address')),
        address2: String(i18n.t('subscriber.address2')),
        address3: String(i18n.t('subscriber.address3')),
        cluster_id: String(i18n.t('subscriber.cluster_id')),
        country: String(i18n.t('subscriber.country')),
        dob: String(i18n.t('subscriber.dob')),
        email: String(i18n.t('subscriber.email')),
        extra: String(i18n.t('subscriber.extra')),
        firstname: String(i18n.t('subscriber.firstname')),
        gender: String(i18n.t('subscriber.gender')),
        is_invalid: String(i18n.t('subscriber.is_invalid')),
        is_unsubscribed: String(i18n.t('subscriber.is_unsubscribed')),
        lastname: String(i18n.t('subscriber.lastname')),
        notifications: String(i18n.t('subscriber.notifications')),
        phone: String(i18n.t('subscriber.phone')),
        postcode: String(i18n.t('subscriber.postcode')),
        ref: String(i18n.t('subscriber.ref')),
        resume_date: String(i18n.t('subscriber.resume_date')),
        state: String(i18n.t('subscriber.state')),
        suburb: String(i18n.t('subscriber.suburb')),
        title: String(i18n.t('subscriber.title')),
    };

    writableProfileFields = {
        address: String(i18n.t('subscriber.address')),
        address2: String(i18n.t('subscriber.address2')),
        address3: String(i18n.t('subscriber.address3')),
        country: String(i18n.t('subscriber.country')),
        dob: String(i18n.t('subscriber.dob')),
        email: String(i18n.t('subscriber.email')),
        extra: String(i18n.t('subscriber.extra')),
        firstname: String(i18n.t('subscriber.firstname')),
        gender: String(i18n.t('subscriber.gender')),
        lastname: String(i18n.t('subscriber.lastname')),
        notifications: String(i18n.t('subscriber.notifications')),
        phone: String(i18n.t('subscriber.phone')),
        postcode: String(i18n.t('subscriber.postcode')),
        resume_date: String(i18n.t('subscriber.resume_date')),
        state: String(i18n.t('subscriber.state')),
        suburb: String(i18n.t('subscriber.suburb')),
        title: String(i18n.t('subscriber.title')),
    };

    defaultListFields = {
        is_list_unsubscribed: String(i18n.t('subscriber.is_list_unsubscribed')),
        list_option: String(i18n.t('subscriber.list_option')),
    };
}

export class Store extends BaseStore {
    public static searchByExpression(
        organization: UserOrganizationProfile,
        expression: string,
        limit = AppConfig.Defaults.limit
    ) {
        let command = super.command(organization, 'subscriber');
        command = command.query(command.query().expression(expression)).limit(limit);
        return Store._search(organization, command);
    }

    public static searchById(
        organization: UserOrganizationProfile,
        id: ResourceID,
        limit = AppConfig.Defaults.limit,
        forceFetch = false
    ) {
        if (!forceFetch && _cache.has(id)) {
            return Rx.Observable.from([ImmList([_cache.get(id)])]);
        }
        let command = super.command(organization, 'subscriber').sortKey('id');
        command = command.query(command.query().id(id)).limit(limit);
        return Store._search(organization, command);
    }

    public static getProfile(organization: UserOrganizationProfile, subscriberId: string) {
        const command = super.command(organization, 'subscriber').sortKey('id');
        const query = command.query().id(subscriberId);

        return super
            .connection(organization)
            .execute(command.query(query))
            .map((response: IQueryCommandResponse<ISubscriber>) => {
                if (response.error) {
                    Rx.Observable.throw(new AppErrors(Types.ApplicationError, response.error));
                }
                return ImmMap(response.result.data[0]);
            });
    }

    private static update(response: IQueryCommandResponse<ISubscriber>) {
        if (response.error) {
            Rx.Observable.throw(new Error(response.error));
        }
        _cache = _cache.withMutations((cache) => {
            [].map.call(response.result.data, (item: ISubscriber) => {
                item.__resourceType = 'subscriber';
                return cache.set(item.id, item);
            });
        });
        return _cache.toList();
    }

    private static _search(organization: UserOrganizationProfile, command: QueryCommand) {
        return super
            .connection(organization)
            .execute(command)
            .map((response: IQueryCommandResponse<ISubscriber>) => {
                // If we get a QueryCancelledError (because we superseded our subscriber query with another one), treat that as a query that returned no results
                if (response.error && response.error !== Types.QueryCancelledError) {
                    throw new AppErrors(response.error);
                }
                response.result.data = [].map.call(response.result.data, (x: ISubscriber) => {
                    x.__resourceType = 'subscriber';
                    if (!Object.prototype.hasOwnProperty.call(x, 'name')) {
                        x.name = '';
                        if (x.firstname) {
                            x.name = x.firstname;
                        } else if (x.lastname) {
                            x.name = x.name + ' ' + x.lastname;
                        } else {
                            x.name = x.email || '';
                        }
                    }
                    return x;
                });
                Store.update(response);
                return ImmList(response.result.data);
            });
    }
}

export class Actions extends BaseActions {
    public static observable() {
        return _hotObservable;
    }

    public static createOrUpdate(
        organization: UserOrganizationProfile,
        objectId: string | null,
        data: ISubscriberAction
    ) {
        const command: WriteCommand<ISubscriberAction> = super.command(organization, 'subscriber');
        // Overwrite the ID passed by the form, if any
        if (data.id && objectId) {
            if (data.id !== parseInt(objectId, 10)) {
                throw new AppErrors(Types.ApplicationError, 'Subscriber IDs do not match');
            }
            data.id = parseInt(objectId, 10) as ResourceID;
        }

        return super
            .connection(organization)
            .execute(command.data(data))
            .first()
            .flatMapLatest((response: IWriteCommandResponse) => {
                // We do a query here to get an updated subscriber profile
                const subscriberId = response.result.data && response.result.data.pop();
                if (!subscriberId) {
                    throw new Error(`An error occurred while updating subscriber ${subscriberId}`);
                }
                return Store.searchById(organization, subscriberId, 1, true);
            })
            .catch((error: any) => {
                if (typeof error.name === 'object' && error.name.message) {
                    error.message = error.name.message;
                }
                return Rx.Observable.throw(error);
            });
    }

    public static anonymize(organization: UserOrganizationProfile, objectId: ResourceID) {
        const command = super.anonymizeSubscriberCommand(organization, objectId);
        return super
            .connection(organization)
            .execute(command)
            .flatMapLatest((response: IAnonymizeSubscriberCommandResponse) => {
                const subscriberId = response.result.data && response.result.data.subscriberId;
                if (!subscriberId) {
                    throw new Error(`An error occurred while updating subscriber ${subscriberId}`);
                }
                return Store.searchById(organization, subscriberId, 1, true);
            })
            .catch((error: Error) => Rx.Observable.throw(error));
    }
}
