export function extend(a, b) {
    for (const key in b) {
        if (Object.prototype.hasOwnProperty.call(b, key)) {
            a[key] = b[key];
        }
    }

    return a;
}

export function identity(x) {
    return x;
}
