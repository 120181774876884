import { parsePhoneNumber, ParseError } from 'libphonenumber-js/max';
import { ResourceID } from '../AppTypes';

// IDs are type INT in the DB
// https://www.postgresql.org/docs/9.6/datatype-numeric.html
const PG_INTEGER_MAX = 2147483647;

// Phone number country codes where we think our users live in
// SG/KR/CN are possibly WebBeds users.
const SUPPORTED_PHONE_CC = ['AU', 'NZ', 'SG', 'KR', 'CN'];

// input type=email regex
// https://www.w3.org/TR/html52/sec-forms.html#email-state-typeemail
// MAYBE FIX this if we get complaints of an email not validating.
const emailRE =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isResourceIDValid(resourceID: ResourceID) {
    return resourceID <= PG_INTEGER_MAX;
}

export function isEmailValid(email: string) {
    return email.match(emailRE);
}

export function isPhoneValid(phone: string) {
    // We'll do a phone search first, failing that we'll do an ID and string search
    let phonePart;
    try {
        for (const code of SUPPORTED_PHONE_CC) {
            phonePart = parsePhoneNumber(phone, code as any);
            if (phonePart && phonePart.isValid()) {
                break;
            } else {
                phonePart = undefined;
            }
        }
    } catch (error) {
        // Not a phone number, invalid country, too short(e.g.national number too short),
        // too long (e.g. national number too long)
        if (error instanceof ParseError) {
            phonePart = undefined;
        } else {
            throw error;
        }
    }
    return phonePart;
}
