import { bisector } from 'd3-array';
import { nanoid } from 'nanoid';
import AppConfig from '../AppConfig';

const escapedEntities = {
    '"': '&quot;',
    '&': '&amp;',
    "'": '&#x27;',
    '/': '&#x2F;',
    '<': '&lt;',
    '>': '&gt;',
};

export function indexFromWeek(data: any, week: Date) {
    const idx = bisector((d: any) => d.week).left(data, week, 1) - 1;
    if (week.valueOf() - data[idx].week.valueOf() > 6.5 * 86400 * 1000) {
        return idx + 1;
    } else {
        return idx;
    }
}

export function indexFromTimestamp(data: any, timestamp: Date) {
    const idx = bisector((d: any) => d.timestamp).left(data, timestamp, 1) - 1;
    if (
        timestamp.valueOf() - data[idx].timestamp.valueOf() >
        (data[idx + 1].timestamp.valueOf() - data[idx].timestamp.valueOf()) * 0.9
    ) {
        return idx + 1;
    } else {
        return idx;
    }
}

export function escapeRegex(re: string) {
    return re.replace(/[-[\]{}()*+!<=:?./\\^$|#,]/g, '\\$&');
}

export function escape(str: string) {
    return str.replace(/[&<>"'/]/g, (match) => escapedEntities[match]);
}

export function downloadContent(content: any, filename: string, contentType = 'text/csv;charset=utf-8') {
    const blob = new Blob([content], { type: contentType });
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    if (typeof anchor.download !== 'undefined') {
        anchor.download = filename;
    } else {
        // FIXME Safari < 11 users need to ⌘-S to save the file.
        anchor.target = '_blank';
    }
    anchor.click();
    window.URL.revokeObjectURL(blob as any);
}

export function shortId() {
    return nanoid(AppConfig.Components.nanoid);
}
