import Rx from 'rx';
import SessionStore from '../SessionStore';
import BaseStore from '../BaseStore';
import AppServer from '../api/AppServer';
import { default as AppErrors, Types } from '../AppErrors';
import { default as Session, ISessionCmdResponseData } from '../api/Session';
import {
    IUserProfile,
    UserOrganizationProfile,
    IUserOrganizationAccess,
    IUserOrganizationIssuer,
} from '../types/IUser';
import { ILoginDetails } from '../types/ILoginDetails';
import AppConfig from '../AppConfig';

import { List as ImmList, Map as ImmMap, Record, Set as ImmSet } from 'immutable';
import { OrganisationType } from '../AppTypes';

export type UserProfile = ImmMap<keyof IUserProfile, any>;
export interface IUserPreference {
    displayView: string;
    'campaign/filter': string;
    'campaign/sortBy': string;
    'campaigns/filter': string;
    'campaigns/sortBy': string;
    'chart/activityResponseBar': string;
    'chart/activityResponseOverlay': string;
    'chart/listChartOverlay': string;
    'dashboard/filter': string;
    'dashboard/sortBy': string;
    'editor/selectedTab': string;
    'integration/filter': string;
    'integration/sortBy': string;
    'subscribers/filter': string;
    'subscribers/sortBy': string;
    'workflow/chatPaneIsOpen': boolean;
    'partitions/activePartitionIds': string;
    locale: string;
}

export const roleNames = {
    'Database.Read': 'View Database',
    'Database.Manage': 'Manage Database',
    'Database.Extract': 'Extract Database',
    'Content.Edit': 'Edit Content',
    'Content.Approve': 'Approve Content',
    'Content.Deploy': 'Deploy Content',
    'System.PowerUser': 'Power User',
    'System.Admin': 'Administrator',
    'System.BaseUser': 'Base User',
    'Integration.Manage': 'Manage Integrations',
    franchisee: 'Franchisee',
};

// Used in the login process flow

const LoginRecord = Record<ILoginDetails>({
    email: null,
    id: null,
    newPassword: null,
    otpCode: null,
    password: null,
    requestId: null,
});

function newLoginRecord() {
    return new LoginRecord({});
}

let _credentials = newLoginRecord();

function capabilities_from_roles(roles: string[]): string[] {
    const caps: string[] = [];

    if (
        roles.indexOf('System.Admin') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.BaseUser') > -1 ||
        roles.indexOf('Content.Edit') > -1 ||
        roles.indexOf('Content.Approve') > -1 ||
        roles.indexOf('Content.Deploy') > -1 ||
        roles.indexOf('Database.Extract') > -1 ||
        roles.indexOf('Database.Read') > -1 ||
        roles.indexOf('Database.Manage') > -1
    ) {
        caps.push('base');
    }
    if (roles.indexOf('franchisee') > -1) {
        caps.push('franchisee');
    }
    if (roles.indexOf('System.Admin') > -1) {
        caps.push('admin');
    }
    if (
        roles.indexOf('Content.Edit') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('content-edit');
    }
    if (
        roles.indexOf('Content.Approve') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('content-approve');
    }
    if (
        roles.indexOf('Content.Deploy') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('content-deploy');
    }
    if (
        roles.indexOf('Database.View') > -1 ||
        roles.indexOf('Database.Manage') > -1 ||
        roles.indexOf('Database.Extract') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('database-view');
    }
    if (
        roles.indexOf('Database.Manage') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('database-manage');
    }
    if (
        roles.indexOf('Database.Extract') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('database-extract');
    }
    if (
        roles.indexOf('Integration.Manage') > -1 ||
        roles.indexOf('System.PowerUser') > -1 ||
        roles.indexOf('System.Admin') > -1
    ) {
        caps.push('integration-manage');
    }

    return caps;
}

export class Store {
    /**
     * Returns the session observable
     */
    public static session() {
        return SessionStore.asObservable();
    }

    public static getUsername(): string {
        return SessionStore.get('email') as string;
    }

    public static getUserDetails(organization: UserOrganizationProfile) {
        const authInfo = BaseStore.connection(organization)?.authInfo;
        return {
            email: SessionStore.get('email'),
            name: '',
            id: SessionStore.get('id'),
            phone: SessionStore.get('phone'),
            subscriberId: authInfo?.subscriberId,
            partitionSets: authInfo?.partitionSets,
        };
    }

    public static hasOTPEnabled() {
        return SessionStore.get('otp_enabled');
    }

    public static hasSSOEnabled() {
        return SessionStore.get('sso_enabled');
    }

    public static getSessionId() {
        const session = SessionStore.getSessionId();
        if (!session) {
            return null;
        }
        return session.get('sessionId');
    }

    public static getOrganizationName(orgId: string) {
        const tokens = SessionStore.get('tokens');
        if (!tokens) {
            return;
        }
        const org = tokens.filter((o: UserOrganizationProfile) => o.get('organization_id') === orgId);
        if (!org.size) {
            return;
        }
        return org.first().get('name');
    }

    public static getAllOrgRoles() {
        const tokens = SessionStore.get('tokens');
        if (!tokens) {
            return;
        }
        return tokens.map((t: UserOrganizationProfile) => t.get('roles')).flatten(true);
    }

    public static getOrganizationUsers(org: UserOrganizationProfile): Rx.Observable<ImmList<IUserOrganizationAccess>> {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.getOrganizationAccess(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
            })
        ).flatMapLatest((response: ISessionCmdResponseData) =>
            Rx.Observable.from([ImmList(response.result.user_accounts)])
        );
    }

    public static getOrganizationIssuers(
        org: UserOrganizationProfile
    ): Rx.Observable<ImmList<IUserOrganizationIssuer>> {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.getOrganizationAccess(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
            })
        ).flatMapLatest((response: ISessionCmdResponseData) =>
            Rx.Observable.from([ImmList(response.result.sso_issuers)])
        );
    }

    public static getPreference(key: string): any {
        return SessionStore.getSettings(['preferences', key]);
    }

    public static hasBetaFeatureEnabled(feature: string): boolean {
        const betaFeatures = SessionStore.getSettings(['advanced', 'enableBetaFeatures']);
        if (betaFeatures) {
            const enabledFeatures = ImmSet(
                SessionStore.getSettings(['advanced', 'featureKeys'])
                    .split('\n')
                    .map((f) => f.trim())
                    .filter((f) => !!f)
            );
            return enabledFeatures.has(feature);
        }
        return false;
    }
}

export class Actions {
    public static getUserLoginMethod(email: string) {
        return Session.getUserLoginMethod(ImmMap({ email: email })).map((response: ISessionCmdResponseData) => {
            SessionStore.update(response.result);
            return response;
        });
    }

    public static tryRenewSession() {
        return Session.tryRenewSession().map((response: ISessionCmdResponseData) => {
            SessionStore.update(response.result);
            return response;
        });
    }

    public static login(email: string, password: string) {
        _credentials = _credentials.merge({
            email,
            password,
        });
        return Session.createSession(_credentials).map((response: ISessionCmdResponseData) => {
            SessionStore.update(response.result);
            return response;
        });
    }

    public static getUserProfile() {
        const sessionId = Store.getSessionId();
        if (!sessionId) {
            throw new AppErrors(Types.InvalidSessionError, 'Invalid session.');
        }
        return Session.getUserProfile()
            .map((response: ISessionCmdResponseData) => {
                // Convert roles to capabilities for easier access control.
                response.result.tokens.forEach((token) => {
                    token.capabilities = token.roles ? capabilities_from_roles(token.roles) : [];
                });
                SessionStore.update(response.result);
                return response;
            })
            .do(() => {
                // Tokens should be in session store by now. Clear temp credential data.
                if (_credentials.get('password', null) || _credentials.get('newPassword', null)) {
                    _credentials = newLoginRecord();
                }
            });
    }

    public static logout() {
        const params = SessionStore.getSessionId();
        // Some components get attached early after the logout and redirect process.
        // Ensure we clear the session state prior to those components getting attached
        // to avoid them reading stale session state.
        SessionStore.reset();
        AppServer.disconnectAll();
        if (!params) {
            return;
        }
        return Session.terminateSession(params)
            .flatMapLatest(() => fetch(AppConfig.Connection.logoutUrl))
            .do(() => {
                Session.disconnect();
            });
    }

    public static verifyOTP(otpCode: string) {
        _credentials = _credentials.set('otpCode', otpCode);
        return Session.createSession(_credentials).map((response: ISessionCmdResponseData) => {
            SessionStore.update({
                sessionId: response.result.sessionId,
            });
            return response;
        });
    }

    public static confirmNewPassword(newPassword: string) {
        _credentials = _credentials.set('newPassword', newPassword);
        return Session.createSession(_credentials).map((response: ISessionCmdResponseData) => {
            SessionStore.update({
                sessionId: response.result.sessionId,
            });
            return response;
        });
    }

    public static create(email: string, phone: string) {
        return Session.createUser(ImmMap({ email, phone }));
    }

    public static resetPassword(email: string, otpCode: string | null = null) {
        return Session.resetPassword(ImmMap({ email, otpCode }));
    }

    public static generateOTP() {
        return Session.generateOTP().map((response: ISessionCmdResponseData) => response.result);
    }

    public static validateOTP(otpSecret: string, otpCode: string) {
        return Session.validateOTP(otpSecret, otpCode).map((response: ISessionCmdResponseData) => response.result);
    }

    public static enableOTP(otpSecret: string) {
        return Session.enableOTP(otpSecret).map((response: ISessionCmdResponseData) => response.result);
    }

    public static disableOTP() {
        return Session.disableOTP().map((response: ISessionCmdResponseData) => response.result);
    }

    public static getOrganizationToken(organizationId: OrganisationType) {
        return Session.getOrganizationToken(ImmMap({ organizationId })).map(
            (response: ISessionCmdResponseData) => response.result
        );
    }

    /**
     * Update user settings
     *
     * Returns the updated settings in an Observable
     */
    public static updateSettings(settings: any) {
        return Actions.getUserProfile()
            .first()
            .flatMapLatest(() => {
                return Session.updateUserSettings(
                    ImmMap({
                        sessionId: SessionStore.get('sessionId'),
                        settings: JSON.stringify(settings),
                    })
                )
                    .first()
                    .flatMapLatest(() => Actions.getUserProfile().first());
            });
    }

    /**
     * Update user preferences
     *
     * @param preference
     */
    public static updatePreference(pref: Partial<IUserPreference>) {
        const settings = Object.create(null);
        settings.preferences = pref;
        return Session.updateUserSettings(
            ImmMap({
                sessionId: SessionStore.get('sessionId'),
                settings: JSON.stringify(settings),
            })
        )
            .first()
            .flatMapLatest(() => Actions.getUserProfile().first());
    }

    /**
     * Update the current organization's theme
     *
     * Returns the updated Org profile wrapped in an Observable
     */
    public static updateOrgTheme(org: UserOrganizationProfile, themeData: any) {
        // This call doesn't return any response. Return updated org profile instead.
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.updateOrganizationTheme(
            ImmMap({
                logo_uri: themeData.logo_uri,
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                theme: JSON.stringify(themeData.theme),
            })
        ).flatMapLatest(() => Actions.getUserProfile().first());
    }

    /**
     * Update the current organization settings
     *
     * Returns the updated Org profile wrapped in an Observable
     */
    public static updateOrgSettings(org: UserOrganizationProfile, settings: any) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.updateOrganizationSettings(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                settings: JSON.stringify(settings),
            })
        ).flatMapLatest(() => Actions.getUserProfile().first());
    }

    /**
     * Adds an organization SSO issuer
     */
    public static addOrgIssuer(org: UserOrganizationProfile, issuerType: string, issuerUri: string) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.addOrganizationIssuer(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                issuerType,
                issuerUri,
            })
        ).flatMapLatest(() => Store.getOrganizationIssuers(org));
    }

    /**
     * Disable an organization SSO issuer
     */
    public static disableOrgIssuer(org: UserOrganizationProfile, issuerId: string) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.disableOrganizationIssuer(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                issuerId,
            })
        ).flatMapLatest(() => Store.getOrganizationIssuers(org));
    }

    /**
     * Update user privileges based on the specified roles
     */
    public static updateUserPrivileges(org: UserOrganizationProfile, userId: string, roles: string[]) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.updateOrganizationAccess(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                roles,
                userId,
            })
        ).flatMapLatest(() => Store.getOrganizationUsers(org));
    }

    /**
     * Grant organization access to a user identified by email address
     */
    public static grantOrgAccess(org: UserOrganizationProfile, userEmail: string, userPhone: string, roles: string[]) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.grantOrganizationAccess(
            ImmMap({
                email: userEmail,
                organizationId: org.get('organization_id'),
                phone: userPhone || 'n/a', // Mandatory field?
                sessionId: SessionStore.get('sessionId'),
                roles,
            })
        ).flatMapLatest(() => Store.getOrganizationUsers(org));
    }

    /**
     * Revoke user acceess
     */
    public static revokeOrgAccess(org: UserOrganizationProfile, userId: string) {
        if (!org || !org.has('admin')) {
            throw new AppErrors(Types.PrivilegesError);
        }
        return Session.revokeOrganizationAccess(
            ImmMap({
                organizationId: org.get('organization_id'),
                sessionId: SessionStore.get('sessionId'),
                userId,
            })
        );
    }
}

(Rx.Observable as any).prototype.getUsername = function () {
    return this.map((d: UserProfile) => d.get('email', null));
};

(Rx.Observable as any).prototype.getOrgProfile = function (this: Rx.Observable<any>, orgName: string) {
    return this.map((d: UserProfile) => {
        const tokens = d.get('tokens', null);
        if (!tokens) {
            return;
        }
        // Let upstream components handle null results (e.g. throw error, etc..);
        return tokens.filter((dorg: UserOrganizationProfile) => dorg.get('name') === orgName);
    });
};

(Rx.Observable as any).prototype.getUserSettings = function () {
    return this.map((d: UserProfile) => {
        const settings = d.get('settings', null);
        if (!settings) {
            return;
        }
        return settings;
    });
};

(Rx.Observable as any).prototype.getTheme = function (this: Rx.Observable<any>, orgName: string) {
    return this.map((d: UserProfile) => {
        const tokens = d.get('tokens');
        if (!tokens) {
            return;
        }
        const org = tokens.filter((dorg: UserOrganizationProfile) => dorg.get('name') === orgName).first();
        if (!org) {
            return;
        }
        return {
            color: org.getIn(['theme', 'color']),
            logo_uri: org.get('logo_uri'),
            name: org.get('name'),
        };
    });
};

(Rx.Observable as any).prototype.getOrganizations = function (this: Rx.Observable<any>) {
    return this.map((d: UserProfile) => {
        if (d.get('sessionId') && d.get('tokens')) {
            return d.get('tokens');
        }
        return ImmList([]);
    });
};

(Rx.Observable as any).prototype.isLoggedIn = function (this: Rx.Observable<any>) {
    return this.map((d: UserProfile) => {
        if (!d) {
            return false;
        }
        if (d.get('sessionId') && d.get('id')) {
            return true;
        } else {
            return false;
        }
    });
};
