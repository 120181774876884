/* Route resolution is top-down. Please keep in mind when updating the routes.

Note regarding redirects and aliases: https://router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect

"A redirect means when the user visits /a, the URL will be replaced by /b, and then matched as /b. But what is an alias?

An alias of /a as /b means when the user visits /b, the URL remains /b, but it will be matched as if the user is visiting /a."
*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import SessionStore from './SessionStore';
import * as User from './modules/User';
import vueStore from './components/store';
import { PiniaVuePlugin } from 'pinia';
import { useStoreRoot } from './components/stores/root';
import { useStoreActivity } from './components/stores/activity';
import { useStoreTemplate } from './components/stores/template';
import { useStoreActivityTheme } from './components/stores/activitytheme';

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);

let router: VueRouter | null = null;
const V5_TEMPLATES_MAJOR_VERSION = 5;

const handleRedirect = async (url) => {
    SessionStore.deleteRedirectUri();
    window.location.assign(url);
};

const prepareRoute = (to, from, next) => {
    if (to.query.session === 'true') {
        // ?session=true is a hint that a session renew will succeed immediately,
        // so the login form will not be shown unless that renewal fails below
        to.query.session = 'false';
    }

    const { fetchUserProfile, addAppError } = useStoreRoot();
    // Check if this was triggered by OneDesk SSO login url or user needs to be redirected after login
    const redirectUri = to.query.redirect;
    if (redirectUri) {
        if (
            ['taguchi.com.au', 'taguchimail.com', 'taguchi.com.sg'].some((td) =>
                decodeURIComponent(redirectUri).includes(td)
            )
        ) {
            if (SessionStore.getRedirectUri() !== redirectUri && redirectUri) {
                SessionStore.update({
                    redirectUri,
                });
            }
        }
    }

    if (['signup', 'passwordreset', 'login'].includes(to.name)) {
        next();
    } else if (!SessionStore.hasValidSession()) {
        // Attempt to renew the session, regardless of whether or not we got
        // the hint above. If renewal fails, redirect to /login
        User.Actions.tryRenewSession().subscribe(
            () => {
                // Check for stored redirect uri
                const cachedRedirectUri = SessionStore.getRedirectUri();
                if (cachedRedirectUri) {
                    handleRedirect(cachedRedirectUri);
                    return;
                }
                fetchUserProfile().then(() => {
                    next(); // Go to whatever the current page is
                });
            },
            () => {
                // If we don't have a session, redirect to login
                console.error('Session renewal failed. Redirecting to login..');
                next({ name: 'login', query: { next: to.path, ...to.query } });
            }
        );
    } else {
        // Valid session

        // Check for stored redirect uri
        const cachedRedirectUri = SessionStore.getRedirectUri();
        if (cachedRedirectUri) {
            handleRedirect(cachedRedirectUri);
            return;
        }

        // Retrieve user profile
        if (!vueStore.getters.user) {
            fetchUserProfile()
                .catch((error) => {
                    console.error('Failed to retrieve user profile', error);
                    if (error.name === 'InvalidSessionError') {
                        addAppError({ error, action: null });
                        next({ name: 'login', query: { next: to.path } });
                    }
                })
                .finally(() => {
                    next();
                });
        } else {
            next();
        }
    }
};

const checkOrgAccess = (to, next) => {
    const { fetchUserOrganization, addAppError } = useStoreRoot();
    // Check for stored redirect uri, if the login was re routed from normal credentials, it loses the rest of the query parameters,
    const redirectUri = to.query.redirect;
    const cachedRedirectUri = SessionStore.getRedirectUri() || redirectUri;
    if (cachedRedirectUri) {
        handleRedirect(cachedRedirectUri);
        return;
    }

    fetchUserOrganization({ org: to.params.organization }).then(
        (result) => {
            if (!result?.get('token')) {
                addAppError({ error: new Error(`Access to ${to.params.organization} not allowed`), action: null });
                next('/organizations');
            }
            next();
        },
        (error) => {
            addAppError({ error, action: null });
            next('/organizations');
        }
    );
};

const checkOrg = (to, _, next) => {
    checkOrgAccess(to, next);
};

const checkDominosFranchiseeAccess = (to, _, next) => {
    checkOrgAccess(to, next);
};

const checkCapabilities = (to, _, next) => {
    const { fetchUserOrganization, addAppError } = useStoreRoot();

    fetchUserOrganization({ org: to.params.organization }).then(
        (result) => {
            if (!result?.get('token')) {
                addAppError({ error: new Error(`Access to ${to.params.organization} not allowed`), action: null });
                next('/organizations');
                return;
            }

            const capabilities = result?.get('capabilities');
            if (capabilities && !to.meta.capabilities.some((cap) => capabilities.includes(cap))) {
                addAppError({
                    error: new Error(`Access not allowed`),
                    action: null,
                });
                const toPath: string[] = to.path.split('/');
                toPath.pop();
                next(toPath.join('/'));

                return;
            }
            next();
        },
        (error) => {
            addAppError({ error, action: null });
            next('/organizations');
        }
    );
};

const checkUserAccountDomain = (to, _, next) => {
    // Merge this with checkCapabilities and other ACL-related stuff
    // preferably using the ACL directive/module
    const { addAppError } = useStoreRoot();
    const rootStore = useStoreRoot();
    const userEmail = rootStore.user.email;
    if (!userEmail) {
        next('/organizations');
        return;
    }
    const userDomain = userEmail.split('@')[1];
    if (!to.meta.domain.includes(userDomain)) {
        addAppError({ error: new Error(`Access to ${to.meta.title} not allowed`), action: null });
        next({
            name: 'dashboard',
            params: { organization: rootStore.organization.get('name') },
        });
        return;
    }
    next();
};

// Redirect to the lists page if the organization disallows new lists
const shouldDisallowUploadToList = (to, from, next) => {
    const rules = vueStore.getters?.organization.getIn(['settings', 'import', 'rules']);
    if (!rules || rules === '') {
        next();
        return;
    }

    let disallowUpload = false;
    try {
        const orgImportRules = JSON.parse(rules);
        if (Object.hasOwn(orgImportRules[to.params.id] ?? {}, 'disallowSubscriberUpload')) {
            disallowUpload = orgImportRules[to.params.id].disallowSubscriberUpload;
        } else {
            disallowUpload = orgImportRules?.global?.disallowSubscriberUpload;
        }
    } catch (ex) {
        next();
        return;
    }

    if (disallowUpload) {
        next({
            name: 'lists',
            params: { organization: vueStore.getters?.organization.get('name') },
        });
        return;
    }
    next();
};

// Redirect to the native editor if the activity has a template and the template is v5
const redirectToEditor = async function (to, _, next) {
    /*
    # SMS/Email/Web activity
    ----
    If the activity has a template:
        * if template is v5 redirect to the v5 native editor.
        * else redirect to the OG editor.
    If the activity has no template, redirect to the OG editor.
    */
    const rootStore = useStoreRoot();
    const { fetchActivity } = useStoreActivity();
    const { fetchTemplate } = useStoreTemplate();

    const activity = await fetchActivity({
        organization: rootStore.organization,
        activityId: to.params.id,
        partitionIds: rootStore.activePartitionIds,
    })
        .first()
        .toPromise();

    let smarttemplate;
    let majorVersion = 0;
    if (activity.templateUrl) {
        if (activity.templateUrl.includes('v5_')) {
            majorVersion = 5;
        } else {
            smarttemplate = await fetchTemplate({
                organization: rootStore.organization,
                templateUrl: activity.templateUrl,
            });
            majorVersion = smarttemplate.majorVersion;
        }
    }

    // OG activity edit if template version is v1 or v2
    if (activity.templateUrl && majorVersion < V5_TEMPLATES_MAJOR_VERSION) {
        next();
    } else if (!activity.templateUrl) {
        next();
    } else {
        next({ name: 'activity-edit-native', params: to.params });
    }
};

// Redirect to theme list if theme is locked, with a warning
const redirectLockedTheme = async function (to, _, next) {
    /*
    # Themes
    ----
    Should be disabled both UI list as well as direct URL access, invalid theme IDs should also throw error
    */

    const rootStore = useStoreRoot();
    const { fetchTheme } = useStoreActivityTheme();
    const themeId = parseInt(to.params.id, 10);

    if (!isNaN(themeId)) {
        try {
            const theme = await fetchTheme({
                organization: rootStore.organization,
                themeId,
            });
            if (theme.config?.locked) {
                next({
                    name: 'settings-template-themes',
                    params: to.params,
                    query: {
                        themeLocked: true,
                    },
                });
            }
        } catch {
            next({
                name: 'settings-template-themes',
                params: to.params,
                query: {
                    failedToLoad: true,
                },
            });
        }
    }
    next();
};

export const getRouter = () => {
    if (router) {
        return router;
    }

    // Setup routes
    router = new VueRouter({
        mode: 'history',
        scrollBehavior: (to, from, savedPosition) => {
            if (to.hash) {
                return {
                    selector: to.hash,
                };
            } else if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        },
        routes: [
            { path: '/', redirect: '/organizations' },
            {
                name: 'organizations',
                path: '/organizations',
                component: () => import(/* webpackPrefetch: true */ './components/pages/OrganizationsPage.vue'),
                meta: {
                    title: 'Organizations',
                },
            },
            {
                name: 'login',
                path: '/login',
                component: () => import(/* webpackPrefetch: true */ './components/pages/LoginPage.vue'),
                beforeEnter: (to, from, next) => {
                    if (SessionStore.hasValidSession()) {
                        next({ name: 'organizations' });
                    } else {
                        next();
                    }
                },
                meta: {
                    title: 'Login',
                },
            },
            {
                name: 'signup',
                path: '/signup',
                component: () => import('./components/pages/SignupPage.vue'),
                meta: {
                    title: 'Signup',
                },
            },
            {
                name: 'passwordreset',
                path: '/passwordreset',
                component: () => import('./components/pages/PasswordResetPage.vue'),
                meta: {
                    title: 'Password Reset',
                },
            },
            {
                name: 'account',
                path: '/account',
                component: () => import('./components/modals/ViewAccount.vue'),
                meta: {
                    title: 'Account',
                },
            },
            {
                name: 'franchisee-page',
                path: '/:organization/franchisee/:franchiseeEmail/',
                beforeEnter: checkDominosFranchiseeAccess,
                component: () => import('./dominos/components/FranchiseeShell.vue'),
                children: [
                    {
                        name: 'franchisee-activityedit',
                        path: 'edit/:id',
                        component: () =>
                            import(/* webpackPrefetch: true */ './dominos/components/FranchiseeActivityEdit.vue'),
                        meta: {
                            title: 'Activity edit',
                        },
                    },
                ],
                meta: {
                    title: 'Franchisee Portal',
                },
            },
            {
                path: '/:organization',
                beforeEnter: checkOrg,
                component: () => import(/* webpackPreload: true */ './components/OrgShell.vue'),
                children: [
                    {
                        name: 'dominos-franchisees',
                        path: 'franchisees',
                        component: () => import('./dominos/components/FranchiseesPage.vue'),
                        meta: {
                            title: 'Franchisees',
                        },
                    },
                    {
                        path: 'franchisees/edit',
                        component: () => import('./dominos/components/FranchiseesEdit.vue'),
                        meta: {
                            title: 'Edit Franchisees',
                        },
                    },
                    { path: '', redirect: { name: 'dashboard' } },

                    {
                        name: 'assets',
                        path: 'assets',
                        component: () => import(/* webpackPrefetch: true */ './components/pages/AssetsPage.vue'),
                        meta: {
                            title: 'Assets',
                        },
                    },
                    {
                        name: 'asset-new',
                        path: 'assets/new',
                        component: () => import('./components/modals/SetupAsset.vue'),
                        meta: {
                            title: 'New asset',
                        },
                    },
                    {
                        name: 'asset-view',
                        path: 'assets/view/:id',
                        component: () => import('./components/modals/SetupAsset.vue'),
                        meta: {
                            title: 'Asset setup',
                        },
                    },
                    {
                        name: 'assets-import',
                        path: 'assets/import',
                        component: () => import('./components/modals/ImportAsset.vue'),
                        meta: {
                            title: 'Import assets',
                        },
                    },
                    {
                        name: 'assets-partitions',
                        path: 'assets/partition/:id',
                        component: () => import('./components/modals/AssetPartitions.vue'),
                        meta: {
                            title: 'Partition Selector',
                        },
                    },
                    {
                        name: 'assets-import-dominos',
                        path: 'assets/importdominos',
                        component: () => import('./components/modals/ImportDominosAsset.vue'),
                        meta: {
                            title: "Manage Domino's asset sets",
                        },
                    },
                    {
                        name: 'assets-import-dominos-setup',
                        path: 'assets/importdominos/:id',
                        alias: 'assets/importdominos/new',
                        component: () => import('./components/modals/ImportDominosAssetSet.vue'),
                        meta: {
                            title: "Domino's asset setup",
                        },
                    },
                    {
                        name: 'dashboard',
                        path: 'dashboard',
                        component: () => import(/* webpackPrefetch: true */ './components/pages/DashboardPage.vue'),
                        meta: {
                            title: 'Dashboard',
                        },
                    },
                    {
                        name: 'campaign-activity-setup',
                        path: 'campaign/:campaignId/view/:id',
                        component: () => import(/* webpackPrefetch: true */ './components/modals/SetupActivity.vue'),
                        meta: {
                            title: 'Activity setup',
                        },
                    },
                    {
                        name: 'campaign-activity-new',
                        path: 'campaign/:campaignId/new',
                        component: () => import(/* webpackPrefetch: true */ './components/modals/SetupActivity.vue'),
                        meta: {
                            title: 'New activity',
                        },
                    },
                    {
                        name: 'activity-setup',
                        path: 'activity/view/:id',
                        component: () => import(/* webpackPrefetch: true */ './components/modals/SetupActivity.vue'),
                        meta: {
                            title: 'Activity setup',
                        },
                    },
                    {
                        name: 'activity-new',
                        path: 'activity/new',
                        component: () => import(/* webpackPrefetch: true */ './components/modals/SetupActivity.vue'),
                        meta: {
                            title: 'New activity',
                        },
                    },
                    {
                        name: 'activity-edit',
                        path: 'activity/edit/:id',
                        alias: 'campaign/:campaignId/edit/:id',
                        beforeEnter: redirectToEditor,
                        component: () => import(/* webpackPrefetch: true */ './components/modals/EditActivity.vue'),
                        meta: {
                            title: 'Activity edit',
                        },
                    },

                    {
                        name: 'activity-edit-native',
                        path: 'activity/editor/:id',
                        alias: ['campaign/:campaignId/editor/:id', 'activity/edit/:id'],
                        component: () => import(/* webpackPrefetch: true */ './components/modals/Editor.vue'),
                        meta: {
                            title: 'Activity edit',
                            features: ['nativeeditor'],
                        },
                    },
                    {
                        name: 'activity-emailreport',
                        path: 'activity/emailreport/:id',
                        alias: 'campaign/:campaignId/emailreport/:id',
                        component: () =>
                            import(/* webpackPrefetch: true */ './components/modals/EmailreportActivity.vue'),
                        meta: {
                            title: 'Email report',
                        },
                    },
                    {
                        name: 'activity-smsreport',
                        path: 'activity/smsreport/:id',
                        alias: 'campaign/:campaignId/smsreport/:id',
                        component: () => import('./components/modals/SmsreportActivity.vue'),
                        meta: {
                            title: 'SMS report',
                        },
                    },
                    {
                        name: 'activity-whatsappreport',
                        path: 'activity/whatsappreport/:id',
                        alias: 'campaign/:campaignId/whatsappreport/:id',
                        component: () => import('./components/modals/WhatsappreportActivity.vue'),
                        meta: {
                            title: 'Whatsapp report',
                        },
                    },
                    {
                        name: 'activity-webreport',
                        path: 'activity/webreport/:id',
                        alias: 'campaign/:campaignId/webreport/:id',
                        component: () => import('./components/modals/WebreportActivity.vue'),
                        meta: {
                            title: 'Web report',
                        },
                    },
                    {
                        name: 'activity-linereport',
                        path: 'activity/linereport/:id',
                        alias: 'campaign/:campaignId/linereport/:id',
                        component: () => import('./components/modals/LinereportActivity.vue'),
                        meta: {
                            title: 'Line report',
                        },
                    },
                    {
                        name: 'activity-displayadreport',
                        path: 'activity/displayadreport/:id',
                        alias: 'campaign/:campaignId/adreport/:id',
                        component: () => import('./components/modals/DisplayadreportActivity.vue'),
                        meta: {
                            title: 'Display Ad report',
                        },
                    },
                    {
                        name: 'activity-partitions',
                        path: 'activity/partition/:id',
                        alias: 'campaign/:campaignId/partition/:id',
                        component: () => import('./components/modals/ActivityPartitions.vue'),
                        meta: {
                            title: 'Partition Selector',
                        },
                    },
                    {
                        name: 'activity-viewerror',
                        path: 'activity/viewerror/:id',
                        alias: 'campaign/:campaignId/viewerror/:id',
                        component: () => import('./components/modals/ViewerrorActivity.vue'),
                        meta: {
                            title: 'Activity errors',
                        },
                    },
                    {
                        name: 'campaigns-new',
                        path: 'campaigns/new',
                        component: () => import('./components/modals/SetupCampaign.vue'),
                        meta: {
                            title: 'New campaign',
                        },
                    },
                    {
                        name: 'campaigns-partitions',
                        path: 'campaigns/partition/:id',
                        component: () => import('./components/modals/CampaignPartitions.vue'),
                        meta: {
                            title: 'Partition Selector',
                        },
                    },
                    {
                        name: 'campaigns-setup',
                        path: 'campaigns/:id',
                        component: () => import('./components/modals/SetupCampaign.vue'),
                        meta: {
                            title: 'Campaign setup',
                        },
                    },
                    {
                        name: 'campaign',
                        path: 'campaign/:campaignId',
                        component: () => import(/* webpackPrefetch: true */ './components/pages/CampaignPage.vue'),
                        meta: {
                            title: 'Campaign',
                        },
                    },
                    {
                        name: 'campaigns',
                        path: 'campaigns',
                        component: () => import(/* webpackPrefetch: true */ './components/pages/CampaignsPage.vue'),
                        meta: {
                            title: 'Campaigns',
                        },
                    },
                    {
                        name: 'lists',
                        path: 'subscribers',
                        component: () => import(/* webpackPrefetch: true */ './components/pages/SubscribersPage.vue'),
                        meta: {
                            title: 'Subscribers',
                        },
                    },
                    {
                        name: 'lists-new',
                        path: 'subscribers/new',
                        component: () => import('./components/modals/SetupList.vue'),
                        beforeEnter: (to, from, next) => {
                            const rules = vueStore.getters?.organization.getIn(['settings', 'import', 'rules']);
                            if (typeof rules === 'undefined' || !rules || rules === '') {
                                next();
                                return;
                            }

                            const orgImportRules = JSON.parse(rules);
                            if (orgImportRules && orgImportRules?.global?.disallowNewLists) {
                                next({
                                    name: 'lists',
                                    params: { organization: vueStore.getters?.organization.get('name') },
                                });
                                return;
                            }
                            next();
                        },
                        meta: {
                            title: 'New list',
                        },
                    },
                    {
                        name: 'lists-setup',
                        path: 'subscribers/view/:id',
                        component: () => import('./components/modals/SetupList.vue'),
                        meta: {
                            title: 'List setup',
                        },
                    },
                    {
                        name: 'lists-import',
                        path: 'subscribers/import/:id?',
                        component: () => import('./components/modals/ImportList.vue'),
                        beforeEnter: shouldDisallowUploadToList,
                        meta: {
                            title: 'Import list',
                        },
                    },
                    {
                        name: 'lists-add-subscriber',
                        path: 'subscribers/add/:id',
                        component: () => import('./components/modals/AddList.vue'),
                        beforeEnter: shouldDisallowUploadToList,
                        meta: {
                            title: 'Add subscriber',
                        },
                    },
                    {
                        name: 'lists-report',
                        path: 'subscribers/report/:id',
                        component: () => import('./components/modals/ReportList.vue'),
                        meta: {
                            title: 'List report',
                        },
                    },
                    {
                        name: 'export-subscribers',
                        path: 'export',
                        component: () => import('./components/modals/ExportSubscriber.vue'),
                        meta: {
                            title: 'Export subscribers',
                        },
                    },
                    {
                        name: 'list-partitions',
                        path: 'subscribers/partition/:id',
                        component: () => import('./components/modals/ListPartitions.vue'),
                        meta: {
                            title: 'Partition Selector',
                        },
                    },
                    {
                        name: 'search-results',
                        path: 'search',
                        component: () => import('./components/modals/ViewSearch.vue'),
                        meta: {
                            title: 'Search results',
                        },
                    },
                    {
                        path: 'admin-panel',
                        component: () => import('./components/admin-panel/AdminPanel.vue'),
                        meta: {
                            title: 'Admin',
                        },
                    },
                    {
                        path: 'subscribers/:id',
                        component: () => import('./components/modals/EditSubscriber.vue'),
                        meta: {
                            title: 'Subscriber profile',
                        },
                    },
                    {
                        path: 'settings',
                        component: () => import('./components/pages/SettingsPage.vue'),
                        meta: {
                            title: 'Settings',
                        },
                        children: [
                            { path: '', redirect: 'profile' },
                            {
                                name: 'settings-organization',
                                path: 'organization',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsOrganization.vue'
                                    ),
                                meta: {
                                    title: 'Organization',
                                },
                            },
                            {
                                name: 'settings-profile',
                                path: 'profile',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsTestProfile.vue'
                                    ),
                                meta: {
                                    title: 'Test Profile',
                                },
                            },
                            {
                                name: 'settings-security',
                                path: 'security',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsSecurity.vue'
                                    ),
                                meta: {
                                    role: ['admin'],
                                    title: 'Security',
                                },
                            },
                            {
                                name: 'settings-template-themes',
                                path: 'themes',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsTemplateThemes.vue'
                                    ),
                                meta: {
                                    title: 'Template themes',
                                },
                            },
                            {
                                name: 'settings-template-theme-setup',
                                path: 'themes/:id',
                                alias: 'themes/new',
                                beforeEnter: redirectLockedTheme,
                                component: () => import('./components/pages/settings/SettingsTemplateTheme.vue'),
                                meta: {
                                    title: 'Template theme',
                                },
                            },
                            {
                                name: 'settings-templates',
                                path: 'templates',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsTemplates.vue'
                                    ),
                                meta: {
                                    title: 'Templates',
                                },
                            },
                            {
                                name: 'settings-tags',
                                path: 'tags',
                                component: () =>
                                    import(/* webpackPrefetch: true */ './components/pages/settings/SettingsTags.vue'),
                                meta: {
                                    title: 'Tags',
                                },
                            },
                            {
                                name: 'settings-tag-setup',
                                path: 'tags/:id',
                                alias: 'tags/new',
                                component: () => import('./components/modals/SetupTag.vue'),
                                meta: {
                                    title: 'New tag',
                                },
                            },
                            {
                                name: 'settings-profilefields',
                                path: 'profilefields',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsProfileFields.vue'
                                    ),
                                meta: {
                                    title: 'Profile fields',
                                },
                            },
                            {
                                name: 'settings-profilefield-setup',
                                path: 'profilefields/:field',
                                alias: 'profilefields/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupProfileField.vue'),
                                meta: {
                                    title: 'New profile field configuration',
                                    capabilities: ['database-manage'],
                                },
                            },
                            {
                                name: 'settings-customfields',
                                path: 'customfields',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsCustomFields.vue'
                                    ),
                                meta: {
                                    title: 'Custom fields',
                                },
                            },
                            {
                                name: 'settings-customfield-setup',
                                path: 'customfields/:type/:field',
                                alias: 'customfields/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupCustomField.vue'),
                                meta: {
                                    title: 'New custom field',
                                    capabilities: ['database-manage'],
                                },
                            },
                            {
                                name: 'settings-customfields-import',
                                path: 'customfields/import',
                                component: () => import('./components/modals/ImportCustomField.vue'),
                                beforeEnter: checkCapabilities,
                                meta: {
                                    title: 'Import custom fields',
                                    capabilities: ['database-manage'],
                                },
                            },
                            {
                                name: 'settings-partitions',
                                path: 'partitions',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsPartitions.vue'
                                    ),
                                meta: {
                                    title: 'Partitions',
                                },
                            },
                            {
                                name: 'settings-partitions-import',
                                path: 'partitions/import',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/ImportPartition.vue'),
                                meta: {
                                    capabilities: ['admin'],
                                    title: 'Import partitions',
                                },
                            },
                            {
                                name: 'settings-partition-setup',
                                path: 'partitions/:id',
                                alias: 'partitions/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupPartition.vue'),
                                meta: {
                                    title: 'New partition',
                                    capabilities: ['admin'],
                                },
                            },
                            {
                                name: 'settings-access',
                                path: 'access',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsAccess.vue'
                                    ),
                                meta: {
                                    capabilities: ['admin'],
                                    title: 'Security',
                                },
                            },
                            {
                                name: 'settings-users',
                                path: 'users',
                                component: () =>
                                    import(/* webpackPrefetch: true */ './components/pages/settings/SettingsUsers.vue'),
                                meta: {
                                    capabilities: ['admin'],
                                    title: 'Users',
                                },
                            },

                            {
                                name: 'settings-orgrules',
                                path: 'rules',
                                beforeEnter: checkUserAccountDomain,
                                component: () =>
                                    import(/* webpackPrefetch: true */ './components/pages/settings/SettingsRules.vue'),
                                meta: {
                                    role: ['admin'],
                                    title: 'Organization Rules',
                                    domain: ['taguchi.com.au', 'taguchimail.com', 'taguchi.com.sg'],
                                },
                            },
                            {
                                name: 'settings-user-setup',
                                path: 'users/:id',
                                alias: 'users/new',
                                component: () => import('./components/modals/SetupUser.vue'),
                                meta: {
                                    role: ['admin'],
                                    title: 'Setup user',
                                },
                            },
                            {
                                name: 'settings-issuer-setup',
                                path: 'issuer/:id',
                                alias: 'issuer/new',
                                component: () => import('./components/modals/SetupIssuer.vue'),
                                meta: {
                                    capabilities: ['admin'],
                                    title: 'Setup Single Sign-on issuer',
                                },
                            },
                            {
                                name: 'settings-domains',
                                path: 'domains',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsDomains.vue'
                                    ),
                                meta: {
                                    title: 'Domains',
                                },
                            },
                            {
                                name: 'settings-domains-tracking-setup',
                                path: 'trackingdomains/:domain',
                                alias: 'trackingdomains/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupTrackingDomain.vue'),
                                meta: {
                                    title: 'New tracking domain',
                                    capabilities: ['admin'],
                                },
                            },
                            {
                                name: 'settings-domains-sending-setup',
                                path: 'sendingdomains/:domain',
                                alias: 'sendingdomains/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupSendingDomain.vue'),
                                meta: {
                                    title: 'New sending domain',
                                    capabilities: ['admin'],
                                },
                            },
                            {
                                name: 'settings-advanced',
                                path: 'advanced',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsAdvanced.vue'
                                    ),
                                meta: {
                                    title: 'Advanced',
                                },
                            },
                            {
                                name: 'settings-credentials',
                                path: 'credentials',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsCredentials.vue'
                                    ),
                                meta: {
                                    capabilities: ['admin'],
                                    title: 'Credentials',
                                },
                            },
                            {
                                name: 'credential-new',
                                path: 'credential/:id',
                                alias: 'credential/new',
                                beforeEnter: checkCapabilities,
                                component: () => import('./components/modals/SetupCredential.vue'),
                                meta: {
                                    capabilities: ['admin'],
                                    features: ['integrations'],
                                    title: 'New credential',
                                },
                            },
                            {
                                name: 'settings-integrations',
                                path: 'integrations',
                                component: () =>
                                    import(
                                        /* webpackPrefetch: true */ './components/pages/settings/SettingsIntegrations.vue'
                                    ),
                                meta: {
                                    capabilities: ['integration-manage'],
                                    features: ['integrations'],
                                    title: 'Integrations',
                                },
                            },
                            {
                                name: 'integration-new',
                                path: 'integrations/:id',
                                alias: 'integrations/new',
                                component: () => import('./components/modals/SetupIntegration.vue'),
                                beforeEnter: checkCapabilities,
                                meta: {
                                    capabilities: ['integration-manage'],
                                    features: ['integrations'],
                                    title: 'New Integration',
                                },
                            },
                        ],
                    },
                ],
            },
            { path: '*', redirect: '/' },
        ],
    });

    // Global guards
    // Check that the user is logged-in prior to navigating to *any* page
    router.beforeEach(prepareRoute);
    router.beforeResolve((to, _, next) => {
        // Update document.title if meta.title exists
        let idStr = '';
        if (to.params.id) {
            idStr = `(ID ${to.params.id})`;
        }
        document.title = `${to.params.organization ?? 'Taguchi®'} - ${to.meta?.title} ${
            to.params.campaignId ?? ''
        } ${idStr}`;
        next();
    });
    return router;
};
