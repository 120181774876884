import Rx from 'rx';
import RxSocketSubject from '../RxSocketSubject/rx-socket-subject';
import SessionStore from '../SessionStore';
import AppConfig from '../AppConfig';
import { v1 as uuidv1 } from 'uuid';
import { default as AppErrors, Types } from '../AppErrors';
import { ILoginDetails } from '../types/ILoginDetails';

import { Map as ImmMap, Record } from 'immutable';

let _wsObservable: any;
let _failedConnects = 0;
let _failedTimeout = -1;

let _socketSubscription: Rx.IDisposable | null = null;
let _heartbeatSubscription: Rx.IDisposable | null = null;

const _errorSubject = new Rx.Subject();

interface IWSCloseEventTarget extends EventTarget {
    readyState: number;
}
interface IWSCloseEvent extends CloseEvent {
    target: IWSCloseEventTarget;
}
interface ISessionCmdResponse {
    data: string;
}

export interface ISessionCmdResponseData {
    error: string;
    result: {
        [key: string]: any;
    };
}

export default class Session {
    public static disconnect() {
        Session.disposeSubscriptions();
        if (_wsObservable) {
            _wsObservable.onCompleted();
            _wsObservable = null;
        }
    }

    public static errorObservable() {
        return _errorSubject.asObservable();
    }

    public static createSession(params: Record<ILoginDetails>) {
        return Session.sendCommand('createSession', params).first();
    }

    public static renewSession(params: ImmMap<string, string>) {
        return Session.sendCommand('renewSession', params).first();
    }

    public static tryRenewSession() {
        return Session.sendCommand('renewSession', ImmMap<string, string>()).first();
    }

    public static terminateSession(params: ImmMap<string, string>) {
        return Session.sendCommand('terminateSession', params).first();
    }

    public static getUserLoginMethod(params: ImmMap<string, string>) {
        return Session.sendCommand('getUserLoginMethod', params).first();
    }

    public static createUser(params: ImmMap<string, string>) {
        return Session.sendCommand('createUser', params).first();
    }

    public static resetPassword(params: ImmMap<string, string | null>) {
        return Session.sendCommand('resetPassword', params).first();
    }

    public static generateOTP() {
        const parameters = SessionStore.getSessionId();
        if (!parameters) {
            throw new AppErrors(Types.InvalidSessionError, 'Session invalid. Unable to process 2FA request.');
        }
        return Session.sendCommand('generateOTP', parameters).first();
    }

    public static validateOTP(otpSecret: string, otpCode: string) {
        let parameters = SessionStore.getSessionId();
        if (!parameters) {
            throw new AppErrors(Types.InvalidSessionError, 'Session invalid. Unable to process 2FA request.');
        }
        parameters = parameters.set('otpSecret', otpSecret);
        parameters = parameters.set('otpCode', otpCode);
        return Session.sendCommand('validateOTP', parameters).first();
    }

    public static enableOTP(otpSecret: string) {
        let parameters = SessionStore.getSessionId();
        if (!parameters) {
            throw new AppErrors(Types.InvalidSessionError, 'Session invalid. Unable to process 2FA request.');
        }
        parameters = parameters.set('otpSecret', otpSecret);
        return Session.sendCommand('enableOTP', parameters).first();
    }

    public static disableOTP() {
        const parameters = SessionStore.getSessionId();
        if (!parameters) {
            throw new AppErrors(Types.InvalidSessionError, 'Session invalid. Unable to process 2FA request.');
        }
        return Session.sendCommand('disableOTP', parameters).first();
    }

    public static getUserProfile() {
        const parameters = SessionStore.getSessionId();
        if (!parameters) {
            throw new AppErrors(Types.InvalidSessionError, 'Session invalid. Unable to process user request.');
        }
        return Session.sendCommand('getUserProfile', parameters)
            .do(() => Session.setupHeartbeat())
            .first();
    }

    public static getUserHistory(params: ImmMap<string, string>) {
        return Session.sendCommand('getUserHistory', params).first();
    }

    public static getUserNotifications(params: ImmMap<string, string>) {
        return Session.sendCommand('getUserNotifications', params, false).filter(
            (response: ISessionCmdResponseData) => response.result && response.result.notifications
        );
    }

    public static getUserTasks(params: ImmMap<string, string>) {
        return Session.sendCommand('getUserTasks', params, false).filter(
            (response: ISessionCmdResponseData) => response.result && response.result.tasks
        );
    }

    public static updateUserSettings(params: ImmMap<string, string>) {
        return Session.sendCommand('updateUserSettings', params).first();
    }

    public static getOrganizationAccess(params: ImmMap<string, string>) {
        return Session.sendCommand('getOrganizationAccess', params).first();
    }

    public static updateOrganizationAccess(params: ImmMap<string, string>) {
        return Session.sendCommand('updateOrganizationAccess', params).first();
    }

    public static grantOrganizationAccess(params: ImmMap<string, string>) {
        return Session.sendCommand('grantOrganizationAccess', params).first();
    }

    public static revokeOrganizationAccess(params: ImmMap<string, string>) {
        return Session.sendCommand('revokeOrganizationAccess', params).first();
    }

    public static updateOrganizationTheme(params: ImmMap<string, string>) {
        return Session.sendCommand('updateOrganizationTheme', params).first();
    }

    public static updateOrganizationSettings(params: ImmMap<string, string>) {
        return Session.sendCommand('updateOrganizationSettings', params).first();
    }

    public static getOrganizationToken(params: ImmMap<string, string>) {
        return Session.sendCommand('getOrganizationToken', params).first();
    }

    public static addOrganizationIssuer(params: ImmMap<string, string>) {
        return Session.sendCommand('addOrganizationIssuer', params).first();
    }

    public static disableOrganizationIssuer(params: ImmMap<string, string>) {
        return Session.sendCommand('disableOrganizationIssuer', params).first();
    }

    private static subscribe() {
        // Maintain a subscription to keep socket alive, so users
        // are free to manage their own subscriptions.
        // Keep this here for now but we might move it somewhere in
        // the future (e.g. renewSession task, or user notification
        // handling task).
        Session.disposeSubscriptions();
        _socketSubscription = _wsObservable.subscribe();
        return _socketSubscription;
    }

    private static setupHeartbeat() {
        if (_heartbeatSubscription) {
            return;
        }
        if (!SessionStore.getSessionId()) {
            return;
        }
        _heartbeatSubscription = Rx.Scheduler.default.schedulePeriodic(null, 60 * 1000 /* 1 min */, () => {
            const session = SessionStore.getSessionId();
            if (session) {
                Session.renewSession(session).subscribeOnError(() => {
                    SessionStore.reset();
                });
            }
        });
    }

    private static disposeSubscriptions() {
        if (_socketSubscription) {
            _socketSubscription.dispose();
            _socketSubscription = null;
        }

        if (_heartbeatSubscription) {
            _heartbeatSubscription.dispose();
            _heartbeatSubscription = null;
        }
    }

    private static establishSession(wsUrl: string) {
        return Rx.Observable.fromPromise(
            new Promise((resolve, reject) => {
                try {
                    fetch(AppConfig.Connection.sessionCookieUrl, { method: 'GET', credentials: 'include' }).then(
                        (response) => {
                            if (!response.ok) {
                                throw new Error(
                                    `Session establishment failed (${response.status} - ${response.statusText}).`
                                );
                            }
                            for (const pair of response.headers.entries()) {
                                console.log(pair[0] + ': ' + pair[1]);
                            }
                            resolve(wsUrl);
                        },
                        (error) => {
                            console.error('Session.establishSession failed with rejection', error);
                            reject(error);
                        }
                    );
                } catch (error) {
                    console.error('Session.establishSession failed with exception', error);
                    reject(error);
                }
            })
        );
    }

    private static sendCommand(
        name: string,
        params: Record<ILoginDetails> | ImmMap<string, any>,
        filter = true
    ): Rx.Observable<ISessionCmdResponseData> {
        const socket = this.connect();
        if (!socket) {
            throw new AppErrors(Types.ApplicationError, `socket is ${socket}`);
        }

        const parameters = (params as ImmMap<string, any>).set('requestId', uuidv1());
        socket.onNext(
            JSON.stringify({
                command: name,
                parameters,
            })
        );

        const source = socket.flatMapLatest((cmdResponse: ISessionCmdResponse) => {
            const response = JSON.parse(cmdResponse.data);
            if (response.error) {
                throw new AppErrors(response.error);
            }
            return Rx.Observable.from([response]);
        });

        if (!filter) {
            return source;
        }

        return source
            .filter((response: ISessionCmdResponseData) => response.result.requestId === parameters.get('requestId'))
            .do((response: ISessionCmdResponseData) => delete response.result.requestId);
    }

    private static connect(force = false) {
        if (!force && _wsObservable) {
            return _wsObservable;
        }

        // Forcing a new connection, disconnect existing connection/subscriptions
        Session.disconnect();

        _wsObservable = RxSocketSubject.create(
            // FIXME: Safari bug -- need to GET the cookie-drop URL in order to set cookies, because WebSockets can't
            Session.establishSession(AppConfig.Connection.sessionUrl),
            Rx.Observer.create(() => {
                _failedConnects = 0;

                // This will check for heartbeat and initialise one if need be
                // Helpful in non-user initiated reconnects (e.g. connection closing, laptop resuming, etc..)
                Session.setupHeartbeat();

                if (SessionStore.hasValidSession()) {
                    Session.tryRenewSession().subscribeOnError(() => {
                        SessionStore.reset();
                    });
                }
            }),
            Rx.Observer.create((error: IWSCloseEvent) => {
                if (_wsObservable && error) {
                    _wsObservable.onCompleted();
                    _wsObservable = null;
                    Session.disposeSubscriptions();
                }

                // Only try to reconnect if we have a session id/token
                if (!SessionStore.hasValidSession()) {
                    // We don't have a session ID and token which means we are, most likely, in a login flow.
                    // 3 = connection is close or couldn't be opened.
                    if (error && error.target && error.target.readyState === 3) {
                        throw new Error(
                            'Unable to connect to Taguchi. Please ensure your IT have whitelisted our domains. Contact Taguchi support for info.'
                        );
                    }
                    throw new Error('An error occurred while processing your request. Please contact Taguchi support.');
                }

                _failedConnects += 1;
                if (_failedConnects >= AppConfig.Connection.maxRetries) {
                    if (_failedTimeout) {
                        window.clearTimeout(_failedTimeout);
                        _failedTimeout = -1;
                        _failedConnects = 0;
                    }
                    const appError = new AppErrors(
                        Types.WSSessionConnectionError,
                        'Connection to Taguchi lost. Please reload the page to reconnect.'
                    );
                    _errorSubject.onNext({
                        action: {
                            method: () => window.location.reload(),
                            text: 'Reload page',
                        },
                        appError,
                        type: Types.WSSessionConnectionError,
                    });
                    throw error;
                }

                if (_failedConnects === AppConfig.Connection.notifyFailedRetries) {
                    // Notify user that we're having problems reconnecting
                    _errorSubject.onNext({
                        action: {
                            method: () => window.location.reload(),
                            text: 'Reload page',
                        },
                        error: new AppErrors(
                            Types.WSSessionConnectionRetryError,
                            'Connection to Taguchi lost. Please reload the page to reconnect.'
                        ),
                        type: Types.WSSessionConnectionRetryError,
                    });
                }

                // Reconnect on error with backoff.
                _failedTimeout = window.setTimeout(
                    () => Session.connect(true),
                    Math.round((Math.pow(2, _failedConnects) - 1) / 2) * 1000
                );
            }),
            Rx.Observer.create((error) => {
                console.info('Closing session connection..', error);
            })
        );
        Session.subscribe();
        return _wsObservable;
    }
}
