import { isKeyed } from 'immutable';
import AppServer from './api/AppServer';
import BulkWriteSubscriberCommand from './api/BulkWriteSubscriberCommand';
import UpdateListStatsCommand from './api/UpdateListStatsCommand';
import RequestExportCommand from './api/RequestExportCommand';
import WriteCommand from './api/WriteCommand';
import UpdateContentBlockTargetCommand from './api/UpdateContentBlockTargetCommand';
import AnonymizeSubscriberCommand from './api/AnonymizeSubscriberCommand';
import {
    OrganizationAddTrackingDomainCommand,
    OrganizationRemoveTrackingDomainCommand,
    OrganizationAddSendingDomainCommand,
    OrganizationRemoveSendingDomainCommand,
} from './api/OrganizationConfigCommands';
import { ResourceID, ResourceType } from './AppTypes';
import { UserOrganizationProfile } from './types/IUser';
import WorkflowCommand, {
    WorkflowAction,
    IWorkflowCommentData,
    IWorkflowProofData,
    IWorkflowApproveData,
    IWorkflowQueueScheduleData,
    IWorkflowData,
    IWorkflowAddParticipants,
    IWorkflowUpdateThrottleData,
    IWorkflowGenerateProofMatrixData,
} from './api/WorkflowCommand';

export default class Actions {
    public static requestExportCommand(
        organization: UserOrganizationProfile,
        resourceType: ResourceType
    ): RequestExportCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create request export command for org ${organization} and resource ${resourceType}`
            );
        }
        return new RequestExportCommand(resourceType, organization.get('organization') as number);
    }

    public static bulkWriteCommand(
        organization: UserOrganizationProfile,
        listId: ResourceID
    ): BulkWriteSubscriberCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create bulk write command for org ${organization} and list ID ${listId}`);
        }
        return new BulkWriteSubscriberCommand(listId, organization.get('organization') as number);
    }

    public static updateListStatsCommand(
        organization: UserOrganizationProfile,
        listId: ResourceID
    ): UpdateListStatsCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create update list stats command for org ${organization} and list ID ${listId}`);
        }
        return new UpdateListStatsCommand(listId, organization.get('organization') as number);
    }

    public static command<T>(organization: UserOrganizationProfile, resourceType: ResourceType): WriteCommand<T> {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create ${resourceType} command for org ${organization}`);
        }
        return new WriteCommand<T>(resourceType, organization.get('organization') as number);
    }

    public static workflowCommand(organization: UserOrganizationProfile, action: WorkflowAction) {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create workflow command ${action} for org ${organization}`);
        }
        const orgId = organization.get('organization');
        switch (action) {
            case WorkflowAction.Approve:
                return new WorkflowCommand<IWorkflowApproveData>(orgId, action);
            case WorkflowAction.Comment:
                return new WorkflowCommand<IWorkflowCommentData>(orgId, action);
            case WorkflowAction.Proof:
                return new WorkflowCommand<IWorkflowProofData>(orgId, action);
            case WorkflowAction.QueueSchedule:
                return new WorkflowCommand<IWorkflowQueueScheduleData>(orgId, action);
            case WorkflowAction.AddParticipants:
                return new WorkflowCommand<IWorkflowAddParticipants>(orgId, action);
            case WorkflowAction.UpdateThrottle:
                return new WorkflowCommand<IWorkflowUpdateThrottleData>(orgId, action);
            case WorkflowAction.GenerateProofMatrix:
                return new WorkflowCommand<IWorkflowGenerateProofMatrixData>(orgId, action);
            case WorkflowAction.Check:
            case WorkflowAction.RemoveParticipants:
            case WorkflowAction.QueueDeploy:
            case WorkflowAction.QueueCancel:
                return new WorkflowCommand<IWorkflowData>(organization.get('organization'), action);
            default:
                throw new Error(`Workflow action ${action} is not supported`);
        }
    }

    public static updateContentBlockTarget(
        organization: UserOrganizationProfile,
        contentBlockId: ResourceID
    ): UpdateContentBlockTargetCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create update content block target command for org ${organization} and list ID ${contentBlockId}`
            );
        }
        return new UpdateContentBlockTargetCommand(contentBlockId, organization.get('organization') as number);
    }

    public static anonymizeSubscriberCommand(
        organization: UserOrganizationProfile,
        subscriberId: ResourceID
    ): AnonymizeSubscriberCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create anonymize subscriber command for org ${organization} and subscriber ID ${subscriberId}`
            );
        }
        return new AnonymizeSubscriberCommand(subscriberId, organization.get('organization') as number);
    }

    public static addOrganizationTrackingDomainCommand(
        organization: UserOrganizationProfile,
        domain: string
    ): OrganizationAddTrackingDomainCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create add tracking domain command for org ${organization} and domain ${domain}`
            );
        }
        return new OrganizationAddTrackingDomainCommand(domain, organization.get('organization') as number);
    }

    public static removeOrganizationTrackingDomainCommand(
        organization: UserOrganizationProfile,
        domain: string
    ): OrganizationRemoveTrackingDomainCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create remove tracking domain command for org ${organization} and domain ${domain}`
            );
        }
        return new OrganizationRemoveTrackingDomainCommand(domain, organization.get('organization') as number);
    }

    public static addOrganizationSendingDomainCommand(
        organization: UserOrganizationProfile,
        domain: string,
        envfrom: string,
        selector: string
    ): OrganizationAddSendingDomainCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create add sending domain command for org ${organization} and domain ${domain}`);
        }
        return new OrganizationAddSendingDomainCommand(
            domain,
            envfrom,
            selector,
            organization.get('organization') as number
        );
    }

    public static removeOrganizationSendingDomainCommand(
        organization: UserOrganizationProfile,
        domain: string
    ): OrganizationRemoveSendingDomainCommand {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(
                `Unable to create remove sending domain command for org ${organization} and domain ${domain}`
            );
        }
        return new OrganizationRemoveSendingDomainCommand(domain, organization.get('organization') as number);
    }

    // FIXME any - convert AppServer to TS
    public static connection(organization: UserOrganizationProfile): any {
        if (typeof organization === 'undefined' || !isKeyed(organization)) {
            throw new Error(`Unable to create an Action connection for org ${organization}`);
        }
        return AppServer.connect(
            organization.get('hostname') as string,
            organization.get('organization') as string,
            organization.get('organization_id') as string
        );
    }
}
