import { ResourceID } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';
import Rx from 'rx';

interface IUpdateListStatsCommand extends ICommand {
    listId: ResourceID;
}

export interface IUpdateListStatsCommandResponse extends ICommandResponse {
    result: {
        data: {
            listId: ResourceID;
        };
        type: 'updateListStats';
    };
}

export default class UpdateListStatsCommand extends AbstractCommand<IUpdateListStatsCommand> {
    public readonly _listId: ResourceID;

    constructor(listId: ResourceID, organizationId: number) {
        super(organizationId, 'updateListStats');
        if (typeof listId === 'undefined') {
            throw new Error('List ID is required to update the stats');
        }
        this._listId = listId;
    }

    get listId(): number {
        return this._listId;
    }

    public toJSON(): IUpdateListStatsCommand {
        return Object.assign(
            {},
            {
                listId: this._listId,
                organizationId: this.organizationId,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'updateListStats' &&
            response.result.data &&
            response.result.data.listId === this._listId
        );
    }
}
