import { ResourceID } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';
import Rx from 'rx';

interface IUpdateContentBlockTargetCommand extends ICommand {
    contentBlockId: ResourceID;
}

export interface IUpdateContentBlockTargetCommandResponse extends ICommandResponse {
    result: {
        type: 'updateContentBlockTarget';
        data: {
            contentBlockId: ResourceID;
        };
    };
}

export default class UpdateContentBlockTarget extends AbstractCommand<IUpdateContentBlockTargetCommand> {
    public readonly _contentBlockId: ResourceID;

    constructor(contentBlockId: ResourceID, organizationId: number) {
        super(organizationId, 'updateContentBlockTarget');
        if (typeof contentBlockId === 'undefined') {
            throw new Error('ContentBlock ID is required to update the target');
        }
        this._contentBlockId = contentBlockId;
    }

    get contentBlockId(): number {
        return this._contentBlockId;
    }

    public toJSON(): IUpdateContentBlockTargetCommand {
        return Object.assign(
            {},
            {
                contentBlockId: this._contentBlockId,
                organizationId: this.organizationId,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'updateContentBlockTarget' &&
            response.result.data &&
            response.result.data.contentBlockId === this._contentBlockId
        );
    }
}
