import Rx from 'rx';
import { Map as ImmMap, fromJS } from 'immutable';
const storageKey = '_session';

type State = ImmMap<string, any>;

const defaultState: State = ImmMap({
    email: null,
    id: null,
    phone: null,
    sessionId: null,
    settings: null,
    tokens: null,
    savedEmail: null,
    redirectUri: null,
});

class SessionStore {
    public static getInstance(): SessionStore {
        return new SessionStore();
    }

    private static _instance: SessionStore;
    private state: State = defaultState;
    private readonly subject: Rx.Subject<State> = new Rx.BehaviorSubject(defaultState);

    public constructor() {
        if (!SessionStore._instance) {
            this.init();
            SessionStore._instance = this;
        }
        return SessionStore._instance;
    }

    /**
     * Returns the state observable
     *
     * Any changes to the state can be observed through the returned Observable.
     *
     * @returns {Observable} observable
     */
    public asObservable(): Rx.Observable<State> {
        return this.subject.asObservable();
    }

    /**
     * Stores the state stored in localStorage with the new state
     *
     * @param {ImmMap} state the state to store in localStorage
     * @returns {State} state the update stated
     */
    public save(): State {
        self.localStorage.setItem(storageKey, JSON.stringify(this.state));
        this.subject.onNext(this.state);
        return this.state;
    }

    /**
     * Returns the session value stored with the given key
     *
     * @param {string} key the key where the value is stored
     * @returns {any} newState the stored session value
     */
    public get(key: string): any {
        return this.state.get(key);
    }

    /**
     * Returns the user setting
     *
     * @param key setting name (e.g. testProfile, advanced, preferences)
     */
    public getSettings(keyPath: string[]): any {
        return this.state.getIn(['settings', ...keyPath], null);
    }

    /**
     * Updates the existing state with the new state
     *
     * @param {Record<string, any>} newState the new state to update the current state with
     * @returns {any} newState the new updated state
     */
    public update(newState: Record<string, any>): State {
        if (!newState) {
            return this.state;
        }
        this.state = this.state.merge(fromJS(newState) as Record<string, any>);
        return this.save();
    }

    /**
     * Resets the state values to default
     *
     * @param {boolean} removeStore true if remove the cache store
     * @returns {any} newState the new updated state
     */
    public reset(removeStore = true): State {
        const savedEmail = this.state.get('savedEmail');
        this.state.clear();
        this.state = defaultState;
        if (removeStore) {
            self.localStorage.removeItem(storageKey);
        }
        if (savedEmail) {
            this.update({ savedEmail });
        }
        // Notify observers?
        return this.state;
    }

    /**
     * Return the session tokens
     *
     * @return the session identifiers valid for the current session
     */
    public getSessionId(): ImmMap<string, string> | null {
        if (!this.get('sessionId')) {
            return null;
        } else {
            return ImmMap({
                sessionId: this.get('sessionId'),
            });
        }
    }

    /**
     * Checks if there's a valid session
     *
     * @returns {boolean} valid if session is valid or not
     */
    public hasValidSession(): boolean {
        return this.get('sessionId') !== null && this.get('id') !== null;
    }

    /**
     * Checks for any saved redirect uri, this is needed since MSAL will strip the original queries when returning to Taguchi post authentication
     *
     * @return {string} the saved uri
     */
    public getRedirectUri(): string | null {
        if (!this.get('redirectUri')) {
            return null;
        } else {
            return this.get('redirectUri');
        }
    }

    /**
     * Removes cached redirect uri, if any
     *
     * @return -
     */
    public deleteRedirectUri() {
        if (this.get('redirectUri')) {
            this.update({ redirectUri: null });
        }
    }

    /**
     * Initialises the session store; sends initial state to Observers
     */
    public init(): void {
        const storedItem = localStorage.getItem(storageKey);
        if (storedItem) {
            const storedState: State = JSON.parse(storedItem);
            if (storedState) {
                this.state = defaultState.merge(fromJS(storedState) as Record<string, any>);
            }
        }
        this.subject.onNext(this.state);
    }
}

const sessionStoreSingleton = SessionStore.getInstance();
export default sessionStoreSingleton;
