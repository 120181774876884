export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_ACTIVE_PARTITION_IDS = 'SET_ACTIVE_PARTITION_IDS';
export const SET_DISPLAYVIEW = 'SET_DISPLAYVIEW';
export const GET_DISPLAYVIEW = 'GET_DISPLAYVIEW';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION';
export const SET_USER_ORGANIZATION = 'SET_USER_ORGANIZATION';
export const SET_FILE_TO_IMPORT = 'SET_FILE_TO_IMPORT';
export const GET_FILE_TO_IMPORT = 'GET_FILE_TO_IMPORT';
export const GET_ORGANIZATION_TOKENS = ' GET_ORGANIZATION_TOKENS';
export const SET_ORGANIZATION_TOKENS = 'SET_ORGANIZATION_TOKENS';
export const FETCH_PARTITIONSETS = 'FETCH_PARTITIONSETS';
export const STORE_PARTITIONSETS = 'STORE_PARTITIONSETS';

/* Chart state mutations */
export const SET_CHART_DATE_RANGE = 'SET_CHART_DATE_RANGE';
export const GET_CHART_DATE_RANGE = 'GET_CHART_DATE_RANGE';

/* Page state mutations */
export const SET_RESOURCE_FILTER = 'SET_RESOURCE_FILTER';
export const GET_RESOURCE_FILTER = 'GET_RESOURCE_FILTER';
export const SET_RESOURCE_LIMIT = 'SET_RESOURCE_LIMIT';
export const GET_RESOURCE_LIMIT = 'GET_RESOURCE_LIMIT';
export const SET_RESOURCE_SORTBY = 'SET_RESOURCE_SORTBY';
export const GET_RESOURCE_SORTBY = 'GET_RESOURCE_SORTBY';
export const SET_ADDITIONAL_FILTERS = 'SET_ADDITIONAL_FILTERS';
export const GET_ADDITIONAL_FILTERS = 'GET_ADDITIONAL_FILTERS';
export const UPDATE_ADDITIONAL_FILTERS = 'UPDATE_ADDITIONAL_FILTERS';

/* Resource actions */
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const STORE_ITEMS = 'STORE_ITEMS';
export const SEARCH_BY_NAME = 'SEARCH_BY_NAME';
export const SEARCH_BY_ID = 'SEARCH_BY_ID';

/* Notification mutations */
export const TOGGLE_NOTIFICATION_PANE = 'TOGGLE_NOTIFICATION_PANE';
export const SET_NOTIFICATIONVIEW = 'SET_NOTIFICATIONVIEW';
export const TOGGLE_FILTER_PROOFS = 'TOGGLE_FILTER_PROOFS';
export const TOGGLE_FILTER_EXTRACTS = 'TOGGLE_FILTER_EXTRACTS';
export const TOGGLE_FILTER_CHECKS = 'TOGGLE_FILTER_CHECKS';
export const TOGGLE_FILTER_APPROVALS = 'TOGGLE_FILTER_APPROVALS';
export const TOGGLE_FILTER_SENDS = 'TOGGLE_FILTER_SENDS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS';
export const STORE_TASKS = 'STORE_TASKS';

/* Campaigns */
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';

/* Subscriber lists */
export const FETCH_LISTS = 'FETCH_LISTS';
export const SEARCH_LISTS = 'SEARCH_LISTS';
export const SET_LISTS = 'SET_LISTS';

/* Activities */
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_PERFSTATS = 'FETCH_PERFSTATS';
export const STORE_PERFSTATS = 'STORE_PERFSTATS';
export const SEARCH_ACTIVITIES = 'SEARCH_ACTIVITIES';
export const SET_ACTIVITIES = 'SET_ACTIVITIES';
export const STORE_ACTIVITY = 'STORE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const STORE_REVISION_CONTENT = 'STORE_ACTIVITY_REVISION_CONTENT';
export const UPDATE_REVISION_CONTENT = 'UPDATE_ACTIVITY_REVISION_CONTENT';

/** Activity Revision */
export const FETCH_REVISION = 'FETCH_REVISION';
export const FETCH_REVISIONS = 'FETCH_REVISIONS';
export const STORE_REVISION = 'STORE_REVISION';
export const STORE_REVISIONS = 'STORE_REVISIONS';

/* Activity Revision Configuration */
export const FETCH_CONFIGSTATS = 'FETCH_CONFIGSTATS';
export const STORE_CONFIGSTATS = 'STORE_CONFIGSTATS';

/* Content Block actions */
export const FETCH_CONTENTBLOCK = 'FETCH_CONTENTBLOCK';
export const FETCH_CONTENTBLOCKS = 'FETCH_CONTENTBLOCKS';
export const FETCH_CONTENTBLOCK_TEMPLATES = 'FETCH_CONTENTBLOCK_TEMPLATES';
export const SEARCH_CONTENTBLOCK = 'SEARCH_CONTENTBLOCK';
export const STORE_CONTENTBLOCK = 'STORE_CONTENTBLOCK';
export const STORE_CONTENTBLOCKS = 'STORE_CONTENTBLOCKS';
export const STORE_CONTENTBLOCKS_UPDATE = 'STORE_CONTENTBLOCKS_UPDATE';
export const STORE_CONTENTBLOCK_TEMPLATES = 'STORE_CONTENTBLOCK_TEMPLATES';
export const STORE_CONTENTBLOCK_DATA = 'STORE_CONTENTBLOCK_DATA';
export const STORE_CONTENTBLOCK_CONTENT = 'STORE_CONTENTBLOCK_CONTENT';
export const STORE_CONTENTBLOCK_STATUS = 'STORE_CONTENTBLOCK_STATUS';
export const REMOVE_CONTENTBLOCK = 'REMOVE_CONTENTBLOCK';

/* Destroy actions */
export const DESTROY = 'DESTROY';

/* Edit Activity modal */
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const STORE_TEMPLATE = 'STORE_TEMPLATE';
export const STORE_PROFILES = 'STORE_PROFILES';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const STORE_TEMPLATES = 'STORE_TEMPLATES';

/* Subscriber view */
export const FETCH_INTERACTIONS = 'FETCH_INTERACTIONS';
export const CLEAR_INTERACTIONS = 'CLEAR_INTERACTIONS';
export const FETCH_SUBSCRIBER = 'FETCH_SUBSCRIBER';
export const STORE_INTERACTIONS = 'STORE_INTERACTIONS';
export const STORE_INTERACTIONS_OFFSET = 'STORE_INTERACTIONS_OFFSET';
export const STORE_SUBSCRIBER = 'STORE_SUBSCRIBER';
export const UPDATE_SUBSCRIBER = 'UPDATE_SUBSCRIBER';
export const ANONYMIZE_SUBSCRIBER = 'ANONYMIZE_SUBSCRIBER';

/* Custom fields */
export const FETCH_CUSTOMFIELDS = 'FETCH_CUSTOMFIELDS';
export const STORE_CUSTOMFIELDS = 'STORE_CUSTOMFIELDS';
export const STORE_CUSTOMFIELD = 'STORE_CUSTOMFIELD';
export const STORE_CUSTOMFIELDS_STATUS = 'STORE_CUSTOMFIELDS_STATUS';
export const STORE_CUSTOMFIELDS_UPDATE = 'STORE_CUSTOMFIELDS_UPDATE';
export const SEARCH_CUSTOM_FIELDS = 'SEARCH_CUSTOM_FIELDS';

/* User */
export const GET_ORG_USER_ACCESS = 'GET_ORG_USER_ACCESS';
export const SET_ORG_USER_ACCESS = 'SET_ORG_USER_ACCESS';
export const GET_USER_PROFILES = 'GET_USER_PROFILES';
export const STORE_USER_PROFILES = 'STORE_USER_PROFILES';
export const GET_ORG_ISSUERS = 'GET_ORG_ISSUERS';
export const SET_ORG_ISSUERS = 'SET_ORG_ISSUERS';

/* Workflow */
export const FETCH_WORKFLOW_EVENTLOG = 'FETCH_WORKFLOW_EVENTLOG';
export const STORE_WORKFLOW_EVENTLOG = 'STORE_WORKFLOW_EVENTLOG';
export const FETCH_WORKFLOW_ROLES = 'FETCH_WORKFLOW_ROLES';
export const STORE_WORKFLOW_ROLES = 'STORE_WORKFLOW_ROLES';
export const STORE_WORKFLOW_STATE = 'STORE_WORKFLOW_STATE';

/* Domino's Import Asset modal */
export const STORE_ASSET_SET = 'STORE_ASSET_SET';
export const STORE_ASSET_SETS = 'STORE_ASSET_SETS';
export const STORE_ASSET_SET_DEAL = 'STORE_ASSET_SET_DEAL';
export const STORE_ASSET_SET_DEALS = 'STORE_ASSET_SET_DEALS';
export const REMOVE_ASSET_SET_DEAL = 'REMOVE_ASSET_SET_DEAL';
export const STORE_ASSET_SET_OFFER = 'STORE_ASSET_SET_OFFER';
export const STORE_ASSET_SET_OFFERS = 'STORE_ASSET_SET_OFFERS';
export const REMOVE_ASSET_SET_OFFER = 'REMOVE_ASSET_SET_OFFER';
export const STORE_ASSET_SET_ASSET = 'STORE_ASSET_SET_ASSET';
export const STORE_ASSET_SET_ASSETS = 'STORE_ASSET_SET_ASSETS';
export const REMOVE_ASSET_SET_ASSET = 'REMOVE_ASSET_SET_ASSET';

/* Tags */
export const SEARCH_TAGS = 'SEARCH_TAGS';

/* Organization config */
export const ADD_ORGANIZATION_TRACKING_DOMAIN = 'ADD_ORGANIZATION_TRACKING_DOMAIN';
export const ADD_ORGANIZATION_SENDING_DOMAIN = 'ADD_ORGANIZATION_SENDING_DOMAIN';
export const REMOVE_ORGANIZATION_TRACKING_DOMAIN = 'REMOVE_ORGANIZATION_TRACKING_DOMAIN';
export const REMOVE_ORGANIZATION_SENDING_DOMAIN = 'REMOVE_ORGANIZATION_SENDING_DOMAIN';

/* Partitions */
export const FETCH_PARTITIONS = 'FETCH_PARTITIONS';
export const STORE_PARTITIONS = 'STORE_PARTITIONS';
export const STORE_PARTITION = 'STORE_PARTITION';
export const STORE_PARTITIONS_STATUS = 'STORE_PARTITIONS_STATUS';
export const STORE_PARTITIONS_UPDATE = 'STORE_PARTITIONS_UPDATE';
export const SEARCH_PARTITIONS = 'SEARCH_PARTITIONS';
