import Rx from 'rx';
import { ResourceID } from '../AppTypes';
import { AbstractCommand, ICommand, ICommandResponse } from './Command';

interface IBulkWriteSubscriberCommand extends ICommand {
    listId: ResourceID;
    fields: ReadonlyArray<string>;
    data: ReadonlyArray<ReadonlyArray<string | number>>;
    sourceName: string;
}

export interface IBulkWriteSubscriberCommandResponse extends ICommandResponse {
    result: {
        data: Array<{
            invalid: boolean;
            newToList: boolean;
            status: string | null;
            unsubscribed: boolean;
        }>;
        type?: 'subscriberSummary';
    };
}

export default class BulkWriteSubscriberCommand extends AbstractCommand<IBulkWriteSubscriberCommand> {
    public readonly _listId: ResourceID;
    private _fields: ReadonlyArray<string> = [];
    private _data: ReadonlyArray<ReadonlyArray<string | number>> = [];
    private _sourceName = '';

    constructor(listId: ResourceID, organizationId: number) {
        super(organizationId, 'bulkWriteSubscribers');
        this._listId = listId;
    }

    get listId(): number {
        return this._listId;
    }

    public fields(): ReadonlyArray<string>;
    public fields(value: ReadonlyArray<string>): this;
    public fields(value?: ReadonlyArray<string>): this | ReadonlyArray<string> {
        if (value) {
            this._fields = value;
            return this;
        }
        return this._fields;
    }

    // No point providing a getter for this due to the amount of data
    public data(): ReadonlyArray<ReadonlyArray<string | number>>;
    public data(value: ReadonlyArray<ReadonlyArray<string | number>>): this;
    public data(
        value?: ReadonlyArray<ReadonlyArray<string | number>>
    ): this | ReadonlyArray<ReadonlyArray<string | number>> {
        if (value) {
            this._data = value;
            return this;
        }
        return this._data;
    }

    public sourceName(): string;
    public sourceName(value: string): this;
    public sourceName(value?: string): this | string {
        if (value) {
            this._sourceName = value;
            return this;
        }
        return this._sourceName;
    }

    public toJSON(): IBulkWriteSubscriberCommand {
        return Object.assign(
            {},
            {
                data: this._data,
                fields: this._fields,
                listId: this._listId,
                organizationId: this.organizationId,
                sourceName: this._sourceName,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        // For this, we let the error propagate and handled closer to the UI layer
        return Rx.Observable.from([JSON.parse(result.data)]);
    }

    public filter(response: any): boolean {
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'subscriberSummary' &&
            response.result.data
        );
    }
}
