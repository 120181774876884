import Rx from 'rx';
import { AbstractCommand, ICommandResponse } from './Command';
import { ResourceID } from '../AppTypes';
import AppErrors from '../AppErrors';

export interface IWorkflowData {
    activityId: ResourceID;
}

export interface IWorkflowCommentData extends IWorkflowData {
    revisionId: ResourceID;
    message: string;
}

export interface IWorkflowProofData extends IWorkflowData {
    revisionId: ResourceID;
    message?: string;
    listId?: ResourceID;
    subscriberId?: ResourceID | ResourceID[] | null;
    useLiveData: boolean;
}

export interface IWorkflowCheckData extends IWorkflowData {
    revisionId: ResourceID;
}

export interface IWorkflowApproveData extends IWorkflowData {
    revisionId: ResourceID;
    message: string;
    date: string;
    schedule: string | null;
    targetExpression: string | null;
}

export interface IWorkflowQueueScheduleData extends IWorkflowData {
    queue: string; // Set of accepted queue methods?
    throttle: number;
}

export interface IWorkflowGenerateProofMatrixData extends IWorkflowData {
    revisionId: ResourceID;
}

export interface IWorkflowCommandResponse extends ICommandResponse {
    result: {
        data: ResourceID[];
        action: WorkflowAction;
    };
}

export interface IWorkflowOpenResponse extends ICommandResponse {
    result: {
        workflowOpen: any[];
    };
}

export interface IWorkflowCloseResponse extends ICommandResponse {
    result: {
        workflowClose: null;
    };
}

export interface IWorkflowRole {
    id: ResourceID;
    name: 'Participant' | 'Approver';
}

export interface IWorkflowGetRolesResponse extends ICommandResponse {
    result: {
        workflowGetRoles: IWorkflowRole[];
    };
}

export interface IWorkflowAddParticipants extends IWorkflowData {
    role: {
        id: ResourceID;
    };
    list?: {
        id: ResourceID;
    };
    subscriber?: {
        id?: ResourceID;
        email?: string;
        phone?: string;
    };
}

export interface IWorkflowRemoveParticipants extends IWorkflowData {
    subscriber: {
        id: ResourceID;
    };
}

export interface IWorkflowOpen {
    activityId: ResourceID;
}

export interface IWorkflowUpdateThrottleData extends IWorkflowData {
    throttle: number;
}

export enum WorkflowAction {
    // These actions map to the backend workflow commands
    AddParticipants = 'workflowAddParticipants',
    Approve = 'workflowApprove',
    Check = 'workflowCheck',
    Close = 'workflowClose',
    Comment = 'workflowComment',
    GenerateProofMatrix = 'workflowGenerateProofMatrix',
    GetRoles = 'workflowGetRoles',
    Open = 'workflowOpen',
    Proof = 'workflowProof',
    QueueDeploy = 'workflowQueueDeploy',
    QueueSchedule = 'workflowQueueSchedule',
    QueueCancel = 'workflowQueueCancel',
    RemoveParticipants = 'workflowRemoveParticipants',
    UpdateThrottle = 'workflowUpdateThrottle',
}

export default class WorkflowCommand<T> extends AbstractCommand<T> {
    private readonly _action: WorkflowAction;
    private _data: T[] = [];

    constructor(organizationId: ResourceID, action: WorkflowAction) {
        super(organizationId, action);
        this._action = action;
    }

    public data(): T[];
    public data(...args: T[]): this;
    public data(...args: T[]): this | T[] {
        if (args && args.length) {
            this._data = args;
            return this;
        }
        return this._data;
    }

    public toJSON(): T {
        return Object.assign(
            {
                organizationId: this.organizationId,
            },
            ...this._data
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error) {
            if (response.error.data) {
                const errData = response.error.data[0];
                throw new AppErrors(errData.name, errData.message ? errData.message : errData);
            } else {
                throw new Error(response.error?.message ?? response.error);
            }
        }
        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        const { organizationId: orgId, result: res } = response;
        return (
            orgId === this.organizationId &&
            res &&
            (Object.prototype.hasOwnProperty.call(res, this._action) || res.action === this._action)
        );
    }
}
