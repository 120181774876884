import Rx from 'rx';
import { IQueryCommandResponse } from '../api/QueryCommand';
import { default as WriteCommand, IWriteCommandResponse } from '../api/WriteCommand';
import AppConfig from '../AppConfig';
import BaseActions from '../BaseActions';
import BaseStore from '../BaseStore';
import Cache from '../Cache';

import { Map as ImmMap, List as ImmList } from 'immutable';
import { AttributeFilter } from '../types/AttributeFilter';
import { UserOrganizationProfile } from '../types/IUser';
import { CustomFieldType, ICustomField } from '../types/ICustomField';
import { IAction } from '../Action';

interface ICustomFieldAction extends IAction {
    type: CustomFieldType;
    field: string;
    groupName?: string | null;
    description?: string | null;
    targetExpression?: string | null;
    rule?: string | null;
    clear?: boolean;
    schema?: any;
    stats?: any;
    isDeleted?: boolean;
    isArchived?: boolean;
}

const _actionSubject: Rx.Subject<ImmMap<keyof ICustomFieldCacheValue, ICustomFieldCacheValue>> = new Rx.Subject();
interface ICustomFieldCacheValue {
    fields: ICustomField[];
    timestamp: number;
    [key: string]: any;
}

export class Actions extends BaseActions {
    public static observable() {
        return _actionSubject.asObservable();
    }

    public static createOrUpdate(organization: UserOrganizationProfile, field: ICustomFieldAction) {
        const command: WriteCommand<ICustomFieldAction> = super.command<ICustomFieldAction>(
            organization,
            'customField'
        );
        return super
            .connection(organization)
            .execute(command.data(field))
            .flatMapLatest((response: IWriteCommandResponse) => Rx.Observable.from([response.result.data]))
            .catch((error: Error) => Rx.Observable.throw(error))
            .first()
            .flatMapLatest(() =>
                Cache.getItem(Cache.ns.importCustomFields, 'customFields', organization.get('name')).flatMapLatest(
                    (val: ICustomFieldCacheValue) => {
                        // Add the field to the list if there is none matching the name and type
                        if (!val.fields.some((f) => f.field === field.field && f.type === field.type)) {
                            val.fields.push({
                                type: field.type,
                                field: field.field,
                                groupName: field.groupName,
                                description: field.description,
                                schema: field.schema,
                                stats: field.stats,
                            });
                        }
                        return Cache.setItem(
                            Cache.ns.importCustomFields,
                            'customFields',
                            val,
                            organization.get('name')
                        );
                    }
                )
            );
    }

    public static remove(
        organization: UserOrganizationProfile,
        objectType: CustomFieldType,
        objectField: string,
        shouldRemove = true
    ) {
        let command: WriteCommand<ICustomFieldAction> = super.command<ICustomFieldAction>(organization, 'customField');
        command = command.data({
            type: objectType,
            field: objectField,
            isDeleted: shouldRemove,
        });
        return super
            .connection(organization)
            .execute(command)
            .flatMapLatest((response: IWriteCommandResponse) => Rx.Observable.from([response.result.data]))
            .doOnNext(() => {
                if (!shouldRemove) {
                    return;
                }
                Cache.getItem(Cache.ns.importCustomFields, 'customFields', organization.get('name')).flatMapLatest(
                    (val: ICustomFieldCacheValue) => {
                        // Add the field to the list if there is none matching the name and type
                        if (val.fields.some((f) => f.field === objectField && f.type === objectType)) {
                            val.fields = val.fields.filter(
                                (field) => field.field !== objectField && field.type !== objectType
                            );
                        }
                        return Cache.setItem(
                            Cache.ns.importCustomFields,
                            'customFields',
                            val,
                            organization.get('name')
                        );
                    }
                );
            })
            .first();
    }

    public static archive(
        organization: UserOrganizationProfile,
        objectType: CustomFieldType,
        objectField: string,
        shouldArchive = true
    ) {
        let command: WriteCommand<ICustomFieldAction> = super.command<ICustomFieldAction>(organization, 'customField');
        command = command.data({
            type: objectType,
            field: objectField,
            isArchived: shouldArchive,
        });
        return super
            .connection(organization)
            .execute(command)
            .flatMapLatest((response: IWriteCommandResponse) => Rx.Observable.from([response.result.data]))
            .doOnNext(() => {
                if (!shouldArchive) {
                    return;
                }
                Cache.getItem(Cache.ns.importCustomFields, 'customFields', organization.get('name')).flatMapLatest(
                    (val: ICustomFieldCacheValue) => {
                        // Add the field to the list if there is none matching the name and type
                        if (val.fields.some((f) => f.field === objectField && f.type === objectType)) {
                            val.fields = val.fields.map((field) => {
                                if (field.field === objectField && field.type === objectType) {
                                    field.isArchived = true;
                                }
                                return field;
                            });
                        }
                        return Cache.setItem(
                            Cache.ns.importCustomFields,
                            'customFields',
                            val,
                            organization.get('name')
                        );
                    }
                );
            })
            .first();
    }
}

export class Store extends BaseStore {
    public static searchByTypeField(
        organization: UserOrganizationProfile,
        type: CustomFieldType,
        field: string
    ): Rx.Observable<ImmList<ICustomField>> {
        return this.getItems(organization, type)
            .map((fields) => fields.filter((f: ICustomField) => f.field === field))
            .first();
    }

    public static searchByAttr(
        organization: UserOrganizationProfile,
        filters: AttributeFilter[],
        limit: number = AppConfig.Defaults.limit
    ): Rx.Observable<ImmList<ICustomField>> {
        let command = super.command(organization, 'customField');
        const query = command.query();

        command = command.query(query).limit(limit);

        return super.runCommand(organization, command).map((response: IQueryCommandResponse<ICustomField>) => {
            const data = response.result.data;
            return ImmList(
                data.filter((item: ICustomField) => {
                    return filters.every((f) => {
                        const attrVal = item[f.attribute] + '';
                        if (!attrVal) {
                            return false;
                        }
                        return attrVal.toLocaleLowerCase().includes((f.value as string).toLocaleLowerCase());
                    });
                })
            );
        });
    }

    public static getItems(organization: UserOrganizationProfile, type: CustomFieldType | 'any', force = false) {
        Cache.getItem(Cache.ns.importCustomFields, 'customFields', organization.get('name'))
            .flatMapLatest((data: ICustomFieldCacheValue) => {
                // If not data or data expired or forced
                if (!data || Date.now() - data.timestamp > AppConfig.Defaults.customFieldCachedDuration || force) {
                    return super
                        .runCommand(organization, super.command(organization, 'customField'))
                        .flatMapLatest((response: IQueryCommandResponse<ICustomField>) => {
                            const val: ICustomFieldCacheValue = {
                                fields: response.result.data,
                                timestamp: Date.now(),
                            };
                            return Cache.setItem(
                                Cache.ns.importCustomFields,
                                'customFields',
                                val,
                                organization.get('name')
                            ).flatMapLatest((cachedData: ICustomFieldCacheValue) =>
                                Rx.Observable.from([ImmMap(cachedData)])
                            );
                        });
                }
                // we have non-stale data, return it
                return Rx.Observable.from([ImmMap(data)]);
            })
            .first()
            .subscribe(
                (data) => _actionSubject.onNext(data as ImmMap<keyof ICustomFieldCacheValue, ICustomFieldCacheValue>),
                _actionSubject.onError.bind(_actionSubject)
            );

        return Actions.observable().map((data: ImmMap<keyof ICustomFieldCacheValue, ICustomFieldCacheValue>) => {
            const fields = data.get('fields');
            if (typeof fields === 'undefined') {
                return ImmList<ICustomField>();
            }
            const filtered = fields.filter(
                (f: ICustomField) => (type === 'any' || f.type === type) && f.isArchived !== true
            );
            return ImmList<ICustomField>(filtered);
        });
    }
}
