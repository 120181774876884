import { defineStore } from 'pinia';
import AppServer from '../../api/AppServer';
import Query from '../../api/Query';
import QueryCommand from '../../api/QueryCommand';
import { ActivityThemeWriteCommand } from '../../api/ActivityWriteCommand';
import { ResourceID } from '../../AppTypes';
import { defaultTheme } from '../../ActivityTheme';

interface IActivityTheme {
    id: ResourceID;
    name: string;
    description: string;
    lastUpdated: string;
    content: Record<string, any>;
    config?: {
        locked?: boolean;
    };
}

let fetchThemeDisposable!: Rx.Disposable;
let fetchThemesDisposable!: Rx.Disposable;
let saveThemeDisposable!: Rx.Disposable;
let deleteThemeDisposable!: Rx.Disposable;
let copyThemeDisposable!: Rx.Disposable;

interface IUIStateActivityTheme {
    _theme: IActivityTheme;
    _themes: IActivityTheme[];
}

const initialState = {
    _theme: {
        id: 0,
        name: '',
        description: '',
        lastUpdated: '',
        content: { ...defaultTheme.content },
    },
    _themes: [],
};

export const useStoreActivityTheme = defineStore({
    id: 'activityTheme',
    state: (): IUIStateActivityTheme => structuredClone(initialState),
    getters: {
        theme(): IActivityTheme {
            return this._theme;
        },
        themes(): IActivityTheme[] {
            return this._themes;
        },
    },
    actions: {
        fetchTheme({ organization, themeId }) {
            if (fetchThemeDisposable) {
                fetchThemeDisposable.dispose();
            }
            return new Promise<IActivityTheme>((resolve, reject) => {
                const command = new QueryCommand(organization.get('organization') as number);
                let query = new Query('activityTheme');
                query = query.id(themeId);
                fetchThemeDisposable = AppServer.connection(organization)
                    .execute(command.query(query))
                    .first()
                    .subscribe((response) => {
                        this._theme = {
                            ...response.result.data[0],
                            content: {
                                ...initialState._theme.content,
                                ...response.result.data[0]?.content,
                                style: {
                                    ...initialState._theme.content.style,
                                    ...response.result.data[0]?.content.style,
                                },
                            },
                        };
                        if (response.result.data[0]) resolve(this._theme);
                        else reject('No theme found');
                    }, reject);
            });
        },

        fetchThemes({ organization }) {
            if (fetchThemesDisposable) {
                fetchThemesDisposable.dispose();
            }

            return new Promise((resolve, reject) => {
                const command = new QueryCommand(organization.get('organization') as number);
                const query = new Query('activityTheme');
                fetchThemesDisposable = AppServer.connection(organization)
                    .execute(command.query(query))
                    .first()
                    .subscribe((response) => {
                        this._themes = response.result.data;
                        resolve(this._themes);
                    }, reject);
            });
        },

        saveTheme({ organization, id, name, description, content }) {
            if (saveThemeDisposable) {
                saveThemeDisposable.dispose();
            }
            return new Promise((resolve, reject) => {
                const command = new ActivityThemeWriteCommand(organization.get('organization'));
                const payload: Record<string, any> = {
                    name,
                    description,
                    content: JSON.stringify(content),
                };

                const intId = parseInt(id + '', 10);
                if (intId) {
                    payload.id = intId;
                }
                saveThemeDisposable = AppServer.connection(organization)
                    .execute(command.data(payload))
                    .first()
                    .subscribe((response) => {
                        this._themes = response.result.data[0];
                        resolve(this._themes);
                    }, reject);
            });
        },

        deleteTheme({ organization, themeId }) {
            if (deleteThemeDisposable) {
                deleteThemeDisposable.dispose();
            }
            return new Promise((resolve, reject) => {
                const command = new ActivityThemeWriteCommand(organization.get('organization'));
                saveThemeDisposable = AppServer.connection(organization)
                    .execute(
                        command.data({
                            id: themeId,
                            isDeleted: true,
                        })
                    )
                    .first()
                    .subscribe((response) => {
                        this._themes = response.result.data[0];
                        resolve(this._themes);
                    }, reject);
            });
        },

        copyTheme({ organization, themeId }) {
            if (copyThemeDisposable) {
                copyThemeDisposable.dispose();
            }
            return new Promise((resolve, reject) => {
                const command = new ActivityThemeWriteCommand(organization.get('organization'));
                copyThemeDisposable = AppServer.connection(organization)
                    .execute(
                        command.data({
                            id: themeId,
                            isCopied: true,
                        })
                    )
                    .first()
                    .subscribe((response) => {
                        this._themes = response.result.data[0];
                        resolve(this._themes);
                    }, reject);
            });
        },

        resetTheme() {
            // Reset the store state to the default values
            Object.assign(this, initialState);
        },
    },
});
