import AppErrors from '../AppErrors';
import { ResourceType } from '../AppTypes';
import { AbstractCommand, ICommand } from './Command';
import Rx from 'rx';

interface IRequestExportCommand extends ICommand {
    params: { [key: string]: any };
    target: string;
    type: ResourceType;
}

export default class RequestExportCommand extends AbstractCommand<IRequestExportCommand> {
    private readonly _type: ResourceType;
    private _target = '';
    private _params: { [key: string]: any } = {};

    constructor(resourceType: ResourceType, organizationId: number) {
        super(organizationId, 'requestExport');
        this._type = resourceType;
    }

    public get type(): ResourceType {
        return this._type;
    }

    public target(): string;
    public target(value: string): this;
    public target(value?: string): this | string {
        if (value) {
            this._target = value;
            return this;
        }
        return this._target;
    }

    public params(): { [key: string]: any };
    public params(value: { [key: string]: any }): this;
    public params(value?: { [key: string]: any }): this | { [key: string]: any } | undefined {
        if (value) {
            this._params = value;
            return this;
        }
        return this._params;
    }

    public toJSON(): IRequestExportCommand {
        return Object.assign(
            {},
            {
                organizationId: this.organizationId,
                params: this._params,
                target: this._target,
                type: this._type,
            }
        );
    }

    public toString(): string {
        return JSON.stringify({
            command: this.name,
            parameters: this.toJSON(),
        });
    }

    public transformer(result: any): any {
        const response = JSON.parse(result.data);
        if (response.error) {
            throw new AppErrors(response.error);
        }

        return Rx.Observable.from([response]);
    }

    public filter(response: any): boolean {
        // If matchesQuery is false, it means it's a subscription update. Allow those too.
        return (
            response.organizationId === this.organizationId &&
            response.result &&
            response.result.type === 'requestExportResponse' &&
            response.result.data
        );
    }
}
