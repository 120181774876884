import '../css/app.css';
import '../manifest.json';

import './Shims';

import { sendError as appSendError } from './AppErrors';
import { addCSSBrowserSelectors } from './UAInfo';

import Vue from 'vue';

import AppAlert from './components/AppAlert.vue';
import AutoGrow from './components/directives/AutoGrow';
import Focus from './components/directives/Focus';
import ClickOutside from './components/directives/ClickOutside';
import Acl from './components/directives/Acl';
import VueTippy from 'vue-tippy';
import Toasted from 'vue-toasted';
import VueDragscroll from 'vue-dragscroll';
import 'tippy.js/themes/light.css'; // Tippy Light Theme

import { Store as UserStore } from './modules/User';
import { getRouter } from './Routes';
import { setI18n } from './utils/Format';
import { setI18n as setI18nSub } from './modules/Subscriber';
import vueStore from './components/store';
import { createPinia } from 'pinia';
import shadow from 'vue-shadow-dom';

import { createI18n } from './composables/useI18n';
import VueI18n from 'vue-i18n';
import i18nStrings from '../i18n/strings';

/** Ignore these Custom Elements inside the Vue context */
Vue.config.ignoredElements = [/^ui-/];

window.addEventListener('DOMContentLoaded', () => {
    Vue.use(Toasted, {
        router: getRouter(),
        duration: 8000, // ms
        position: 'bottom-left',
    });

    /* Global truncate filter */
    Vue.filter('truncate', function (text, length) {
        if (!text) {
            return '';
        }
        if (text.length > length) {
            return text.substring(0, length) + '...';
        } else {
            return text;
        }
    });

    Vue.use(VueTippy, {
        animateFill: false,
        arrow: false,
        delay: [350, 300],
        directive: 'tippy', // => v-tippy
        onShow: (options) => {
            return !!options.props.content;
        },
    });

    Vue.directive('acl', Acl);
    Vue.directive('focus', Focus);
    Vue.directive('click-outside', ClickOutside);
    Vue.directive('auto-grow', AutoGrow);
    Vue.directive('drag-scroll', VueDragscroll);

    Vue.use(VueI18n);
    Vue.use(shadow);

    let locale = UserStore.getPreference('locale');
    if (!locale || locale === 'system') {
        locale = window.navigator.language;
    }
    const i18n = createI18n({
        locale,
        fallbackLocale: 'en',
        messages: i18nStrings,
        silentFallbackWarn: true,
    });
    setI18n(i18n);
    setI18nSub(i18n);

    const app = new Vue({
        i18n,
        router: getRouter(),
        store: vueStore,
        components: {
            AppAlert,
        },
        mounted() {
            // Add browser/os selector ala css_browser_selector
            addCSSBrowserSelectors();
        },
        // Add Pinia (vuex replacement)
        pinia: createPinia(),
    });

    // Mount app on load
    app.$mount('#app');
});

if (process.env.NODE_ENV === 'production') {
    window.addEventListener('load', async () => {
        // register service worker
        const sw = await import('./ServiceWorker');
        console.log('Registering service worker');
        sw.register();
    });
}

// Setup global onerror handler
window.onerror = (message, source, lineno, colno, error) => {
    // Handle global errors that we can recover from here.
    // Redirect on invalid session errors;
    if (typeof error === 'undefined' || error === null) {
        return false;
    }

    // Send unhandled prod errors to Slack
    if (process.env.NODE_ENV === 'production') {
        appSendError({ error, message, source, lineno, colno });
    } else {
        return false;
    }
    return true;
};

interface IdleDeadline {
    readonly didTimeout: boolean;
    timeRemaining(): DOMHighResTimeStamp;
}

declare const IdleDeadline: {
    prototype: IdleDeadline;
    new (): IdleDeadline;
};
interface IdleRequestCallback {
    (deadline: IdleDeadline): void;
}

interface IdleRequestOptions {
    timeout?: number;
}

// webcomponent interface definition hack
declare global {
    interface Window {
        confirm: (message?: string) => boolean;
        requestIdleCallback: (callback: IdleRequestCallback, options?: IdleRequestOptions | undefined) => number;
        cancelIdleCallback: (handle: number) => void;
        dominosCouponDragging: boolean;
    }
}
